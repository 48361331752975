import React from 'react';

const MacIcon = () => {
      return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M25.4529 16.7869C25.2396 16.7869 25.0263 16.7202 24.8396 16.5735C19.4663 12.4269 12.5196 12.4269 7.14625 16.5735C6.70625 16.9069 6.07958 16.8269 5.74625 16.4002C5.41292 15.9602 5.49292 15.3335 5.91959 15.0002C12.0396 10.2669 19.9462 10.2669 26.0529 15.0002C26.4929 15.3335 26.5729 15.9602 26.2263 16.4002C26.0529 16.6535 25.7596 16.7869 25.4529 16.7869Z" fill="#ABABCD" />
                  <path d="M29.3334 12.1469C29.12 12.1469 28.9067 12.0802 28.72 11.9335C20.9867 5.96019 11 5.96019 3.28005 11.9335C2.84005 12.2669 2.21338 12.1869 1.88005 11.7602C1.54671 11.3202 1.6267 10.6935 2.05336 10.3602C10.52 3.81353 21.4667 3.81353 29.9467 10.3602C30.3867 10.6935 30.4667 11.3202 30.12 11.7602C29.9334 12.0135 29.6267 12.1469 29.3334 12.1469Z" fill="#ABABCD" />
                  <path d="M22.9468 21.6532C22.7334 21.6532 22.5201 21.5866 22.3334 21.4399C18.4934 18.4666 13.5201 18.4666 9.66676 21.4399C9.22676 21.7732 8.60009 21.6932 8.26676 21.2665C7.93342 20.8265 8.01343 20.1999 8.4401 19.8665C13.0268 16.3199 18.9601 16.3199 23.5468 19.8665C23.9868 20.1999 24.0668 20.8265 23.7201 21.2665C23.5334 21.5199 23.2401 21.6532 22.9468 21.6532Z" fill="#ABABCD" />
                  <path opacity="0.4" d="M19.4669 26.5333C19.2536 26.5333 19.0402 26.4667 18.8536 26.32C17.1202 24.9733 14.8669 24.9733 13.1336 26.32C12.6936 26.6533 12.0669 26.5733 11.7336 26.1466C11.4002 25.7066 11.4802 25.08 11.9069 24.7466C14.3869 22.8266 17.5869 22.8266 20.0669 24.7466C20.5069 25.08 20.5869 25.7066 20.2402 26.1466C20.0669 26.3866 19.7736 26.5333 19.4669 26.5333Z" fill="#ABABCD" />
            </svg>
      );
};

export default MacIcon;
