import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomEvent } from "../../Event/CustomEventContext";
import { channel } from "diagnostics_channel";
import Marquee from "react-fast-marquee";

function ItemCtagoryLive({  category, focusKey: focusKeyParam,index }) {
      const navigate = useNavigate();
      const { emitEvent } = useCustomEvent();

      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,


            onEnterPress: () => {
                  emitEvent('focusEventOpen', index);


            },
      });
      useEffect(() => {
            if (focused) {
                  emitEvent('focusEvent', index);

            }
      }, [focused]);

      return <FocusContext.Provider value={focusKey}> <div ref={ref}  className="group outline-none text-left m-2	" > <div
            className=" relative focusable w-full  h-32 outline-none border-0 cursor-pointer"
      >
            <div className={(focused ?"bg-white ":"")+"w-1/3 mx-auto h-2 "} />

            <div className="  h-full -mt-2 bg-categoryColor relative w-full">
                  <div
                        className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-end p-4"
                  >
                        <img src="/images/live.svg" className="w-8" />
                        <h2 className="text-white text-2xl my-2 ">{category.category_name}</h2>
                        <span className="text-textcolor2 text-lg">{category.lengthCat}</span>
                  </div>
            </div>

            <div
                  className={(focused ? "block " : "hidden") + " absolute left-0 top-0.5 right-0 w-full h-full  "}
                  style={{"backgroundImage": "url(/images/bordergradient.svg)"}}
            />

      </div>
      </div>
      </FocusContext.Provider>
}

export default ItemCtagoryLive;


