
function Progress({classes="h-20 w-20"}) {
      return <div
            className="absolute right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2"
      >
            <div
                  className={"border-t-transparent border-solid animate-spin duration-100 rounded-full border-white border-4 " + classes}
            />
      </div>
}

export default Progress;