import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ItemSeason = ({index,season }) => {
      const navigate = useNavigate();

      const { ref, focused } = useFocusable({
            onEnterPress: () => {
                  navigate("/season/" + index)
            },
      });
      useEffect(() => {
            console.log('focused array changed:', focused);
            if (focused) {
                  ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                  });
            }
            //    addToGlobalArray('eeeee')
            // Do something when the global array changes
      }, [focused]);
/*
*/
      return (
            <div ref={ref}
                  className="group mr-4 relative focusable w-48 transition-all duration-300  outline-none border-0 h-28"
            >


                  <div className=" h-20  bg-categoryColor relative w-full">
                        <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: focused ? 1 : 0 }}
                              transition={{ duration: 0.3 }}
                              className={"relative h-full  w-full flex justify-center items-end"} >

                              <div className={(focused ? "bg-white " : "") + "w-1/3 absolute -bottom-[4px] mx-auto h-[5px] left-1/2 transform -translate-x-1/2 "} />
                              <div className="w-full h-full overflow-hidden flex justify-center items-end">
                              <div className="bg-white w-1/3   transform h-8 filter  top-0 overflow-hidden
                              blur-[2rem]
" ></div>
                              </div>
                        </motion.div>
                        <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                              <h2 className="text-white text-2xl text-normal my-2">Season {season}</h2>
                              <div
                                    className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                    style={{ backgroundImage: `url('../images/bordergradient.svg')` }}
                              />
                        </div>
                  </div>
            </div>
      );
};

export default ItemSeason;
