import React from 'react';

const Like = (props) => {
      return (
       <svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.44 3.59998C14.63 3.59998 13.01 4.47998 12 5.82998C10.99 4.47998 9.37 3.59998 7.56 3.59998C4.49 3.59998 2 6.09998 2 9.18998C2 10.38 2.19 11.48 2.52 12.5C4.1 17.5 8.97 20.49 11.38 21.31C11.72 21.43 12.28 21.43 12.62 21.31C15.03 20.49 19.9 17.5 21.48 12.5C21.81 11.48 22 10.38 22 9.18998C22 6.09998 19.51 3.59998 16.44 3.59998Z"
                        className={props.class}
  />
</svg>

      );
};

export default Like;
