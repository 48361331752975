function Return({ classes }: { classes?: string }) {
      return <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
      >
            <path
                  d="M21.587 2.66675H10.4137C5.56033 2.66675 2.66699 5.56008 2.66699 10.4134V21.5734C2.66699 26.4401 5.56033 29.3334 10.4137 29.3334H21.5737C26.427 29.3334 29.3203 26.4401 29.3203 21.5867V10.4134C29.3337 5.56008 26.4403 2.66675 21.587 2.66675ZM18.5603 21.5067H12.0003C11.4537 21.5067 11.0003 21.0534 11.0003 20.5067C11.0003 19.9601 11.4537 19.5067 12.0003 19.5067H18.5603C20.267 19.5067 21.667 18.1201 21.667 16.4001C21.667 14.6801 20.2803 13.2934 18.5603 13.2934H11.8003L12.147 13.6401C12.5337 14.0401 12.5337 14.6667 12.1337 15.0667C11.9337 15.2667 11.6803 15.3601 11.427 15.3601C11.1737 15.3601 10.9203 15.2667 10.7203 15.0667L8.62699 12.9601C8.24033 12.5734 8.24033 11.9334 8.62699 11.5467L10.7203 9.45342C11.107 9.06675 11.747 9.06675 12.1337 9.45342C12.5203 9.84008 12.5203 10.4801 12.1337 10.8667L11.6937 11.3067H18.5603C21.3737 11.3067 23.667 13.6001 23.667 16.4134C23.667 19.2267 21.3737 21.5067 18.5603 21.5067Z"
                  fill="url(#paint0_linear_606_1872)"
            />
            <defs>
                  <linearGradient
                        id="paint0_linear_606_1872"
                        x1="12.9575"
                        y1="9.66675"
                        x2="35.6769"
                        y2="45.5724"
                        gradientUnits="userSpaceOnUse"
                  >
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
            </defs>
      </svg>

}

export default Return;


/*
function Refrech() {
}

export default Refrech;
*/