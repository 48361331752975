import React from 'react';

const Users: React.FC = () => {
      return (
            <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
            >
                  <path
                        opacity="0.4"
                        d="M9 2.5C6.38 2.5 4.25 4.63 4.25 7.25C4.25 9.82 6.26 11.9 8.88 11.99C8.96 11.98 9.04 11.98 9.1 11.99C9.12 11.99 9.13 11.99 9.15 11.99C9.16 11.99 9.16 11.99 9.17 11.99C11.73 11.9 13.74 9.82 13.75 7.25C13.75 4.63 11.62 2.5 9 2.5Z"
                        fill="white"
                  />
                  <path
                        d="M14.08 14.65C11.29 12.79 6.73996 12.79 3.92996 14.65C2.65996 15.5 1.95996 16.65 1.95996 17.88C1.95996 19.11 2.65996 20.25 3.91996 21.09C5.31996 22.03 7.15996 22.5 8.99996 22.5C10.84 22.5 12.68 22.03 14.08 21.09C15.34 20.24 16.04 19.1 16.04 17.86C16.03 16.63 15.34 15.49 14.08 14.65Z"
                        fill="white"
                  />
                  <path
                        opacity="0.4"
                        d="M19.9904 7.83998C20.1504 9.77998 18.7704 11.48 16.8604 11.71C16.8504 11.71 16.8504 11.71 16.8404 11.71H16.8104C16.7504 11.71 16.6904 11.71 16.6404 11.73C15.6704 11.78 14.7804 11.47 14.1104 10.9C15.1404 9.97998 15.7304 8.59998 15.6104 7.09998C15.5404 6.28998 15.2604 5.54998 14.8404 4.91998C15.2204 4.72998 15.6604 4.60998 16.1104 4.56998C18.0704 4.39998 19.8204 5.85998 19.9904 7.83998Z"
                        fill="white"
                  />
                  <path
                        d="M21.9902 17.09C21.9102 18.06 21.2902 18.9 20.2502 19.47C19.2502 20.02 17.9902 20.28 16.7402 20.25C17.4602 19.6 17.8802 18.79 17.9602 17.93C18.0602 16.69 17.4702 15.5 16.2902 14.55C15.6202 14.02 14.8402 13.6 13.9902 13.29C16.2002 12.65 18.9802 13.08 20.6902 14.46C21.6102 15.2 22.0802 16.13 21.9902 17.09Z"
                        fill="white"
                  />
            </svg>
      );
};

export default Users;
