import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Progress from "./sub/Progress";
import { useGlobalContext } from "../model/GlobalContext";
import { FocusContext, getCurrentFocusKey, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import 'react-virtualized/styles.css'; // import the styles
import { Collection } from 'react-virtualized';
import useWindowDimensions from "../hooks/useWindowDimensions";
import { WindowScroller, AutoSizer } from 'react-virtualized';
import React from "react";
import { useCustomEvent } from "../Event/CustomEventContext";
import ItemSerie from "./sub/ItemSerie";
import Nav from "./sub/Nav";
import useEventListener from "@use-it/event-listener";
import { deleterow, insertData } from "../model/constant";
const LIKE_KEY = ['100', '403']
const ESCAPE_KEYS = ['27',  '10009'];

const Series: React.FC = () => {
      const { id } = useParams();
      const navigate = useNavigate();

      const { height, width } = useWindowDimensions();
      const { listSerie, listSerieRecent, listSerieFav, changeListSerieFav, profile, profileShow } = useGlobalContext();
      const collectionRef = React.createRef<Collection>();
      const { addEventListener, removeEventListener } = useCustomEvent();
      const [listPos, setListPos] = useState<number>(0);
      const _windowScroller = useRef(null);
      const [listFav, setListFav] = useState<any[]>([]);
      const [listRecent, setListRecent] = useState<any[]>([]);

      const [listSeries, setlistSeries] = useState<any[]>([]);

      const [isLoadinfShow, setLoadinfShow] = useState(true);
      const { ref, focusSelf, focusKey } = useFocusable({ trackChildren: true });
      const scrollingRef = useRef<HTMLDivElement>(null);
      const cellRenderer = ({ index, key, isScrolling, style }) => (
            <div key={key} style={style} className="p-2">

                  <ItemSerie index={index} serie={listSeries[index]} focusKey={'Serie' + index} listFav={listFav} isScrolling={isScrolling} />
            </div>
      );
      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      useEffect(() => {

            setListFav([...listSerieFav])
      }, [listSerieFav]);
      useEffect(() => {
            console.log(listSerieRecent)
            setListRecent([...listSerieRecent])
      }, [listSerieRecent]);

      function handler({ keyCode }) {
            console.log(keyCode)
            if (ESCAPE_KEYS.includes(String(keyCode))) {

                  setTimeout(() => {
                        if (!profileShow)
                              navigate(-1)

                  })

            } else {
                  if (LIKE_KEY.includes(String(keyCode))) {
                        let focused = getCurrentFocusKey();
                        if (focused.includes('Serie')) {

                              let id = parseInt(focused.replace("Serie", ""))
                              console.log(id)
                              const newArray = [...listSeries];
                              newArray[id].isLiked = !newArray[id].isLiked;
                              var streamId = String(newArray[id].series_id);
                              const arrayFav = [...listSerieFav]
                              if (listSerieFav.includes(streamId)) {
                                    var arr=arrayFav.filter(el => el !== streamId)
                                    deleterow(streamId, "serie", profile);

                                    changeListSerieFav(arr)
                              } else {
                                    arrayFav.push(streamId)
                                    insertData(streamId, "serie", profile)
                                    changeListSerieFav(arrayFav)

                              }
                              // Set the state with the new array

                              setlistSeries(newArray)
                        }
                  }
            }
      }

      useEventListener('keydown', handler);

      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  focusSelf();

                 // scrollToIndex(payload)
                  setListPos(payload)

                  if (_windowScroller.current) {
                        let variableName = _windowScroller.current as WindowScroller;

                        variableName.updatePosition();
                  }
            };

            addEventListener('focusEventSerie', handleCustomEvent);

            return () => {
                  removeEventListener('focusEventSerie', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);


      useEffect(() => {

console.log(id)
            if (id === "0") {
                  setlistSeries([...listSerie])
            }
            else if (id === "-1") {
                  let arr = [...listSerie.filter(item => {
                        return listSerieFav.includes(String(item.series_id))
                  })]


                  setlistSeries(arr)


            } else if (id === "-2") {
                  let arr = [...listSerie.filter(item => {
                        return listSerieRecent.includes(String(item.series_id))
                  })]


                  setlistSeries(arr)

            } else {
                  let arr = [...listSerie.filter(item => {
                        return item.category_id === id
                  })
                  ]
                  setlistSeries(arr)

            }
            setLoadinfShow(false)

      }, [listSerie]);


      const cellSizeAndPositionGetter = ({ index }) => {
            const columnCount = 6;
            const rowCount = Math.ceil(listSeries.length / columnCount);
            const cellWidth = ((width * 0.9)) / 6;
            const cellHeight = cellWidth *1.5;
            const margin = 8;
            const   cellFirst = (width * 0.1)/2

            const column = index % columnCount;
            const row = Math.floor(index / columnCount);

            return {
                  height: cellHeight,
                  width: cellWidth,
                  x: cellFirst+ column * (cellWidth ),                  y: row * (cellHeight),
            };
      };
      const onSectionRendered = ({ indices: { rowStartIndex, rowStopIndex } }) => {
            // Logic to handle item loaded when scrolling
            console.log('Items loaded:', rowStartIndex, 'to', rowStopIndex);
      };


      const scrollToIndex = (index) => {
                  // Calculate the position to scroll to based on the index and cell height
                  const columnCount = 6; // Assuming 3 columns
                  const cellWidth = ((width * 0.9)) / 6 - 16;

                  const cellHeight = cellWidth * 1.5;
                  const margin = 8; // Adjust this based on any margins

                  const row = Math.floor(index / columnCount);
                  const scrollToPosition = row * (cellHeight + margin);
            console.log(scrollToPosition)
                  window.scrollTo(0, scrollToPosition-200);
//smoothScrollTo(targetPosition, duration);

                  // Scroll to the calculated position

      };
      const changeScroll=()=>{
            focusSelf();

      }
      function easeInOutQuad(t: number, b: number, c: number, d: number): number {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      // Smooth scroll function
      function smoothScrollTo(targetPosition: number, duration: number): void {
            const startPosition = window.scrollY;
            const distance = targetPosition - startPosition;
            const startTime = performance.now();

            function scrollStep(): void {
                  const currentTime = performance.now();
                  const elapsedTime = currentTime - startTime;

                  window.scrollTo(0, easeInOutQuad(elapsedTime, startPosition, distance, duration));

                  if (elapsedTime < duration) {
                        requestAnimationFrame(scrollStep);
                  }
            }

            requestAnimationFrame(scrollStep);
      }
      var arrow_keys_handler = function (e) {
            switch (e.code) {
                  case "ArrowUp": case "ArrowDown": case "ArrowLeft": case "ArrowRight":
                  case "Space": e.preventDefault(); break;
                  default: break; // do not block other keys
            }
      };
      window.addEventListener("keydown", arrow_keys_handler, false);

      return <FocusContext.Provider value={focusKey}><div ref={scrollingRef} className="outline-none" >
            {isLoadinfShow &&  <Progress />}
            {!isLoadinfShow&& <div>
                 <Nav/>                 <div className={"list mt-32"}>

                        <WindowScroller ref={_windowScroller}>
                              {({ height, scrollTop, onChildScroll }) => (
                                    <div >

                                          <AutoSizer disableHeight>
                                                {({ width }) => (

                                                      <Collection
                                                            autoHeight
                                                            tabIndex={-1}
                                                            ref={collectionRef}
                                                            height={height}
                                                            width={width}
                                                            scrollTop={scrollTop}
                                                            cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                                                            onScroll={onChildScroll}
                                                            scrollToCell={listPos}

                                                            cellRenderer={cellRenderer}
                                                            cellCount={listSeries.length}
                                                            overscanRowCount={20}
                                                      />


                                                )}
                                          </AutoSizer>
                                          <div style={{ height: 100 }} /> {/* Spacer div to account for future content height */}
                                    </div>
                              )}
                        </WindowScroller>
                  </div>

            </div>
            }
</div >
      </FocusContext.Provider>

}

export default Series;