function Search({ classes}:{ classes?: string}) {
      return (<svg
            width={classes ? classes :"2rem"}
            height={classes ? classes : "2rem"}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
      >
            <path
                  d="M15.3337 27.9998C22.3293 27.9998 28.0003 22.3288 28.0003 15.3332C28.0003 8.33756 22.3293 2.6665 15.3337 2.6665C8.33805 2.6665 2.66699 8.33756 2.66699 15.3332C2.66699 22.3288 8.33805 27.9998 15.3337 27.9998Z"
                  fill="url(#paint0_linear_606_1701)"
            />
            <path
                  d="M28.4004 29.3332C28.1604 29.3332 27.9204 29.2399 27.7471 29.0666L25.2671 26.5866C24.9071 26.2266 24.9071 25.6399 25.2671 25.2666C25.6271 24.9066 26.2137 24.9066 26.5871 25.2666L29.0671 27.7466C29.4271 28.1066 29.4271 28.6932 29.0671 29.0666C28.8804 29.2399 28.6404 29.3332 28.4004 29.3332Z"
                  fill="white"
            />
            <defs>
                  <linearGradient
                        id="paint0_linear_606_1701"
                        x1="14.1937"
                        y1="10.6465"
                        x2="41.047"
                        y2="36.7398"
                        gradientUnits="userSpaceOnUse"
                  >
                        <stop stopColor="white" />
                        <stop offset="0.200834" stopColor="white" stopOpacity="0.7" />
                        <stop offset="0.392183" stopColor="white" stopOpacity="0.5" />
                        <stop offset="1" stopColor="white"        stopOpacity="0" />
                  </linearGradient>
            </defs>
      </svg>
);
}

export default Search;


/*
function Refrech() {
}

export default Refrech;
*/