import { useCallback, useEffect, useRef, useState } from "react";
import { FocusContext, pause, resume, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import ItemSetting from "./sub/ItemSetting";
import 'react-spring-bottom-sheet/dist/style.css'
import './Home.css'
import BottomSheet from "./Settings/BottomSheet";
import { useCustomEvent } from "../Event/CustomEventContext";
import LanguageCom from "./Settings/language";
import StreamType from "./Settings/StreamType";
import PlayerType from "./Settings/PlayerType";
import TimeFormat from "./Settings/TimeFormat";
import { ReactInternetSpeedMeter } from "./Settings/ReactInternetSpeedMeter";
import Parental from "./Settings/Parental";
import ParentalList from "./Settings/ParentalList";
import useEventListener from '@use-it/event-listener'
import { useNavigate } from "react-router-dom";
import ListType from "./Settings/ListType";

const ESCAPE_KEYS = ['27', 'Escape', "XF86Back"];

function Settings() {
      const { addEventListener, removeEventListener } = useCustomEvent();
      const [wifiSpeed, setwifiSpeed] = useState("Checking ... ");
      const [rerender, setRerender] = useState(false);

      const [listSettings, setListSettings] = useState<any[]>([{ id: 0, title: "Select Language" }, { id: 1, title: "parental control" }, { id: 2, title: "Stream Format" }, { id: 3, title: "Player Selection" }, { id: 4, title: "Time Format" }, { id: 5, title: "Speed Test" }, { id: 6, title: "List Type" }]);
      const { ref, focusSelf, focusKey, focused } = useFocusable({ focusKey :"Settings"});
      const [open, setOpen] = useState(false)
      const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
      const [isBottomSheetOpenParental, setBottomSheetOpenParental] = useState(false);
      const [isBottomSheetOpenParentalList, setBottomSheetOpenParentalList] = useState(false);

      const [isStreamOpen, setStreamOpen] = useState(false);
      const [isPlayerOpen, setPlayerOpen] = useState(false);
      const [isTimeFormatOpen, setTimeFormatOpen] = useState(false);
      const [isListOpen, setListOpen] = useState(false);
      const navigate = useNavigate();

      const handleToggleBottomSheet = () => {
            if (!isBottomSheetOpen){
            }
            setBottomSheetOpen((prev) => !prev);
      };
      function handler({ key }) {
            if (ESCAPE_KEYS.includes(String(key))) {
                  resume();
                  focusSelf()
                  if(isBottomSheetOpen)
                  setBottomSheetOpen(false)
                  else if (isStreamOpen)
                  setStreamOpen(false)
                  else if (isPlayerOpen)
                  setPlayerOpen(false)
                  else  if (isTimeFormatOpen)
                  setTimeFormatOpen(false)
                  else if (isBottomSheetOpenParental)
                  setBottomSheetOpenParental(false)
                  else  if (isBottomSheetOpenParentalList)
                  setBottomSheetOpenParentalList(false)
                  else if (isListOpen)
                        setListOpen(false)


                  else
                        navigate(-1)

            }
      }

      useEventListener('keydown', handler);

const closeFn=(id)=>{
      localStorage.setItem('language',id)
      setRerender(!rerender);     //whenever you want to re-render
      resume();
      focusSelf()
      setBottomSheetOpen(false)
      setStreamOpen(false)
}
      const closeFnStream = (id) => {
            localStorage.setItem('streamFormat', id)
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()

            setStreamOpen(false)
      }
      const closeFnListType = (id) => {
            localStorage.setItem('listType', id)
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()
            setListOpen(false)
      }
      const closeFnPlayer = (id) => {
            localStorage.setItem('player', id)
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()
            setPlayerOpen(false)
      }
      const closeFnTime = (id) => {
            localStorage.setItem('timeformat', id)
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()
            setTimeFormatOpen(false)
      }
      const closeFnParental = (id) => {
          //  localStorage.setItem('timeformat', id)
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()
            setBottomSheetOpenParental(false)
      }
      const openFnParental = (id) => {
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()
            setBottomSheetOpenParentalList(true)
      }

      const closeFnParentalList = (id) => {
            //  localStorage.setItem('timeformat', id)
            setRerender(!rerender);     //whenever you want to re-render

            resume();
            focusSelf()
            setBottomSheetOpenParentalList(false)
      }
      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      const setOpenB=()=>{
            console.log("nnnnnnnnnnn")
            setOpen(true)
      }
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  if (payload.id==0){
                        handleToggleBottomSheet()
                  }
                  if (payload.id == 1) {
                        setBottomSheetOpenParental(true)

                  }
                  if (payload.id == 2) {
                        setStreamOpen(true)

                  }
                  if (payload.id == 3) {
                        setPlayerOpen(true)

                  }
                  if (payload.id == 4) {
                        setTimeFormatOpen(true)

                  }
                  if (payload.id == 6) {
                        setListOpen(true)

                  }

            };

            addEventListener('focusEvent', handleCustomEvent);

            return () => {
                  removeEventListener('focusEvent', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);




      return<> <FocusContext.Provider value={focusKey}><div ref={ref}  className=" w-full h-screen px-20 mx-auto overflow-auto  flex flex-col absolute bottom-0 left-0	">

            <h1 className="text-white text-4xl font-bold my-0 mt-28 text-s mx-auto  text-left w-full capitalize ">Setting</h1>
            <ReactInternetSpeedMeter
                  txtSubHeading={"Internet is too slow " + wifiSpeed + " MB/s"}
                  outputType="empty"
                  customClassName={null}
                  txtMainHeading="Opps..."
                  pingInterval={30000} // milliseconds
                  thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                  threshold={8}
                  imageUrl="https://www.sammobile.com/wp-content/uploads/2019/03/keyguard_default_wallpaper_silver.png"
                  downloadSize="2550420" //bytes
                  callbackFunctionOnNetworkDown={(speed) =>
                        console.log(`Internet speed is down ${speed}`)
                  }
                  callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(`Internet speed is down ${speed}`)}
            />
            <div className="  flex flex-row flex-wrap  mt-28  w-full">

                  {listSettings.map((item, index) =>
                        <ItemSetting obj={item} key={'setting' + index} position={index}
                              speed={wifiSpeed}
                              rerender={rerender}
  />

                  )}



  </div>

</div >



      </FocusContext.Provider>
<BottomSheet isOpen={isBottomSheetOpen} onClose={() => {resume(); setBottomSheetOpen(false)}}>
                  {isBottomSheetOpen&&<LanguageCom isOpen={isBottomSheetOpen} onCloseFn={closeFn} />
}
                  </BottomSheet>


            <BottomSheet isOpen={isBottomSheetOpenParental} onClose={null} size="20rem">
                  {isBottomSheetOpenParental && <Parental isOpen={isBottomSheetOpenParental} onCloseFn={closeFnParental} onOpenFn={openFnParental}/>
                  }
            </BottomSheet>


            <BottomSheet isOpen={isStreamOpen} onClose={() => { }}>
                  {isStreamOpen && <StreamType isOpen={isStreamOpen} onCloseFn={closeFnStream} />
                  }
            </BottomSheet>

            <BottomSheet isOpen={isPlayerOpen} onClose={() => { }}>
                  {isPlayerOpen && <PlayerType isOpen={isPlayerOpen} onCloseFn={closeFnPlayer} />
                  }
            </BottomSheet>
            <BottomSheet isOpen={isTimeFormatOpen} onClose={() => { }}>
                  {isTimeFormatOpen && <TimeFormat isOpen={isTimeFormatOpen} onCloseFn={closeFnTime} />
                  }
            </BottomSheet>
            <BottomSheet isOpen={isBottomSheetOpenParentalList} onClose={null} size="75%">
                  {isBottomSheetOpenParentalList && <ParentalList isOpen={isBottomSheetOpenParentalList} onCloseFn={closeFnParentalList} />
                  }
            </BottomSheet>
            <BottomSheet isOpen={isListOpen} onClose={null} size="50%">
                  {isListOpen && <ListType isOpen={isListOpen} onCloseFn={closeFnListType} />
                  }
            </BottomSheet>

                  </>
}

export default Settings;