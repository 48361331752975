import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ItemEpiPlayer = ({ index, epi, season, onFocused, sectionIndex, onClose }) => {

      const { ref, focused } = useFocusable({
            focusKey: season + "-" + epi.episode_num,
            onEnterPress: () => {
                  if (typeof onClose === 'function') {
                        onClose(epi,season);
                  } else {
                  }
            }
});


      useEffect(() => {
            if (focused) {
                  ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                  });
                  onFocused(sectionIndex)
            }
      }, [focused]);


      return (<div ref={ref} id={season+"-"+epi.id}
            className=" w-full flex items-end h-[200px]  "
      >
            <div ref={ref}
                  className="group absolute bottom-[5px] focusable  transition-all duration-300 h-20 outline-none border-0 left-2 right-2"
            >


                  <div className=" h-full bg-categoryColor relative w-full">
                        <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: focused ? 1 : 0 }}
                              transition={{ duration: 0.3 }}
                              className={"relative h-full  w-full flex justify-center items-end"} >

                              <div className={(focused ? "bg-white " : "") + "w-1/3 absolute -bottom-[4px] h-[5px]  mx-auto  left-1/2 transform -translate-x-1/2 "} />
                              <div className="w-full h-full overflow-hidden flex justify-center items-end">
                                    <div className="bg-white w-1/3   transform h-8 filter  top-0 overflow-hidden
                              blur-[2rem]
" ></div>
                              </div>
                        </motion.div>
                        <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                              <h2 className="text-white text-normal my-2 text-2xl">Episode {epi.episode_num}</h2>
                              <div
                                    className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                    style={{ backgroundImage: `url(../images/bordergradient.svg)` }}
                              />
                        </div>
                  </div>
            </div>
            </div>
      );
};

export default ItemEpiPlayer;
