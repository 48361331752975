import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomEvent } from "../../Event/CustomEventContext";
import { channel } from "diagnostics_channel";
import { motion } from "framer-motion";

function ItemCtagory({ type, category, focusKey: focusKeyParam, index }) {
      const navigate = useNavigate();
      const { emitEvent } = useCustomEvent();

      const { ref, focused, focusKey, focusSelf } = useFocusable({
            focusKey: focusKeyParam,


            onEnterPress: () => {

                  onEnterPress();
            },
      });
      const onEnterPress = () => {
            emitEvent('focusEventOpen', index);

            if (type == "live") navigate("/live/" + category.category_id);
            if (type == "movie") navigate("/movies/" + category.category_id);
            if (type == "serie") navigate("/series/" + category.category_id);
      };

      useEffect(() => {
            if (focused) {

                  emitEvent('focusEvent', index);
                  if (!isElementInViewport(ref.current))
                  {ref.current.scrollIntoView({
                        behavior: 'instant',
                        block: 'center'
                  });
            }
            }
      }, [focused]);
      function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();

            return (
                  rect.top >= 0 &&
                  rect.left >= 0 &&
                  rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                  rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
      }

      return <FocusContext.Provider value={focusKey}> <div ref={ref} onClick={onEnterPress}
            onMouseEnter={focusSelf}
 className="group outline-none text-left w-full h-full" > <div
            className=" relative focusable w-full  h-full outline-none border-0 cursor-pointer"
      >
                  <div className={(focused ? "bg-white " : "") + "w-1/3 absolute  mx-auto -top-[4px] h-[5px]  left-1/2 transform -translate-x-1/2 "} />
            <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: focused ? 1 : 0 }}
                  transition={{ duration: 0.3 }}
                  className={"relative h-full overflow-hidden w-full flex justify-center"} >


                  <div className="bg-white w-1/3   transform h-20 filter  top-0 overflow-hidden blur-[3rem]
" ></div>
            </motion.div>


            <div className="  absolute  bg-categoryColor  left-0 right-0 top-0 bottom-0">
                  <div
                        className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-end p-8"
                  >
                        <img src="/images/live.svg" className="w-8" />
                        <h2 className="text-white text-2xl my-2">{category.category_name}</h2>
                        <span className="text-textcolor2 text-lg">{category.lengthCat}</span>
                  </div>
            </div>

            <div
                  className={(focused ? "block " : "hidden") + " absolute left-0 top-0 right-0 bottom-0  "}
                  style={{ "backgroundImage": "url(/images/bordergradient.svg)" }}
            />

      </div>
      </div>
      </FocusContext.Provider>
}

export default ItemCtagory;

