function Setting({ classes}:{ classes?: string}) {
      return ( <svg
            width="2rem"
            height="2rem"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes}
      >
            <path
                  d="M25.2534 7.22659L18.3601 3.23992C17.0401 2.47992 14.9734 2.47992 13.6534 3.23992L6.69344 7.25325C3.93344 9.11992 3.77344 9.39992 3.77344 12.3733V19.6133C3.77344 22.5866 3.93344 22.8799 6.74677 24.7733L13.6401 28.7599C14.3068 29.1466 15.1601 29.3333 16.0001 29.3333C16.8401 29.3333 17.6934 29.1466 18.3468 28.7599L25.3068 24.7466C28.0668 22.8799 28.2268 22.5999 28.2268 19.6266V12.3733C28.2268 9.39992 28.0668 9.11992 25.2534 7.22659ZM16.0001 20.3333C13.6134 20.3333 11.6668 18.3866 11.6668 15.9999C11.6668 13.6133 13.6134 11.6666 16.0001 11.6666C18.3868 11.6666 20.3334 13.6133 20.3334 15.9999C20.3334 18.3866 18.3868 20.3333 16.0001 20.3333Z"
                  fill="url(#paint0_linear_747_1055)"
            />
            <defs>
                  <linearGradient
                        id="paint0_linear_747_1055"
                        x1="14.8997"
                        y1="11.0689"
                        x2="42.9878"
                        y2="36.0998"
                        gradientUnits="userSpaceOnUse"
                  >
                        <stop stopColor="white" />
                        <stop offset="0.200834" stopColor="white" stopOpacity="0.7" />
                        <stop offset="0.392183" stopColor="white" stopOpacity="0.5" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
            </defs>
      </svg>);


}

export default Setting;


/*
function ItemMovie() {
}

export default ItemMovie;
*/