
import { FocusContext, pause, resume, setFocus, updateAllLayouts, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import { List, AutoSizer, Collection } from 'react-virtualized';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ItemEpiPlayer from "../sub/ItemEpiPlayer";
import useRemtoPx from "../../hooks/remToPx";
const HorizontalListWithSections = ({ data, itemWidth, itemHeight, sectionRenderer, cellRenderer, scrollToIndex }) => {
      const rowCount = data.length;
      const { height, width } = useWindowDimensions();

      const rowHeight = itemHeight;
      const totalHeight = data.reduce((sum, section) => sum + itemHeight, 0);

      const rowRenderer = ({ index, key, style }) => {
            const section = data[index];

            return (
                  <div key={key} style={{ ...style}} >
                        {sectionRenderer(section)}
                        <div className="overflow-auto no-scrollbar absolute left-0 top-0 right-0">
                        <Collection
                              cellCount={section.items.length}
                              cellRenderer={({ index: itemIndex, key: itemKey, style: itemStyle }) => (
                                    <div key={itemKey} style={{ ...itemStyle, width: itemWidth, textAlign: 'center', padding: '5px' }}>
                                          {cellRenderer(section.items[itemIndex], itemIndex, index)}
                                    </div>
                              )}
                              cellSizeAndPositionGetter={({ index: itemIndex }) => ({
                                    height: 200,
                                    width: itemWidth,
                                    x: itemIndex * itemWidth,
                                    y: 0,
                              })}
                              height={220}
                              width={section.items.length * itemWidth}
                        />
                        </div>
                  </div>
            );
      };

      return (
            <AutoSizer disableHeight>
                  {({ width }) => (
                        <List
                              width={width}
                              height={height*0.5}
                              rowCount={rowCount}
                              rowHeight={200}
                              scrollToIndex={scrollToIndex}

                              rowRenderer={rowRenderer}
                        />
                  )}
            </AutoSizer>
      );
};


function SerieEpi({ isOpen, serie,onCloseFn, }) {
      const { ref, focusSelf, focusKey } = useFocusable({ isFocusBoundary: true,focusKey:"settingepi"});
      const [data, setData] = useState<any[]>([])
      const [scrollToIndex, setScrollToIndex] = useState<number>(0);


      useEffect(() => {
            console.log("open", isOpen)
            if (isOpen) {
                  setFocus('settingepi');
            }
            else {
            }

      }, [isOpen]);

      const itemHeight = 200;
      useEffect(() => {
            let datacp:any[]=[]
            for(let i=0;i<serie.sea.length;i++){
                  let ob={ section: serie.sea[i], items: serie.episodes[serie.sea[i]] }
                  datacp.push(ob)
            }
            console.log(datacp)
            setData(datacp)
           setTimeout(()=>{
                 setFocus('settingepi');
                 setFocus(serie.epi.season + '-' + serie.epi.episode_num);

           },500)

      }, [serie]);

      const sectionRenderer = (section) => (
            <h1 className="text-white text-3xl my-10 text-left pl-2">
                 Seasson {section.section}
            </h1>

      );

      const cellRenderer = (item,index,sectionIndex) => (
            <ItemEpiPlayer key={index} index={index} epi={item} season={item.season} onFocused={onfocused} sectionIndex={sectionIndex} onClose={onCloseFn} />


      );
      const onfocused = (sectionIndex)=>{
            setScrollToIndex(sectionIndex)
            setTimeout(()=>focusSelf(),100);

      }
      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col text-white	" ref={ref}>
                  <HorizontalListWithSections
                        data={data}
                        itemWidth={useRemtoPx(10)}
                        itemHeight={itemHeight}
                        sectionRenderer={sectionRenderer}
                        cellRenderer={cellRenderer}
                        scrollToIndex={scrollToIndex}

                  />


                  </div>

              </FocusContext.Provider>
}

export default SerieEpi;