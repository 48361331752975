




import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement, useEffect } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';

type SlotProps = {
  name: 'leftActions' | 'leftActionsFocused' | 'rightActions';
  className?: string; // New prop for className

  children?: React.ReactNode;

};

const Slot: React.FC<SlotProps> = ({ children }) => <>{children}</>;

type NavbarContainerProps = {
  children: ReactElement<SlotProps>[];
  event: string;
  classes?: string;

};


const ButtonItemLContenu: React.FC<NavbarContainerProps> & { Slot: React.FC<SlotProps> } = ({ children, event, classes }) => {
  const { emitEvent } = useCustomEvent();

  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      emitEvent(event, "click");


    },
  });
  if (classes == "" || !classes) {
    classes = " py-6 px-12 "
  }
  useEffect(() => {
    console.log('focused array changed:', focused);
    if (focused) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
    //    addToGlobalArray('eeeee')
    // Do something when the global array changes
  }, [focused]); const childrenArray = Children.toArray(children) as ReactElement<SlotProps>[];
  const leftActionsSlot = childrenArray.find((child) => child?.props?.name === 'leftActions');
  const leftActionsSlotFocused = childrenArray.find((child) => child?.props?.name === 'leftActionsFocused');
  const rightActionsSlot = childrenArray.find((child) => child?.props?.name === 'rightActions');


  return (<button ref={ref} className={(focused ? "bg-gradient-to-r from-white to-white shadow " : "") + "   hover:bg-gradient-to-r  hover:from-white  hover:to-white  hover:shadow outline-none  text-textcolor2 font-bold  inline-flex items-center cursor-pointe " + classes}
    tabIndex={-1}
      style={{
        boxShadow:" inset 0 0 1.2rem #10101B"
}}
  >


    {!focused && <div className="flex">{leftActionsSlot?.props?.children}</div>}
    {focused && <div className="flex">{leftActionsSlotFocused?.props?.children}</div>}
    <div className={((focused ? "text-primary" : "text-white")) + " flex"}>{rightActionsSlot?.props?.children}</div>
  </button>
  );
};

ButtonItemLContenu.Slot = Slot;

export default ButtonItemLContenu;

