import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement, useEffect } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';

type SlotProps = {
  name: 'leftActions' | 'leftActionsFocused' | 'rightActions' | 'leftActionsliked' | 'leftActionsFocusedliked';
  className?: string; // New prop for className

  children?: React.ReactNode;

};

const Slot: React.FC<SlotProps> = ({ children }) => <>{children}</>;

type NavbarContainerProps = {
  children: ReactElement<SlotProps>[];
  event: string;
  isliked: Boolean;

  classes?: string;

};

const CircleButtonLike: React.FC<NavbarContainerProps> & { Slot: React.FC<SlotProps> } = ({ children, event, classes, isliked }) => {
  const { emitEvent } = useCustomEvent();

  const { ref, focused, focusKey, focusSelf } = useFocusable({
    focusKey:'action-'+event,
    onEnterPress: () => {
      onEnterPress()


    },
  });
  const onEnterPress = () => {
    emitEvent(event, "click");

  }
  if(classes==""|| !classes){
    classes =" py-6 px-12 "
  }
  useEffect(() => {
    console.log('focused array changed:', focused);
    if (focused) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
    //    addToGlobalArray('eeeee')
    // Do something when the global array changes
  }, [focused]);  const childrenArray = Children.toArray(children) as ReactElement<SlotProps>[];
  const leftActionsSlot = childrenArray.find((child) => child?.props?.name === 'leftActions');
  const leftActionsSlotFocused = childrenArray.find((child) => child?.props?.name === 'leftActionsFocused');
  const rightActionsSlot = childrenArray.find((child) => child?.props?.name === 'rightActions');
  const leftActionsSlotLiked = childrenArray.find((child) => child?.props?.name === 'leftActionsliked');
  const leftActionsSlotFocusedLiked = childrenArray.find((child) => child?.props?.name === 'leftActionsFocusedliked');

  return (<FocusContext.Provider value={focusKey} ><div ref={ref} onClick={onEnterPress}
    onMouseEnter={focusSelf}
    className={(focused ? "border-2 border-thirdCOlor" : " border-transparentWhite50 ") + " border  w-14 h-14 rounded-full mr-4 focusable outline-none flex justify-center items-center"}
    tabIndex={-1}
  >



    {(isliked&&!focused) && <div className="flex">{leftActionsSlot?.props?.children}</div>}
    {(isliked && focused) && <div className="flex">{leftActionsSlotFocused?.props?.children}</div>}

    {(!isliked && !focused) && <div className="flex">{leftActionsSlotLiked?.props?.children}</div>}
    {(!isliked && focused) && <div className="flex">{leftActionsSlotFocusedLiked?.props?.children}</div>}
      <div className="flex">{rightActionsSlot?.props?.children}</div>
</div></FocusContext.Provider>  );
};

CircleButtonLike.Slot = Slot;

export default CircleButtonLike;

