import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect } from 'react';

const ItemTrack = ({ index, obj, onClose, focusKey: focusKeyParam }) => {

      const json = obj!=null?JSON.parse(obj.extraInfo):null;
      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,
            onEnterPress: () => {
                  if (typeof onClose === 'function') {
                        onClose(obj != null ?obj.index:99);
                  }else{
                        console.log(typeof onClose)
                  }
            },
      });

      useEffect(() => {
            if (focused) {
                  ref.current.scrollIntoView({
                        behavior: 'instant',
                        block: 'center'
                  });

            }
            //    addToGlobalArray('eeeee')
            // Do something when the global array changes
      }, [focused]);
      return (
            <FocusContext.Provider value={focusKey}>
            <div ref={ref}
                        className="group relative focusable min-w-[10rem] transition-all duration-300 h-[6rem] outline-none border-0 max-w-[10rem] m-2 "
            >
                  <div
                        className={(focused ?"bg-white":"")+" w-1/3 mx-auto h-[0.4rem] bottom-[0.6rem] absolute left-1/2 -translate-x-1/2 "}
                  />

                  <div className="backdrop-blur-3xl h-[5rem] bg-categoryColor relative w-full">
                        <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                                    {!json && <>
                                          <h2 className="text-white text-normal my-2 text-xl">
                                              Disable
                                          </h2>
</>}
                                    {json&&<>
                                    {json.track_lang == "" &&   <h2 className="text-white text-normal my-2 text-xl">
                                           Caption {json.track_num}
                              </h2>
}
                                    {json.track_lang != "" && <h2 className="text-white text-normal my-2 text-xl">
                                         {json.tack_lang}
                                    </h2>
                                    }
                                    </>
                                    }
                              <div
                                    className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                    style={{ backgroundImage: `url('../images/bordergradient.svg')` }}
                              />
                        </div>
                  </div>
            </div>
            </FocusContext.Provider>
      );
};

export default ItemTrack;
