


function Resolution({ type }) {
switch(type){
      case '8k':
            return <div className="w-14 "><img src="/images/8k.svg"/></div>
      case '4k':
            return <div className="w-14 "><img src="/images/4k.svg" /></div>
      case '1080':
            return <div className="w-14 "><img src="/images/1080.svg" /></div>
      case '720':
            return <div className="w-14 "><img src="/images/720.svg" /></div>
      case '480':
            return <div className="w-14"><img src="/images/480.svg" /></div>
            default:
            return <></>


}
}

export default Resolution;