import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import a CSS effect (optional)
import LazyLoad from 'react-lazyload';
import { useEffect } from "react";
import { useCustomEvent } from "../../Event/CustomEventContext";
import Like from "../svg/Like";
import { motion } from "framer-motion";

function ItemChannelList({ category, position, focusKey: focusKeyParam,onChannelChange,cat}) {
      const { emitEvent } = useCustomEvent();

      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,
            onEnterPress: () => {
                  onChannelChange( position);

            },
      });
      useEffect(() => {
            if (focused) {
                  emitEvent('focusEventOpen', position);

            }
      }, [focused]);

      return <FocusContext.Provider value={focusKey}> <div ref={ref} className="h-full focusable outline-0 relative max-w-full overflow-hidden" >
              <img
            src="/images/bordergradient2.svg"
                  className={(focused ? "visible" :"invisible")+" absolute w-full h-full left-0 top-0  z-10 ml-1"}
      />

            <div
                  className={(focused ? "bg-white" : "bg-transparent") + " w-[4px] h-3/4   absolute left-0 top-1/2 -translate-y-1/2"}
            >


            </div>
            <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: focused ? 1 : 0 }}
                  transition={{ duration: 0.3 }}
                  className={"absolute h-full overflow-hidden w-full flex justify-center left-0 right-0 top-0"} >


                  <div className="bg-white w-full h-3/4   absolute left-0    top-0  blur-[3rem] opacity-30
" ></div>
            </motion.div>
            <div
                  className={(cat.category_id == category.category_id ? "bg-secondary " : "") +(focused ? "bg-gradientchannel backdrop-blur-2xl" : "") + " absolute inline-flex space-x-8 items-center justify-start w-full h-full pl-4 py-4 g  ml-1 left-0 right-0 top-0"}
            >
                  <p className="text-xl font-bold text-white">#{(position+1)}</p>

                  <p className="text-xl font-bold text-white text-left">{category.category_name}</p>
            </div>

      </div>
      </FocusContext.Provider>
}

export default ItemChannelList;