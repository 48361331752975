import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement, useEffect } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';

type SlotProps = {
  name: 'leftActions' | 'leftActionsFocused' | 'rightActions';
  className?: string; // New prop for className

  children?: React.ReactNode;

};

const Slot: React.FC<SlotProps> = ({ children }) => <>{children}</>;

type NavbarContainerProps = {
  children: ReactElement<SlotProps>[];
  event: string;
  classes?: string;
  onevent?: any;
  light?: boolean;

};

const ButtonItem: React.FC<NavbarContainerProps> & { Slot: React.FC<SlotProps> } = ({ children, event, classes, onevent, light }) => {
  const { emitEvent } = useCustomEvent();

  const { ref, focused, focusSelf  } = useFocusable({
    focusKey:"action-"+event,
    onEnterPress: () => {
      onEnterPress()

    },
  });
  const onEnterPress =()=>{
    if (!onevent)
    emitEvent(event, "click");
  else{
      onevent();
  }

  }
  if(classes==""|| !classes){
    classes =" py-8 px-16 "
  }
  useEffect(() => {
    console.log('focused array changed:', focused);
    if (focused) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
    //    addToGlobalArray('eeeee')
    // Do something when the global array changes
  }, [focused]);  const childrenArray = Children.toArray(children) as ReactElement<SlotProps>[];
  const leftActionsSlot = childrenArray.find((child) => child?.props?.name === 'leftActions');
  const leftActionsSlotFocused = childrenArray.find((child) => child?.props?.name === 'leftActionsFocused');
  const rightActionsSlot = childrenArray.find((child) => child?.props?.name === 'rightActions');

  return (<button ref={ref} onClick={onEnterPress}
    onMouseEnter={focusSelf}
  className={(focused && !light ?"bg-buttongradient text-white":"") +"   hover:bg-buttongradient outline-none  text-textcolor2 font-bold  inline-flex items-center cursor-pointe r" +classes}
                                                tabIndex={-1}
                                          >


    {!focused && <div className="flex">{leftActionsSlot?.props?.children}</div>}
    {focused && <div className="flex">{leftActionsSlotFocused?.props?.children}</div>}
      <div className="flex ">{rightActionsSlot?.props?.children}</div>
                                          </button>
  );
};

ButtonItem.Slot = Slot;

export default ButtonItem;

