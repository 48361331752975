import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BgEpi from '../svg/BgEpi';
import { useCustomEvent } from '../../Event/CustomEventContext';
import { useGlobalContext } from '../../model/GlobalContext';
import Mask from './Mask';
import LazyLoad from 'react-lazyload';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import a CSS effect (optional)
import { motion } from 'framer-motion';

const ItemSeason = ({ index, season, cover, focusKey: focusKeyParam }) => {
      const navigate = useNavigate();
      const { emitEvent } = useCustomEvent();

      const { serie, changeSerie, profile } = useGlobalContext();

      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,

            onEnterPress: () => {
                  serie.epi = season
                  serie.playon=0;
                  changeSerie(serie )
                  navigate("/serieplayer/" )
            },
      });
      useEffect(() => {
            if (focused) {
                  emitEvent('focusEvent', index);
                  ref.current.scrollIntoView({
                        behavior: 'instant',
                        block: 'center'
                  });

            }
            //    addToGlobalArray('eeeee')
            // Do something when the global array changes
      }, [focused]);
/*
*/
      return (<FocusContext.Provider value={focusKey}>
            <div ref={ref}
                  className="group relative focusable w-full  transition-all  h-full outline-none "
            >

                  <div className="  h-full  bg-primary relative w-full top-0 bottom-0 left-0 right-0">
                        <div className={(focused ? "bg-white " : "") + "w-1/3 absolute -top-[4px] h-[5px] z-0    mx-auto  left-1/2 transform -translate-x-1/2 "} />
                        <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: focused ? 1 : 0 }}
                              transition={{ duration: 0.3 }}
                              className={"relative h-full overflow-hidden w-full flex justify-center"} >


                              <div className="bg-white w-1/3   transform h-20 filter  top-0 overflow-hidden blur-[5rem]
" ></div>
                        </motion.div>
                        <div className='absolute top-0 left-0 z-10 right-0 bottom-0'><Mask mask={"linear-gradient(180deg,transparent   -20%,  rgb(0, 0, 0) 195%)"}>
                              <LazyLoad height="100%" offset={100}>

                                    <LazyLoadImage
                                          alt={cover}
                                          height="100%"
                                          width="100%"
                                          src={cover}
                                    />
                              </LazyLoad>

                        </Mask>
                        </div>
                        <div
                              className="w-full h-full bg-center bg-cover absolute bottom-0 z-10 left-0 flex flex-col justify-end p-4"
                        >
                              <h2 className="text-white text-2xl my-2 font-medium text-start">Episode {season.episode_num}</h2>
                        </div>
                        <div
                              className={(focused ? "block " : " hidden ") + "absolute top-0 z-10 bottom-0 left-0 right-0 "}
                              style={{ backgroundImage: "url(/images/bordergradient.svg)" }}
                        />
                  </div>

            </div>
      </FocusContext.Provider>

      );
};

export default ItemSeason;
