import React, { useRef, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useEventListener from '@use-it/event-listener';
import ReactPlayer from 'react-player';
const ESCAPE_KEYS = ['27', '10009'];
const REWIND_KEYS = ['412', '37'];
const FF_KEYS = ['417', '39'];
const PAUSE_KEYS = ['19'];
const PLAY_KEYS = ['415'];
const PAUSEPLAY_KEYS = ['10252'];

function YoutubePlayer() {
      const playerRef = useRef<ReactPlayer | null>(null);
      const [playing, setPlaying] = useState(true);
      const [controls, setControls] = useState(true);

      const navigate = useNavigate();
      const { id } = useParams();

      function handler({ keyCode }) {
            if (ESCAPE_KEYS.includes(String(keyCode))) {

                        navigate(-1)

            }
            if (REWIND_KEYS.includes(String(keyCode))) {
                  handleRewind()

            }
            if (FF_KEYS.includes(String(keyCode))) {
                  handleForward()


            }
            if (PLAY_KEYS.includes(String(keyCode))) {
                  handlePlay()

            }
            if (PAUSE_KEYS.includes(String(keyCode))) {
                  handlePause()

            }
            if (PAUSEPLAY_KEYS.includes(String(keyCode))) {
setPlaying((play)=> !play)
            }
      }

      useEventListener('keydown', handler);


      const handleRewind = () => {
            if (playerRef.current) {

            // Implement rewind logic (e.g., decrease current time by 10 seconds)
            const currentTime = playerRef.current.getCurrentTime();
            playerRef.current.seekTo(currentTime - 10, 'seconds');
                  setControls(false)
                  setControls(true)

            }
      };

      const handleForward = () => {
            // Implement forward logic (e.g., increase current time by 10 seconds)
            if (playerRef.current) {
    const currentTime = playerRef.current.getCurrentTime();
            playerRef.current.seekTo(currentTime + 10, 'seconds');
                  setControls(true)

            }
      };

      const handlePause = () => {
            if (playerRef.current) {
                  setPlaying(false)
                  setControls(true)
}
      };

      const handlePlay = () => {
            if (playerRef.current) {
                  setPlaying(true)
                  setControls(true)

}
      };

      return (
            <div>
                  <ReactPlayer
                        ref={playerRef}
                        url={`https://www.youtube.com/watch?v=${id}`}
                        controls={controls}
                        width="100%"
                        height="100vh"
                        playing={playing}

                  />

            </div>
      );

}
export default YoutubePlayer;