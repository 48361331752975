import WifiIcon from "../svg/WifiIcon";

function NoInternet(){
  return  <div className="absolute left-0 top-0 right-0 bottom-0 z-50 flex items-center justify-center">
        <div className=" w-1/3     ">
        <div className="relative isolate overflow-hidden bg-secondary px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">


                    <span className="relative flex h-24 w-24 mx-auto">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                          <WifiIcon></WifiIcon>
                    </span>
                    <h2 className="mx-auto max-w-2xl mt-10 text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Please check your connection !</h2>
              <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-200">please verify you internet settings.</p>

              <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
                        aria-hidden="true">
                        <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7"></circle>
                        <defs>
                              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(512 512) rotate(90) scale(512)">
                                    <stop stop-color="#7775D6"></stop>
                                    <stop offset="1" stop-color="#E935C1" stop-opacity="0"></stop>
                              </radialGradient>
                        </defs>
                  </svg>
            </div>
      </div></div>
}
export default NoInternet;