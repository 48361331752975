
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import ItemSettingSheet from "../sub/ItemSettingSheet";
import ItemExit from "../sub/ItemExit";

function ExitModal({isOpen, onCloseFn}) {
      const { ref, focusSelf, focusKey, focused } = useFocusable({focusKey:"Language"});
      const [languageList, setLanguageList] = useState<any[]>([{ id: "0", title: "Exit application" }, { id: "1", title: "List User " }, { id: "2", title: "Cancel " } ]);


      useEffect(() => {
            console.log("open", isOpen)
            if (isOpen)
            {
                  setFocus('Language');

}
            else{
            }

      }, [isOpen]);
      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col 	" ref={ref}>

            <h1 className="text-white text-4xl font-bold my-0 mt-10 text-s mx-auto  text-left w-full capitalize ">Exit app</h1>

            <div className=" flex flex-col   mt-28  w-full">
                        {languageList.map((obj, index) => (
                              <ItemExit key={index} index={index} obj={obj} onClose={onCloseFn}  />
                        ))}
                  </div>
                  </div>

              </FocusContext.Provider>
}

export default ExitModal;