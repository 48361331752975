import React, { useEffect, useState } from 'react';
import DeleteIcon from '../svg/deleteIcon';
import LanguageIcon from '../svg/LanguageIcon';
import SpaceKeyIcon from '../svg/SapceKey';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import KeyboardKey from './KeybordKey';

const Keyboard = ({ width ="w-3/12", onKeyPress,hasLeftRight=false }) => {
      const { ref, hasFocusedChild, focusKey } = useFocusable({
            focusKey:"Keyboard",
             trackChildren: true,
            saveLastFocusedChild: false,
 });

      const [language, setLanguage] = useState('english');
      const [displayedKeys, setDisplayedKeys] = useState(['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'X', 'C', 'V', 'B', 'N', 'M']);

      const keys = {
            english: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'X', 'C', 'V', 'B', 'N', 'M'],
            arabic: ['ض', 'ص', 'ث', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج', 'د', 'ش', 'س', 'ي', 'ب', 'ل', 'ا', 'ء', 'ئ', 'ت', 'ن', 'م', 'ك', 'ط', 'ئ'],
            numeric:['0','1','2','3','4','5','6','7','8','9']
      };


      const handleKeyPress = (key) => {
            if(key=="Language"){
toggleLanguage();
            }else{
                  onKeyPress(key);

            }
      };

      const toggleLanguage = () => {
            setLanguage(language === 'english' ? 'arabic' : (language === 'arabic' ? 'numeric' :'english'));
            setDisplayedKeys([]);
            setDisplayedKeys([...keys[language === 'english' ? 'arabic' : (language === 'arabic' ? 'numeric' : 'english')]]);

      };

      return (
            <FocusContext.Provider value={focusKey}>
                  <div className={width + "  h-min   relative"} ref={ref}>
                        <div className='relative rounded-md m-4 bg-primary p-4 flex flex-col '>
            <div className="flex flex-wrap	flex-row	">
                  {displayedKeys.map((key, index) => (

                        <KeyboardKey text={key} index={index} handleKeyPress={handleKeyPress}></KeyboardKey>
                  ))}
            </div>
                        <div className="flex flex-wrap	flex-row	">

                        <KeyboardKey text={'Language'} index={96} handleKeyPress={handleKeyPress}></KeyboardKey>
                        <KeyboardKey text={'Delete'} index={97} handleKeyPress={handleKeyPress}></KeyboardKey>
                        <KeyboardKey text={'Space'} index={98} handleKeyPress={handleKeyPress}></KeyboardKey>


                                    {hasLeftRight&& <>
                                    <KeyboardKey text={'LEFT'} index={66} handleKeyPress={handleKeyPress}></KeyboardKey>
                                    <KeyboardKey text={'RIGHT'} index={65} handleKeyPress={handleKeyPress}></KeyboardKey>
                                    </>
                                    }
                                    <KeyboardKey text={'CLEAR'} index={99} handleKeyPress={handleKeyPress}></KeyboardKey>



</div>
</div>
                  </div>
            </FocusContext.Provider>
      );
};

export default Keyboard;
