import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Progress from "./sub/Progress";
import { useGlobalContext } from "../model/GlobalContext";
import ItemCtagory from "./sub/ItemCtagory";
import { FocusContext, FocusDetails, getCurrentFocusKey, navigateByDirection, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import { Collection } from 'react-virtualized';
import { useCustomEvent } from "../Event/CustomEventContext";
import useRemtoPx from "../hooks/remToPx";
import Nav from "./sub/Nav";
import useEventListener from "@use-it/event-listener";
import BottomSheet from "./Settings/BottomSheet";
import Parental from "./Settings/Parental";
import toast, { Toaster } from 'react-hot-toast';

const ESCAPE_KEYS = ['27', '10009'];

function Category() {
      const navigate = useNavigate();
      const [keySequence, setKeySequence] = useState('');
      const [lastKeyPressTime, setLastKeyPressTime] = useState(0);


      const { height, width } = useWindowDimensions();
      const _windowScroller = useRef(null);

      const [isBottomSheetOpenParental, setBottomSheetOpenParental] = useState(false);

      const collectionRef = createRef<Collection>();
      const { addEventListener, removeEventListener } = useCustomEvent();
      const itemHeight = useRemtoPx(12)
      const itemMargin = useRemtoPx(0.5)
      const [listcat, setListcat] = useState<any[]>([]);
      const [isLoadinfShow, setLoadinfShow] = useState(true);
      const { focusSelf, focusKey } = useFocusable();
      const [listPos, setListPos] = useState<number>(0);
      let windowScroller:WindowScroller ;
      const [items, setItems] = useState<string[]>([]);

      useEffect(() => {
            focusSelf();
      }, [focusSelf]);


      useEffect(() => {
            const handleKeyDown = (event) => {
                  const currentTime = new Date().getTime();
                  const keyPressed = event.key;
                  if (['1', '2', '3', '4', '5', '6'].includes(keyPressed)) {
                        if (currentTime - lastKeyPressTime <= 1000) {
                              if (keyPressed=='1'){
                                    setKeySequence('')
                              }
                              setKeySequence(prevSequence => prevSequence + keyPressed);
                        } else {
                              setKeySequence(keyPressed);
                        }
                        setLastKeyPressTime(currentTime);

                        if (keySequence === '12345' && keyPressed=='6') {
                              setKeySequence('');
                              if (parentalEnable){
                                    changeParental(false)


                              }else{
                                    let password = localStorage.getItem('password');
                                    if (!password) {
                                          toast.error("This didn't work.", {
                                                position: "bottom-center",
                                                style: { borderRadius: "200px", background: "#8181B5", color: " #fff" },
                                          });
                                    } else {
                                          setBottomSheetOpenParental(true)

                                    }
                              }


                        }
                  } else {
                        setKeySequence('');
                  }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                  document.removeEventListener('keydown', handleKeyDown);
            };
      }, [lastKeyPressTime, keySequence]);



      const { catLive, catVod, catSerie,settings,changeSettings,profileShow,parentalEnable,changeParental} = useGlobalContext();
      const { type } = useParams();

      useEffect(() => {
            const storedItems = JSON.parse(localStorage.getItem('parentallist') || '[]') || [];
            setItems(storedItems);

            if (type == 'live') {
                  setListcat(parentalEnable ?catLive:catLive.filter(item => !storedItems.includes(String(item.category_id))))

                  setLoadinfShow(false)
            }
            if (type == 'movie') {

                  setListcat(catVod)

                  setLoadinfShow(false)
            }
            if (type == 'serie') {

                  setListcat(catSerie)

                  setLoadinfShow(false)

            }
            setTimeout(()=>{
                  focusSelf();
                  setListPos(settings.position)
                  console.log(settings.position)

                  if (_windowScroller.current) {
                        let variableName = _windowScroller.current as WindowScroller;

                        variableName.updatePosition();
                  }
                  setFocus('CAT' + settings.position);

                  scrollToIndex(settings.position)
            },0)

      }, [catLive, catVod, catSerie, settings,parentalEnable]);



      function handler({ keyCode }) {


            if (ESCAPE_KEYS.includes(String(keyCode))) {
                  setTimeout(()=>{
                        if (!profileShow)
                        navigate(-1)

                  })

            }
      }

      useEventListener('keydown', handler);
      const cellRenderer = ({ index, key, style }) => (
            <div key={key} style={style} className="p-2">
                  <ItemCtagory type={type} category={listcat[index]} key={'item' + index}
                        focusKey={'CAT' + index} index={index}
                  />
            </div>
      );
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  focusSelf();
                  setListPos(payload)

                  if (_windowScroller.current)
                  {
                        let variableName = _windowScroller.current as WindowScroller;

                        variableName.updatePosition();
                  }
                //  scrollToIndex(payload)

            };

            addEventListener('focusEvent', handleCustomEvent);
            addEventListener('focusEventOpen', (payload)=>{
                  changeSettings({position:payload})
            });

            return () => {
                  removeEventListener('focusEvent', handleCustomEvent);
                  removeEventListener('focusEventOpen', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);
      const scrollToIndex = (index) => {
            // Calculate the position to scroll to based on the index and cell height
            const columnCount = 4; // Assuming 3 columns

            const cellHeight = itemHeight;
            const margin = itemMargin; // Adjust this based on any margins

            const row = Math.floor(index / columnCount);
            const scrollToPosition = row * (cellHeight + margin);
            window.scrollTo(0, scrollToPosition);

      };
      const cellSizeAndPositionGetter = ({ index }) => {
            const columnCount = 4;
            const rowCount = Math.ceil(listcat.length / columnCount);
            const cellWidth = ((width * 0.90)) / 4 - itemMargin;
            const cellHeight = itemHeight;
            const margin = itemMargin;

            const column = index % columnCount;
            const row = Math.floor(index / columnCount);

            return {
                  height: cellHeight,
                  width: cellWidth,
                  x: column * cellWidth,
                  y: row * (cellHeight),

            };
      };
      const changeScroll = () => {
            focusSelf();

      }
      var arrow_keys_handler = function (e) {
            switch (e.code) {
                  case "ArrowUp": case "ArrowDown": case "ArrowLeft": case "ArrowRight":
                  case "Space": e.preventDefault(); break;
                  default: break; // do not block other keys
            }
      };
      const closeFnParental = (id) => {
            //  localStorage.setItem('timeformat', id)

            focusSelf()
            setBottomSheetOpenParental(false)
      }
      const openFnParental = (id) => {
            changeParental(true)
            setBottomSheetOpenParental(false)

            focusSelf()

      }
      window.addEventListener("keydown", arrow_keys_handler, false);
      useEffect(() => {

            const wheelListener = (event) => {
                  const delta = Math.sign(event.deltaY);
                  if (delta > 0) {
                        const focusDetails: FocusDetails = {
                              direction: 'down',
                              from: getCurrentFocusKey(),
                              // Other necessary details based on the library's documentation
                        };

                        navigateByDirection('down', focusDetails)
                  } else if (delta < 0) {
                        const focusDetails: FocusDetails = {
                              direction: 'up',
                              from: getCurrentFocusKey(),
                              // Other necessary details based on the library's documentation
                        };
                        navigateByDirection('up', focusDetails)
                  }
            };

            // Attach the wheel event listener
            document.addEventListener('wheel', wheelListener);

            // Remove the wheel event listener when the component is unmounted
            return () => {
                  document.removeEventListener('wheel', wheelListener);
            };
      }, []);
      const handleWheel = (event) => {
            // Prevent the default wheel scroll behavior
            event.preventDefault();
      };
      return <FocusContext.Provider value={focusKey}><div className=" w-full h-screen px-20 mx-auto bg-primary  flex flex-col absolute bottom-0 left-0	" >
            {isLoadinfShow &&
                  <Progress />
            }
            <Nav />
            <h1 className="text-white text-4xl font-bold my-0 mt-28 text-s mx-auto  text-left w-full capitalize ">{type + "s"}</h1>

            <div className="   flex-grow  mt-28  w-full list">
                  <WindowScroller ref={_windowScroller}>
                        {({ height, scrollTop, onChildScroll }) => (
                              <div >

                                    <AutoSizer disableHeight>
                                          {({ width }) => (

                                                <Collection
                                                      autoHeight
                                                      tabIndex={-1}
                                                      ref={collectionRef}
                                                      height={height}
                                                      width={width}
                                                      scrollTop={scrollTop}
                                                      cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                                                      onScroll={changeScroll}
                                                      scrollToCell={listPos}

                                                      cellRenderer={cellRenderer}
                                                      cellCount={listcat.length}
                                                      overscanRowCount={60}
                                                />


                                          )}
                                    </AutoSizer>
                                    <div style={{ height: 100 }} /> {/* Spacer div to account for future content height */}
                              </div>
                        )}
                  </WindowScroller>



            </div>
      </div >
            <BottomSheet isOpen={isBottomSheetOpenParental} onClose={null} size="20rem">
                  {isBottomSheetOpenParental && <Parental isOpen={isBottomSheetOpenParental} onCloseFn={closeFnParental} onOpenFn={openFnParental} />
                  }
            </BottomSheet>
            <Toaster />

      </FocusContext.Provider>

}

export default Category;