import React from 'react';

const BadgeTextIcon = () => {
      return (
            <svg xmlns="http://www.w3.org/2000/svg" width="13rem" height="13rem" viewBox="0 0 247 196" fill="none">
                  <path d="M47.2949 0.435059L246.247 115.3L212.112 149.162L199.855 195.655L0.902203 80.7896L35.0375 46.9279L47.2949 0.435059Z" fill="url(#paint0_linear_191_2277)" />
                  <defs>
                        <linearGradient id="paint0_linear_191_2277" x1="202.404" y1="89.131" x2="164.194" y2="182.128" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#51C0FF" />
                              <stop offset="1" stop-color="#419ED1" />
                        </linearGradient>
                  </defs>
            </svg>
      );
};

export default BadgeTextIcon;
