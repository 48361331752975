import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement, useEffect, useRef, useState } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';
import SpaceKeyIcon from '../svg/SapceKey';
import DeleteIcon from '../svg/deleteIcon';
import LanguageCom from './language';
import LanguageIcon from '../svg/LanguageIcon';
import TriangleLeftIcon from '../svg/TriangleLeftIcon';
import TriangleRightIcon from '../svg/TriangleRightIcon';



function KeyboardKey({ text, index,handleKeyPress }) {

      const { ref, focusKey, focusSelf, focused } = useFocusable({
            focusKey:'keyboard_'+index,
            onEnterPress: () => {
                  handleKeyPress(text)

            },
});
      return <button key={`${text}_0}`} ref={ref} className={(focused ? 'bg-buttongradient ' : 'bg-secondary ') + ((text === 'Space' )? "flex-grow " :"max-w-20 ")+ "  rounded-md min-w-20  outline-none  text-textcolor2 font-bold m-[2px]  items-center cursor-pointe  flex justify-center py-4 w-[4.3rem] "} onClick={() => handleKeyPress(text)}>
                  {text == 'Space' && <SpaceKeyIcon />}
                  {text == 'Delete' && <DeleteIcon/>}
            {text == 'Language' && <LanguageIcon />}
            {text == 'LEFT' && <TriangleLeftIcon />}
            {text == 'RIGHT' && <TriangleRightIcon />}
            {(text !== 'Space' && text !== 'Delete' && text !== 'Language' && text !== 'LEFT' && text !== 'RIGHT') && String(text)}
            </button>
}

export default KeyboardKey;

