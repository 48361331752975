import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Progress from "./sub/Progress";
import { useGlobalContext } from "../model/GlobalContext";
import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import 'react-virtualized/styles.css'; // import the styles
import React from "react";

import { useCustomEvent } from "../Event/CustomEventContext";
import { deleterow, generateurl, getVideoPositions, insertData } from "../model/constant";
import Bg from "./svg/Bg";
import Users from "./svg/Users";
import DateImg from "./svg/date";
import Play from "./svg/play";
import Youtube from "./svg/youtube";
import Like from "./svg/Like";
import ButtonItem from "./sub/ButtonItem";
import Nav from "./sub/Nav";
import LazyLoad from 'react-lazyload';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import a CSS effect (optional)

import useEventListener from "@use-it/event-listener";
import ButtonItemLike from "./sub/ButtonItemLike";
import LikeEmpty from "./svg/LikeEmpty";
import Mask from "./sub/Mask";
import ButtonItemLContenu from "./sub/ButtonItemLContenu";
import WatchingIcon from "./svg/WatchingIcon";
const ESCAPE_KEYS = ['27', 'Escape', "XF86Back"];

const Movie: React.FC = () => {
      const navigate = useNavigate();

      function handler({ key }) {
            if (ESCAPE_KEYS.includes(String(key))) {

                  setTimeout(() => {
                        if (!profileShow)
                              navigate(-1)

                  })

            }
      }

      useEventListener('keydown', handler);
      const { addEventListener, removeEventListener } = useCustomEvent();

      const [isLoadinfShow, setLoadinfShow] = useState(true);
      const [movieObj, setMovieObj] = useState<any>({});
      const [currentpos, setCurrentpos] = useState<number>(0);
      const [movieLiked, setMovieLiked] = useState<boolean>(false);
      const [movieTrailer, setMovieTrailer] = useState<boolean>(false);
      const { ref, focusSelf, focusKey } = useFocusable({ trackChildren: true });
      const scrollingRef = useRef<HTMLDivElement>(null);
      const { movie, changeListVodFav, profile, listVodFav, profileShow,changeMovie } = useGlobalContext();
let movieOB;
      useEffect(() => {
            setMovieLiked(listVodFav.includes(String(movie.stream_id)))
      }, [listVodFav]);


      useEffect(() => {
            if (movieObj.stream_id==null)
            getMovieDetail(movie.stream_id, profile);
            const videoPositions = getVideoPositions();

            const storedPosition = videoPositions[String(movie.stream_id + "") ];

            if (storedPosition !== undefined) {
                  // Set the video position to the stored position
                  setCurrentpos( parseFloat(storedPosition));
            }

      }, [profile]);
      const getMovieDetail = async (id, playlist) => {
            const requestOptions: RequestInit = {
                  method: "GET",
                  redirect: "follow",
            };

            try {
                  const response = await fetch(generateurl("get_vod_info", playlist) + "&vod_id=" + id, requestOptions);
                  const result = await response.json();
                  result.stream_id = id;
                  movieOB =result;
                  setMovieObj({...result});
                  if (result.info.youtube_trailer){
                        setMovieTrailer(true)
                  }
            } catch (error) {
                  console.error("error", error);
            }
      };
      const handleYoutube = (payload) => {
            navigate("/youtube/" + movieOB.info.youtube_trailer)

      };
      const handlePlay = (payload) => {
            const mov = { ...movie }
            mov.playon = 0
            changeMovie(mov)

            navigate("/movieplayer")
      };

      const handleLike = (payload) => {
            var streamId = String(movie.stream_id);
            const arrayFav = [...listVodFav]
            if (listVodFav.includes(streamId)) {
                let arr=  arrayFav.filter(el => el !== streamId)
                  deleterow(streamId, "movie", profile);

                  changeListVodFav(arr)
            } else {
                  arrayFav.push(streamId)
                  insertData(streamId, "movie", profile)
                  changeListVodFav(arrayFav)

            }
            setMovieLiked((serielike) => !serielike)
      };
      const handlePlayC = (payload) => {
            setTimeout(()=>{
                  const mov = { ...movie }
                  const videoPositions = getVideoPositions();

                  const storedPosition = videoPositions[String(movie.stream_id + "")];
                  if (storedPosition !== undefined) {
                        // Set the video position to the stored position
                        const tt = (parseFloat(storedPosition));
                        mov.playon = tt

                  }
                  changeMovie(mov)
                  navigate("/movieplayer")
            })

      };

      useEffect(() => {


            addEventListener('youtube', handleYoutube);
            addEventListener('play', handlePlay);
            addEventListener('like', handleLike);
            addEventListener('continue', handlePlayC);

            return () => {
                  removeEventListener('youtube', handleYoutube);
                  removeEventListener('like', handleLike);
                  removeEventListener('play', handlePlay);
                  removeEventListener('continue', handlePlayC);

            };
      }, [addEventListener, removeEventListener]);

      useEffect(() => {
            focusSelf();
      }, [focusSelf]);

      if (!movieObj) {
            return <Progress></Progress>; // Return some loading UI or placeholder if movieData is not available
      }
      const formatTime = (timeInSeconds) => {
            const minutes = Math.floor((timeInSeconds) / 60);
            const seconds = Math.floor(timeInSeconds % 60);

            const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            return formattedTime;
      };

      return <FocusContext.Provider value={focusKey}>
            <Nav/><div ref={scrollingRef} className="outline-none" >
            {movieObj.info &&( <><div
                  className=" w-full h-screen overflow-hidden bg-cover bg-[50%] bg-no-repeat absolute bottom-0 left-0"
                  >
                        <Mask mask="linear-gradient(45deg, transparent 50%, rgba(0, 0,0, 1) 150%)">
                              <LazyLoad height="100%" offset={100}>

                                    <LazyLoadImage
                                          alt={movieObj.info.name}
                                          height="100%"
                                          width="100%"
                                          src={movieObj.info.backdrop_path > 0 ? movieObj.info.backdrop_path[0] : movieObj.info.movie_image}

                                    />
                              </LazyLoad>

                        </Mask>
                        <div className="w-full h-full left-0 top-0 bg-primary absolute opacity-40"></div>
               <div className="-translate-y-1/2 top-0 absolute  " style={{ width: "55vw", height: "55vh", opacity: 0.10, background: '#ABABCD', borderRadius: "100%", filter: 'blur(5rem)' }} />
                  <div className="-translate-x-1/2 translate-y-1/2 bottom-0 absolute  " style={{ width: "55vw", height: "55vh", opacity: 0.10, background: '#ABABCD', borderRadius: "100%", filter: 'blur(5rem)', }} />

            </div>
            <div
                  className=" absolute bottom-0 left-0 w-full h-full justify-center justify-items-center"
            >
                  <div className="flex container mx-auto  h-full pt-24" role="list">
                        <div className="  w-1/3 h-5/6 mx-3 transition-all duration-300">
                                          <LazyLoadImage
                                                alt={movieObj.info.movie_image}
                                                height="100%"
                                                width="100%"
                                                placeholderSrc="/images/logoui.svg"
                                                src={movieObj.info.movie_image}
                                                onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null; // prevents looping
                                                      currentTarget.src = "/images/logoui.svg";
                                                }}
                                                wrapperProps={{
                                                      // If you need to, you can tweak the effect transition using the wrapper style.
                                                      style: { display: "flex", maxHeight: '100%' },
                                                }}

                                          />
                        </div>
                        <div className="w-2/3 ml-8">
                                          <div className="flex items-center flex-wrap	">
                                    <h1 className="text-white text-5xl text-left">{movieObj.movie_data.name}</h1>
                                    {movieObj.info.genre.split(",").map((genre, index) => (
                                          <span
                                                key={index}
                                                className="bg-gray-700 text-white text-xl  mt-2 font-medium ml-4 px-2.5 py-0.5 rounded-full"
                                          >
                                                {genre}
                                          </span>
                                    ))}

        </div>
                                          <p className="w-2/3 text-white mt-4 opacity-80 text-left text-xl">{movieObj.info.plot}</p>
                              <div className="flex flex-row mt-8">

                                                <button className=" text-white font-light inline-flex  text-xl min-w-[7rem]">
                                                      <DateImg />
                                                      <span className="ml-4 text-xl text-left">{movieObj.info.releaseDate} </span>
                                                </button>

                                    <button className=" text-white font-light inline-flex  ml-4 text-2xl ">
                                          <Users />
                                                      <span className="ml-4 text-xl text-left">{movieObj.info.cast} </span>
                                    </button>
                              </div>
                              <div className="flex flex-row mt-8">
                                                {(currentpos > 0) &&
                                                      <ButtonItemLContenu event="continue" classes=" ml-4 -normal py-6 px-10  mt-4 bg-secondary ">
                                                            <ButtonItemLContenu.Slot name="leftActions">
                                                                  <WatchingIcon fillColor="#fff" />
                                                            </ButtonItemLContenu.Slot>
                                                            <ButtonItemLContenu.Slot name="leftActionsFocused">
                                                                  <WatchingIcon fillColor="#000" />
                                                            </ButtonItemLContenu.Slot>

                                                            <ButtonItemLContenu.Slot name="rightActions">
                                                                  <span className="ml-2 text-lg">Continue From {formatTime(currentpos)}</span>
                                                            </ButtonItemLContenu.Slot>
                                                      </ButtonItemLContenu>
}
                                                <ButtonItem event="play" classes=" ml-4 -normal py-6 px-10  mt-4 bg-secondary ">
                                                <ButtonItem.Slot name="leftActions">
                                                      <Play class="fill-textcolor2" />
                                                </ButtonItem.Slot>
                                                <ButtonItem.Slot name="leftActionsFocused">
                                                      <Play class="fill-white" />
                                                </ButtonItem.Slot>

                                                <ButtonItem.Slot name="rightActions">
                                                            <span className="ml-2 text-lg">Play</span>
                                                </ButtonItem.Slot>
                                          </ButtonItem>
                                                {movieTrailer&&<ButtonItem event="youtube" classes=" ml-4 -normal py-6 px-10  mt-4 bg-secondary ">
                                                <ButtonItem.Slot name="leftActions">
                                                      <Youtube class="fill-textcolor2" />
                                                </ButtonItem.Slot>
                                                <ButtonItem.Slot name="leftActionsFocused">
                                                      <Youtube class="fill-white" />
                                                </ButtonItem.Slot>

                                                <ButtonItem.Slot name="rightActions">
                                                            <span className="ml-2 text-lg">Trailer</span>
                                                </ButtonItem.Slot>
                                          </ButtonItem>
}
                                                <ButtonItemLike event="like" isliked={movieLiked} classes=" ml-4 -normal py-6 px-10  mt-4 bg-buttonLike ">
                                                      <ButtonItemLike.Slot name="leftActions">
                                                      <Like class="fill-rose-600" />
                                                      </ButtonItemLike.Slot>
                                                      <ButtonItemLike.Slot name="leftActionsFocused">
                                                      <Like class="fill-rose-300" />
                                                      </ButtonItemLike.Slot>
                                                      <ButtonItemLike.Slot name="leftActionsliked">
                                                            <LikeEmpty class="fill-rose-600" />
                                                      </ButtonItemLike.Slot>
                                                      <ButtonItemLike.Slot name="leftActionsFocusedliked">
                                                            <LikeEmpty class="fill-rose-300" />
                                                      </ButtonItemLike.Slot>


                                                </ButtonItemLike>
                              </div>
                        </div>
                  </div>
                  </div></>)
}

        </div>
      </FocusContext.Provider>

}

export default Movie;