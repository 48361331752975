import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Progress from "./sub/Progress";
import { useGlobalContext } from "../model/GlobalContext";
import ItemCtagory from "./sub/ItemCtagory";
import { FocusContext, FocusDetails, getCurrentFocusKey, navigateByDirection, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import 'react-virtualized/styles.css'; // import the styles
import { Collection } from 'react-virtualized';
import useWindowDimensions from "../hooks/useWindowDimensions";
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import ItemMovie from "./sub/ItemMovie";
import React from "react";
import { useCustomEvent } from "../Event/CustomEventContext";
import NoriginSpatialNavigation from '@noriginmedia/norigin-spatial-navigation';
import Nav from "./sub/Nav";
import useEventListener from "@use-it/event-listener";
import { deleterow, insertData } from "../model/constant";
const ESCAPE_KEYS = ['27', '10009'];
const LIKE_KEY = ['100', '403']
const Movies: React.FC = () => {
      const { id } = useParams();
      const navigate = useNavigate();

      const { height, width } = useWindowDimensions();
      const collectionRef = React.createRef<Collection>();
      const { addEventListener, removeEventListener } = useCustomEvent();
      const { listVod, listVodFav, listVodRecent, changeListVodFav, profile, profileShow } = useGlobalContext();
      const [listPos, setListPos] = useState<number>(0);
      const _windowScroller = useRef(null);
      const [listFav, setListFav] = useState<any[]>([]);

      const [listMovies, setListMovies] = useState<any[]>([]);
      const [scrollIndex, setScrollIndex] = useState(0);

      const [isLoadinfShow, setLoadinfShow] = useState(true);
      const { ref, focusSelf, focusKey } = useFocusable({ focusKey: "movies", trackChildren: true });
      const scrollingRef = useRef<HTMLDivElement>(null);
      const cellRenderer = ({ index, key, style, isScrolling }) => (
            <div key={key} style={style} className="p-2">
                  <ItemMovie index={index} movie={listMovies[index]} focusKey={'Movie' + index} listFav={listFav} isScrolling={isScrolling} />
            </div>
      );
      useEffect(() => {

            setListFav([...listVodFav])
      }, [listVodFav]);
      useEffect(() => {

      }, [listVodRecent]);

      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  setFocus('Movie' + payload)

                  //   scrollToIndex(payload)
                  setListPos(payload)
                  if (_windowScroller.current) {
                        let variableName = _windowScroller.current as WindowScroller;

                        variableName.updatePosition();
                  }
            };

            addEventListener('focusEventMovie', handleCustomEvent);

            return () => {
                  removeEventListener('focusEventMovie', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);

      function handler({ keyCode }) {

            if (getCurrentFocusKey() == null) {
                  focusSelf()
                  return
            } else {
                  console.log(getCurrentFocusKey())
            }
            if (ESCAPE_KEYS.includes(String(keyCode))) {

                  setTimeout(() => {
                        if (!profileShow)
                              navigate(-1)

                  })

            } else {
                  if (LIKE_KEY.includes(String(keyCode))) {
                        let focused = getCurrentFocusKey();
                        if (focused.includes('Movie')) {

                              let id = parseInt(focused.replace("Movie", ""))
                              console.log(id)
                              const newArray = [...listMovies];
                              newArray[id].isLiked = !newArray[id].isLiked;
                              var streamId = String(newArray[id].stream_id);
                              const arrayFav = [...listVodFav]
                              if (listVodFav.includes(streamId)) {
                                    const arrayFav2 = arrayFav.filter(el => el !== streamId)
                                    deleterow(streamId, "movie", profile);
                                    changeListVodFav(arrayFav2)
                              } else {
                                    arrayFav.push(streamId)
                                    insertData(streamId, "movie", profile)
                                    changeListVodFav(arrayFav)

                              }
                              // Set the state with the new array

                              setListMovies(newArray)

                        }
                  }
            }
      }

      useEventListener('keydown', handler);
      useEffect(() => {

            const wheelListener = (event) => {
                  const delta = Math.sign(event.deltaY);
                  if (delta > 0) {
                        const focusDetails: FocusDetails = {
                              direction: 'down',
                              from: getCurrentFocusKey(),
                              // Other necessary details based on the library's documentation
                        };

                        navigateByDirection('down', focusDetails)
                  } else if (delta < 0) {
                        const focusDetails: FocusDetails = {
                              direction: 'up',
                              from: getCurrentFocusKey(),
                              // Other necessary details based on the library's documentation
                        };
                        navigateByDirection('up', focusDetails)
                  }
            };

            // Attach the wheel event listener
            document.addEventListener('wheel', wheelListener);

            // Remove the wheel event listener when the component is unmounted
            return () => {
                  document.removeEventListener('wheel', wheelListener);
            };
      }, []);
      useEffect(() => {
            if (id === "0") {
                  setListMovies([...listVod])
            }
            else if (id === "-1") {
                  let arr = [...listVod.filter(item => {
                        return listVodFav.includes(String(item.stream_id))
                  })]


                  setListMovies(arr)

            } else if (id === "-2") {
                  let arr = [...listVod.filter(item => {
                        return listVodRecent.includes(String(item.stream_id))
                  })]


                  setListMovies(arr)

            } else {
                  let arr = [...listVod.filter(item => {
                        return item.category_id === id
                  })
                  ]
                  setListMovies(arr)

            }
            setTimeout(() => {
                  setFocus('Movie0')

            }, 100)

            setLoadinfShow(false)



      }, [listVod]);


      const cellSizeAndPositionGetter = ({ index }) => {
            const columnCount = 6;
            const rowCount = Math.ceil(listMovies.length / columnCount);
            const cellWidth = ((width * 0.9)) / 6;
            const cellHeight = cellWidth * 1.5;
            const margin = 8;
            const cellFirst = (width * 0.1) / 2

            const column = index % columnCount;
            const row = Math.floor(index / columnCount);

            return {
                  height: cellHeight,
                  width: cellWidth,
                  x: cellFirst + column * (cellWidth),
                  y: row * (cellHeight),
            };

      };





      var arrow_keys_handler = function (e) {
            switch (e.code) {
                  case "ArrowUp": case "ArrowDown": case "ArrowLeft": case "ArrowRight":
                  case "Space": e.preventDefault(); break;
                  default: break; // do not block other keys
            }
      };
      window.addEventListener("keydown", arrow_keys_handler, false);

      return <FocusContext.Provider value={focusKey}><div ref={ref} className="outline-none bg-primary" >
            {isLoadinfShow && <Progress />}
            {!isLoadinfShow &&
                  <div>
                        <Nav />                       <div className={" mt-32 "}>

                              <WindowScroller ref={_windowScroller} >
                                    {({ height, scrollTop, onChildScroll }) => (
                                          <div >

                                                <AutoSizer disableHeight>
                                                      {({ width }) => (

                                                            <Collection
                                                                  autoHeight
                                                                  tabIndex={-1}
                                                                  ref={collectionRef}
                                                                  height={height}
                                                                  width={width}
                                                                  scrollTop={scrollTop}
                                                                  cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                                                                  onScroll={onChildScroll}
                                                                  scrollToCell={listPos}

                                                                  cellRenderer={cellRenderer}
                                                                  cellCount={listMovies.length}
                                                                  overscanRowCount={20}
                                                            />


                                                      )}
                                                </AutoSizer>
                                                <div style={{ height: 100 }} /> {/* Spacer div to account for future content height */}
                                          </div>
                                    )}
                              </WindowScroller>
                        </div>
                  </div>


            }
      </div >
      </FocusContext.Provider>

}

export default Movies;