import React, { useEffect, useState } from 'react';
import { Collection, Grid } from 'react-virtualized';
import { useGlobalContext } from '../model/GlobalContext';
import ItemSerie from './sub/ItemSerie';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCustomEvent } from '../Event/CustomEventContext';
import ItemMovie from './sub/ItemMovie';
import { motion } from 'framer-motion';

const SearchMovie = ({ parentWidth, parentHeiht, keystr }) => {
      const { listVod, listVodFav, changeListSerieFav, profile } = useGlobalContext();
      const [listFav, setListFav] = useState<any[]>([]);
      const [cellposition, setCellposition] = useState<number>(0);
      const { ref, hasFocusedChild, focusKey } = useFocusable({

            trackChildren: true,

            focusKey: 'searchmovie'
      });

      const [listVods, setlistVods] = useState<any[]>([]);
      const [listUnfilterd, setListUnfilterd] = useState<any[]>([]);

      const { addEventListener, removeEventListener } = useCustomEvent();

      const rowCount = 100;
      const columnCount = 5;
      const margin = 10;
      useEffect(() => {

            setListFav([...listVodFav])
      }, [listVodFav]);
      useEffect(() => {

            console.log(listUnfilterd.length)
      }, [listUnfilterd]);


      useEffect(() => {



            setListUnfilterd([...listVod])
            if (!keystr || (keystr == "" || keystr.length < 3)) {
                  //   setListChannelfilterd([...listChannel]);
                  setlistVods([])

            } else {
                  const filteredData = [...listVod].filter(item =>
                        item.name.toLowerCase().includes(keystr.toLowerCase())
                  );


                  setlistVods(filteredData)
            }

      }, [listVod]);
      useEffect(() => {

            if (!keystr || (keystr == "" || keystr.length < 3)) {
                  //   setListChannelfilterd([...listChannel]);
                  setlistVods([])

            } else {
                  const filteredData = listUnfilterd.filter(item =>
                        item.name.toLowerCase().includes(keystr.toLowerCase())
                  );


                  setlistVods(filteredData)
            }
      }, [keystr]);
      // Calculate the width of each column dynamically based on the available width
      const columnWidth = (parentWidth - margin - (columnCount) * margin) / columnCount;

      const totalWidth = parentWidth;
      const totalHeight = columnWidth*1.5;
      const cellSizeAndPositionGetter = ({ index }) => {
            const rowIndex = Math.floor(index / columnCount);

            const left = index * (columnWidth + margin);
            const top = rowIndex * (columnWidth * 1.5 + margin);

            return {
                  width: columnWidth,
                  height: columnWidth * 1.5,
                  x: left,
                  y: 0,
            };
      };

      const cellRenderer = ({ index, key, style }) => {
            return (
                  <div
                        key={key}
                        style={{
                              ...style,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                        }}
                  >
                        <ItemMovie index={index} movie={listVods[index]} focusKey={'Movie' + index} listFav={listFav} />
                  </div>
            );
      };
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  console.log(payload)

                  setCellposition(payload+1)
            };

            addEventListener('focusEventMovie', handleCustomEvent);

            return () => {
                  removeEventListener('focusEventMovie', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);

      useEffect(() => {
            if (hasFocusedChild) {
                  ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                  });

            }
      }, [hasFocusedChild]);
      return (
            <FocusContext.Provider value={focusKey}>
                  <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={listVods.length > 0 ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                  >
            <div style={{ width: '100%', overflow: 'hidden' }} ref={ref}>
                  <h1 className='text-white text-left text-3xl py-4 font-bold'>VOD's</h1>
                  <div>

                  <Collection
                        width={totalWidth}
                              height={totalHeight + 30}
                        cellCount={listVods.length}
                        scrollToCell={cellposition}
                        cellRenderer={cellRenderer}
                        cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                  />

</div>
            </div>
            </motion.div>
            </FocusContext.Provider>
      );
};

export default SearchMovie;
