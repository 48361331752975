function Info({ classes}:{ classes?: string}) {
    return  <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            className={classes}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
      >
            <path
                  d="M12.9336 2C7.42359 2 2.93359 6.49 2.93359 12C2.93359 17.51 7.42359 22 12.9336 22C18.4436 22 22.9336 17.51 22.9336 12C22.9336 6.49 18.4436 2 12.9336 2ZM12.1836 8C12.1836 7.59 12.5236 7.25 12.9336 7.25C13.3436 7.25 13.6836 7.59 13.6836 8V13C13.6836 13.41 13.3436 13.75 12.9336 13.75C12.5236 13.75 12.1836 13.41 12.1836 13V8ZM13.8536 16.38C13.8036 16.51 13.7336 16.61 13.6436 16.71C13.5436 16.8 13.4336 16.87 13.3136 16.92C13.1936 16.97 13.0636 17 12.9336 17C12.8036 17 12.6736 16.97 12.5536 16.92C12.4336 16.87 12.3236 16.8 12.2236 16.71C12.1336 16.61 12.0636 16.51 12.0136 16.38C11.9636 16.26 11.9336 16.13 11.9336 16C11.9336 15.87 11.9636 15.74 12.0136 15.62C12.0636 15.5 12.1336 15.39 12.2236 15.29C12.3236 15.2 12.4336 15.13 12.5536 15.08C12.7936 14.98 13.0736 14.98 13.3136 15.08C13.4336 15.13 13.5436 15.2 13.6436 15.29C13.7336 15.39 13.8036 15.5 13.8536 15.62C13.9036 15.74 13.9336 15.87 13.9336 16C13.9336 16.13 13.9036 16.26 13.8536 16.38Z"
            />
      </svg>
}

export default Info;


/*
function Refrech() {
}

export default Refrech;
*/