import { Focusable } from "react-js-spatial-navigation"
import Favorite from "../svg/Favorite"
import Recent from "../svg/Recent"
import Setting from "../svg/Setting"
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";

function FavMenu({ type,section ,setSection}) {
      const navigate = useNavigate();

      const { ref, focused } = useFocusable({
            onEnterPress: () => {
                  setSection(type)
            },
      });
      return <div
            ref={ref}
            className="  outline-none"
      > <div className=" flex items-center outline-none" >
            <div
                        className={(focused ? "bg-secondaryFocused" : ((section == type) ? "bg-thirdCOlor/50" : "bg-secondary"))+" w-20 h-20   rounded-full my-4 ml-8 flex justify-center items-center min-w-5"}
            >
                        <div className={(focused ? "opacity-100" : "opacity-50")}>
                        {(() => {
                              if (type === "s1") {
                                    return (
                                          <img src={'/images/live.svg'} className="w-8" />
)
                              } else if (type === "s2") {
                                    return (
                                          <img src={'/images/movie.svg'} className="w-8" />
)
                              } else {
                                    return (
                                          <img src={'/images/serie.svg'} className="w-8" />
)
                              }
                        })()}

                  </div>
            </div>

      </div>
      </div>
}

export default FavMenu;