function Favorite({ classes}:{ classes?: string}) {
    return  ( <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2rem"
            height="2rem"
            viewBox="0 0 28 24"
            fill="none"
      >
            <path
                  d="M19.9203 0.133301C17.507 0.133301 15.347 1.30663 14.0003 3.10663C12.6537 1.30663 10.4937 0.133301 8.08033 0.133301C3.98699 0.133301 0.666992 3.46663 0.666992 7.58663C0.666992 9.1733 0.920325 10.64 1.36033 12C3.46699 18.6666 9.96033 22.6533 13.1737 23.7466C13.627 23.9066 14.3737 23.9066 14.827 23.7466C18.0403 22.6533 24.5337 18.6666 26.6403 12C27.0803 10.64 27.3337 9.1733 27.3337 7.58663C27.3337 3.46663 24.0137 0.133301 19.9203 0.133301Z"
                  fill="url(#paint0_linear_502_1331)"
            />
            <defs>
                  <linearGradient
                        id="paint0_linear_502_1331"
                        x1="12.8003"
                        y1="7.6093"
                        x2="37.8713"
                        y2="34.9817"
                        gradientUnits="userSpaceOnUse"
                  >
                      <stop stopColor="white" />
                      <stop offset="0.200834" stopColor="white" stopOpacity="0.7" />
                      <stop offset="0.392183" stopColor="white" stopOpacity="0.5" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
            </defs>
      </svg>);
}

export default Favorite;


/*
function Refrech() {
}

export default Refrech;
*/