import React from 'react';

function WifiIcon(props) {
      return (
            <svg xmlns="http://www.w3.org/2000/svg" width="6rem" height="6rem" viewBox="0 0 48 48" {...props}>
                  <title>wifi-disable-solid</title>
                  <g id="Layer_2" data-name="Layer 2">
                        <g id="invisible_box" data-name="invisible box">
                              <rect width="48" height="48" fill="none" />
                        </g>
                        <g id="Q3_icons" data-name="Q3 icons">
                              <g>
                                    <path d="M40.7,26.5a2,2,0,0,0-.2-2.6A23,23,0,0,0,24.3,17L29,21.7a18.6,18.6,0,0,1,8.7,5A1.9,1.9,0,0,0,40.7,26.5Z" fill='#fff' />
                                    <path d="M45.4,17.4A31.2,31.2,0,0,0,17.1,9.8l3.4,3.4L24,13a27.4,27.4,0,0,1,18.6,7.3,2,2,0,0,0,3-.2h0A2.1,2.1,0,0,0,45.4,17.4Z" fill='#fff' />
                                    <circle cx="24" cy="38" r="5" fill='#fff' />
                                    <path d="M5.4,3.6a1.9,1.9,0,0,0-2.8,0,1.9,1.9,0,0,0,0,2.8L9,12.8H8.7L6.8,14.1l-.3.3L4.7,15.7l-.3.2L2.6,17.4a2.1,2.1,0,0,0-.2,2.7h0a2,2,0,0,0,3,.2l1.7-1.4.4-.3,1.8-1.3.3-.2,2-1.1h0l.4-.2,3,3-.5.2-.6.3-.8.4-.6.3-.8.5-.5.4-.8.5-.5.4-.9.7-.4.3a11.4,11.4,0,0,1-1.1,1.1,2,2,0,0,0-.6,1.4,2.8,2.8,0,0,0,.4,1.2,1.9,1.9,0,0,0,3,.2l1.2-1,.3-.3.9-.7.4-.3,1.1-.7h.2l1.4-.8h.4l1.1-.5.5-.2h.3l3.3,3.3a16,16,0,0,0-9.1,5.3,1.9,1.9,0,0,0-.4,1.2,2,2,0,0,0,.4,1.3,2,2,0,0,0,3.1,0A11.5,11.5,0,0,1,24,29h1.2L38.6,42.4a1.9,1.9,0,0,0,2.8,0,1.9,1.9,0,0,0,0-2.8Z" fill='#fff' />
                              </g>
                        </g>
                  </g>
            </svg>
      );
}

export default WifiIcon;
