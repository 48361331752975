import { Focusable } from "react-js-spatial-navigation";
import { useCustomEvent } from "../../Event/CustomEventContext";
import { useEffect, useState } from "react";
import { getetprofile } from "../../model/constant";
import { Playlist } from "../../model/model";
import { useGlobalContext } from "../../model/GlobalContext";
import { useNavigate } from 'react-router-dom';
import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import spatialNavigation from '@noriginmedia/norigin-spatial-navigation';

interface ItemProfileProps {
      position: any;
      playList: Playlist;
      focusKey: string; // Make sure this is defined in your component
}

const ItemProfile: React.FC<ItemProfileProps> = ({ position, playList, focusKey: focusKeyParam }) => {
      const navigate = useNavigate();
      const { ref, focused, focusKey, focusSelf } = useFocusable({
            focusKey: focusKeyParam,

            onEnterPress: () => {
                  onClick();
            },
      });

      const { profile, changeProfile } = useGlobalContext();
      useEffect(() => {
            if (focused) {
                  emitEvent('customEvent', position);

            }
      }, [focused]);

      const [isLoadinfShow, setLoadinfShow] = useState(false);

      const { emitEvent } = useCustomEvent();
      const onClick = () => {
            setLoadinfShow(true);
            var prof = getetprofile(playList);
            var settings = {
                  url:
                        prof.host +
                        "/player_api.php?username=" +
                        prof.username +
                        "&password=" +
                        prof.password,
                  method: "GET",

                  timeout: 0,
                  headers: {
                        "Content-Type": "application/json",
                  },
            };
            let requestOptions: RequestInit = {
                  method: "GET",
                  redirect: "follow",
            };

            fetch(
                  prof.host +
                  "/player_api.php?username=" +
                  prof.username +
                  "&password=" +
                  prof.password,
                  requestOptions
            )
                  .then((response) => response.json())
                  .then((responseaccount) => {

                        setLoadinfShow(false);

                        if (responseaccount.user_info.auth == 0 || responseaccount.user_info.status=="Expired") {
                              emitEvent('customEventToast', 0);
                        } else {
                              playList.allowed_output_formats = responseaccount.user_info.allowed_output_formats;
                              console.log(playList)
                              changeProfile(playList);
                              navigate("main/");
                        }
                  })
                  .catch((error) => {
                        setLoadinfShow(false);
                        emitEvent('customEventToast', 0);

                        //dispatch("Toast");
                  });
      };
      const onFocus = (event: any) => {
            event.target.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "nearest",
            });

            emitEvent('customEvent', position);
      };
      return <FocusContext.Provider value={focusKey}>
            <div className="p-2 w-40 h-40 relative profile " ref={ref}  onClick={onClick}
            onMouseEnter={focusSelf} >
            <div
                  className="group outline-none" >
                  <div
                        className="  focusable cursor-pointer"
                        id={position}
                  >
                        <div
                                    className={(focused ? "bg-secondaryFocused" : "bg-secondary") + " left-0 right-0 top-0 bottom-0 absolute  rounded-full  flex justify-center items-center"}
                        >
                              <img
                                    src="/images/icon3.png"
                                    className="w-12 h-12 absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                              />



                        </div>
                  </div>
                        <div
                              className={(focused ? "block " : "hidden") + " absolute left-0 top-0 right-0 bottom-0  "}
                              style={{ "backgroundImage": "url(/images/bordergradientcircle.svg)" }}
                        />
                  </div>
            </div>
      </FocusContext.Provider>
}

export default ItemProfile;