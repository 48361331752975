
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import ItemSettingSheet from "../sub/ItemSettingSheet";
import ItemSettingSheetSize from "../sub/ItemSettingSheetSize";

function Size({isOpen, onCloseFn,size}) {
      const { ref, focusSelf, focusKey, focused } = useFocusable({ focusKey: "size", isFocusBoundary: true, trackChildren: true, focusBoundaryDirections: ['left', 'right'] }); ;
      const [languageList, setLanguageList] = useState<any[]>([{ id: "0", class: "contain", size: "16:9", title: "Default" }, { id: "1", class: "cover", size: "16:9", title: "Full" }, { id: "2", class: "fill", size: "16:9", title: "Fit" }, { id: "3", class: "contain", size: "4:3", title: "4:3" }, { id: "4", class: "contain", size: "16:9", title: "16:9" } ]);


      useEffect(() => {
            console.log("open", isOpen)
            if (isOpen)
            {
                  setFocus("S" + size.id)
            focusSelf();}
            else{
               //   pause()
            }

      }, [isOpen]);
      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col 	" ref={ref}>

            <h1 className="text-white text-4xl font-bold my-0 mt-10 text-s mx-auto  text-left w-full capitalize ">Change video size</h1>

            <div className="  flex  mt-28  w-full">
                        {languageList.map((obj, index) => (
                              <ItemSettingSheetSize key={index} index={index} obj={obj} onClose={onCloseFn} focusKey={"S" + obj.id} />
                        ))}
                  </div>
                  </div>

              </FocusContext.Provider>
}

export default Size;