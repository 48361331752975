import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useRef } from "react";
import { Focusable } from "react-js-spatial-navigation";

import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../model/GlobalContext";
import Progress from "./Progress";

function MainItem({ card ,size}) {
      const { changeSettings } = useGlobalContext();

      const { ref, focused, focusSelf } = useFocusable({
            focusKey:card,
            onEnterPress: () => {
                  onEnterPress()
            },
      });
    const   onEnterPress= () => {
            localStorage.setItem('main', card)

            changeSettings({ position: 0 })

            goToRoute()
      };
      const navigate = useNavigate();
      const myButtonRef = useRef<HTMLDivElement>(null!);

      const goToRoute = () => {
            if(card=='live'){
                  let listType = localStorage.getItem('listType')||'list';
                  if (listType=="grid"){
                        navigate('/cat/' + card);

                  }else{
                        navigate("/liveList")
                  }

            }else{
                  navigate('/cat/' + card);

            }
      };
      if(card=="movie"){
            if (myButtonRef.current) {
                  myButtonRef.current.focus();
            }
      }

      let img = 'url(/images/'+ card+'back.png)'
      return <div ref={myButtonRef} id={card} onClick={onEnterPress}
            onMouseEnter={focusSelf}  className={card+ "  w-1/4 h-4/6 mx-3"}
      ><div className="w-full h-full   outline-none"
            ref={ref}><div
            className="text-start	   outline-none w-full h-full transition-all duration-300 cursor-pointer"
            id={card}>
                        <div className={(focused ?"bg-white":"")+" w-1/3 mx-auto h-10 " }/>
            <div
                              className={(focused ? " bg-gradient-custom " : "") +"  w-full h-full relative backdrop-blur-2xl bg-secondary  -mt-9  "}
            >
                  <div style={{ "backgroundImage":img,"WebkitMaskImage":"linear-gradient(199.42deg, #292945 -15.04%, rgba(41, 41, 69, 0) 78.01%)","maskImage":"linear-gradient(199.42deg, #292945 -15.04%, rgba(41, 41, 69, 0) 70.01%)"}}

                        className="w-full h-full bg-center bg-cover absolute bottom-0 left-0"
                  />

                  <div
                                    className={(focused ? "block" : "hidden") +  " w-full   h-full bg-center bg-cover absolute bottom-0 left-0"}
                  >
                        <img src="/images/Vector.png" />
                  </div>
                  <div
                        className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-end p-10"
                  >
                        <img src={'/images/'+card+'.svg'} className="w-16" />
                        {card == "live" &&
                        <h2 className="text-white text-4xl my-3">
                              <div
                                    className="bg-rose-500 px-2 float-left flex justify-center py-1 mr-2"
                              >
                                    <img className="float-left mr-1 w-1" src="/images/circle.svg" /><span className=""
                                    >Live</span>
                              </div>
                              TV
                        </h2>
}
                        {card == "movie"&&
                              <h2 className="text-white text-4xl my-3">Video’s</h2>
}
                        {card == "serie" &&

                              <h2 className="text-white text-4xl my-3">Series</h2>
 }
                                    {size!=0&&
                                          < span className="text-textcolor2 text-xl">+{size}</span>
                                          }
                                    {size == 0 &&
                                          <div
                                                className="relative  transform flex text-white text-xl items-center"
                                          >
                                                <div
                                                      className="border-t-transparent mr-4 border-solid animate-spin duration-50 rounded-full border-white border-2 h-4 w-4"
                                                />
                                                Loading ...
                                          </div>
                                    }
            </div>
                              <div
                                    className={(focused ? "block " : "hidden") + " absolute left-0 top-0 right-0 w-full  bottom-0 "}
                                    style={{ "backgroundImage": "url('/images/bordergradient.svg')" }}
                              />
      </div>
      </div >
            </div></div>
}

export default MainItem;

