const Mask = ({ mask, children }) => (
      <div style={{
            WebkitMaskImage: mask,
            maskImage: mask,
            maxHeight:'100%',
            overflow:'hidden'
      }}>
            {children}
      </div>
)
export default Mask;