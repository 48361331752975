import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';
import PlayIcon from '../svg/PlayIcon';
import PauseIcon from '../svg/PauseIcon';

const CircleButtonPlay= ({ event ,playing }) => {
  const { emitEvent } = useCustomEvent();

  const { ref, focused, focusKey, focusSelf } = useFocusable({
    focusKey:"focusPlay",
    onEnterPress: () => {
      onEnterPress()

    },
  });
  const onEnterPress = () => {

      emitEvent(event, "click");


  }

  return (<FocusContext.Provider value={focusKey}><div ref={ref} onClick={onEnterPress}
    onMouseEnter={focusSelf}  className={(focused ? "border-0 bg-gradientplay shadow-lg" : " border-transparentWhite50 ") +" border-0  w-20 h-20 rounded-full mr-4 focusable outline-none flex justify-center items-center"}
                                                tabIndex={-1}
                                          >



    {(!focused&&playing) && <div className="flex">
      <PlayIcon classes="fill-textcolor2" />
</div>}
    {(focused && playing) && <div className="flex">      <PlayIcon classes="fill-primary" />
</div>}



    {(!focused && !playing) && <div className="flex">
      <PauseIcon classes="fill-textcolor2" />
    </div>}
    {(focused && !playing) && <div className="flex">      <PauseIcon classes="fill-primary" />
    </div>}
                                          </div>
  </FocusContext.Provider>
  );
};


export default CircleButtonPlay;

