
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { createRef, useEffect, useRef, useState } from "react";
import ButtonItem from "../sub/ButtonItem";

import { useCustomEvent } from "../../Event/CustomEventContext";
import toast, { Toaster } from 'react-hot-toast';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import { Collection } from 'react-virtualized';
import useRemtoPx from "../../hooks/remToPx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useGlobalContext } from "../../model/GlobalContext";
import ItemCtagoryPortal from "../sub/ItemCtagoryPortal";
import Info from "../svg/Info";
import RemoteIcon from "../svg/RemoteIcon";
import ReturnIcon from "../svg/ReturnIcon";
import ParentalInfo from "./ParentalInfo";

function ParentalList({ isOpen, onCloseFn }) {
      const [inputPassword, setInputPassword] = useState('');
      const inputRef = useRef<HTMLInputElement>(null);
      const inputRef2 = useRef<HTMLInputElement>(null);
      const collectionRef = createRef<Collection>();
      const itemHeight = useRemtoPx(6)
      const itemMargin = useRemtoPx(0.5)
      const itemMarginDiv = useRemtoPx(1.25)
      const [listcat, setListcat] = useState<any[]>([]);
      const [listPos, setListPos] = useState<number>(0);
      const { height, width } = useWindowDimensions();
      const { catLive } = useGlobalContext();
      const scrollingRef = useRef<HTMLDivElement>(null);
      const [items, setItems] = useState<string[]>([]);

      const { addEventListener, removeEventListener } = useCustomEvent();
      let varible=''
      const { ref, focusSelf, focusKey, focused } = useFocusable({ focusKey: "Language" });

      const [languageList, setLanguageList] = useState<any[]>([{ id: "en", title: "English" }, { id: "ar", title: "Arabic" }]);
      const [isSeted, setSeted] = useState<boolean>(false);
      const [isInfo, setInfo] = useState<boolean>(false);

      const cellRenderer = ({ index, key, style }) => (
            <div key={key} style={style}>
                  <ItemCtagoryPortal category={listcat[index]} key={'item' + index}
                        focusKey={'CAT' + index} index={index}
                        closed={(items.includes(String(listcat[index].category_id)))}
                        changeClose={changeClose}
                  />
            </div>
      );
      const changeClose =(index)=>{
            if (!items.includes(String(listcat[index].category_id))) {
                  const updatedItems = [...items, String(listcat[index].category_id)];

                  setItems(updatedItems);
                  localStorage.setItem('parentallist', JSON.stringify(updatedItems));

            } else {
                  const indexC = items.indexOf(String(listcat[index].category_id));
                  if (indexC !== -1) {
                        const updatedItems = [...items];
                        updatedItems.splice(indexC, 1);
                        setItems(updatedItems);
                        localStorage.setItem('parentallist', JSON.stringify(updatedItems));

                  }
            }
      }


      useEffect(() => {
                  setListcat(catLive)
                  setFocus('CAT0');


      }, [catLive]);
      useEffect(() => {
            const storedItems = JSON.parse(localStorage.getItem('parentallist')||'[]') || [];
            setItems(storedItems);
      }, []);

      useEffect(() => {
            console.log("open", isOpen)
            if (isOpen) {
                  setFocus('Language');
                  let password = localStorage.getItem('password');
                  if (!password) {
                        setSeted(false);
                  }else{
                        setSeted(true);

                  }

            }
            else {
            }

      }, [isOpen]);
      const changeScroll = () => {
            focusSelf();

      }
      const scrollToIndex = (index) => {
           setListPos(index)


      };
      const cellSizeAndPositionGetter = ({ index }) => {
            const columnCount = 4;
            const rowCount = Math.ceil(listcat.length / columnCount);
            const cellWidth = ((width - (itemMarginDiv*4))) / 4 - itemMargin;
            const cellHeight = itemHeight;
            const margin = itemMargin;

            const column = index % columnCount;
            const row = Math.floor(index / columnCount);

            return {
                  height: cellHeight + (margin * 2),
                  width: cellWidth + (margin * 2),
                  x: column * (cellWidth + margin),
                  y: row * (cellHeight + margin),
            };
      };
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  focusSelf();
                  console.log(payload)

                  scrollToIndex(payload)

            };

            addEventListener('focusEvent', handleCustomEvent);

            return () => {
                  removeEventListener('focusEvent', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);

      return <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col  w-full h-full">
                  {isInfo && <ParentalInfo onCloseFn={() => { focusSelf(); setInfo(info => !info) }} />
}
                  {!isInfo &&  <div className=" w-full h-full px-5 mx-auto overflow-auto  flex flex-col  flex-grow	" >

             <div className="flex flex-row pt-4">    <h1 className="text-white text-4xl font-bold my-0 mt-10 text-s mx-auto  text-left w-full capitalize flex-grow">Controle Parental</h1>
                        <ButtonItem event="info" classes="m-4 bg-transparentWhite30 px-4 py-4" onevent={()=>{setInfo(info=> !info)}} >
                              <ButtonItem.Slot name="leftActions">
                                    <Info classes="fill-textcolor2" />
                              </ButtonItem.Slot>
                              <ButtonItem.Slot name="leftActionsFocused">
                                          <Info classes="fill-white" />
                              </ButtonItem.Slot>

                              <ButtonItem.Slot name="rightActions">
                                    <span className="ml-2">Information</span>
                              </ButtonItem.Slot>
                        </ButtonItem>
                  </div>
                  <div className="flex items-center  mt-16 flex-grow " ref={scrollingRef}>

                        <div className="   flex-grow h-full  w-full list">

                              <div onScroll={changeScroll} ref={scrollingRef}  className=" h-full  w-full">
                                    <AutoSizer>
                                          {({ width, height }) => (
                                                <Collection
                                                      cellCount={listcat.length}
                                                      cellRenderer={cellRenderer}
                                                      width={width}
                                                      ref={collectionRef}
                                                      scrollToCell={listPos}

                                                      height={height}
                                                      cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                                                                  overscanRowCount={30}

                                                />
                                          )}
                                    </AutoSizer>


                                                <div style={{ height: 200 }} /> {/* Spacer div to account for future content height */}
                                          </div>


                        </div>
                  </div>
            </div>
                  }
            </div>

            <Toaster />

      </FocusContext.Provider>

}

export default ParentalList;