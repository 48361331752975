import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../model/GlobalContext";
import { FocusContext, FocusDetails, getCurrentFocusKey, navigateByDirection, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import 'react-virtualized/styles.css'; // import the styles
import './Home.css'; // import the styles
import { Collection } from 'react-virtualized';
import useWindowDimensions from "../hooks/useWindowDimensions";
import { WindowScroller, AutoSizer } from 'react-virtualized';
import React from "react";
import { useCustomEvent } from "../Event/CustomEventContext";
import ItemEpi from "./sub/ItemEpi";
import useRemtoPx from "../hooks/remToPx";
import useEventListener from "@use-it/event-listener";
const ESCAPE_KEYS = ['27', '10009'];

const Season: React.FC = () => {
      const { height, width } = useWindowDimensions();
      const collectionRef = createRef<Collection>();
      const { addEventListener, removeEventListener } = useCustomEvent();
      const itemHeight = useRemtoPx(15)
      const itemMargin = useRemtoPx(0.5)

      const navigate = useNavigate();
      const { index } = useParams();

      const [serieObj, setSerieObj] = useState<any>({});
      const { ref, focusSelf, focusKey } = useFocusable({ trackChildren: true });
      const scrollingRef = useRef<HTMLDivElement>(null);
      const { serie, changeSerie, profile, profileShow } = useGlobalContext();
      const [serieEPi, setSerieEPi] = useState<any[]>([]);
      const [serieSea, setSerieSea] = useState<string>("1");

      let serieOB;
      useEffect(() => {
            if (index !== undefined) {
                  setSerieSea(serie.sea[parseInt(index)])
                  setSerieEPi(serie.episodes[serie.sea[parseInt(index)]])
            }

      }, [serie]);

      useEffect(() => {

            const wheelListener = (event) => {
                  const delta = Math.sign(event.deltaY);
                  if (delta > 0) {
                        const focusDetails: FocusDetails = {
                              direction: 'down',
                              from: getCurrentFocusKey(),
                              // Other necessary details based on the library's documentation
                        };

                        navigateByDirection('down', focusDetails)
} else if (delta < 0) {
                        const focusDetails: FocusDetails = {
                              direction: 'up',
                              from: getCurrentFocusKey(),
                              // Other necessary details based on the library's documentation
                        };
                        navigateByDirection('up', focusDetails)
                  }
            };

            // Attach the wheel event listener
            document.addEventListener('wheel', wheelListener);

            // Remove the wheel event listener when the component is unmounted
            return () => {
                  document.removeEventListener('wheel', wheelListener);
            };
      }, []);



      function handler({ keyCode }) {
            console.log(keyCode)
            if (ESCAPE_KEYS.includes(String(keyCode))) {

                  setTimeout(() => {
                        if (!profileShow)
                              navigate(-1)

                  })

            }
      }

      useEventListener('keydown', handler);


      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      const onAssetFocus = useCallback(

            ({ x, y }: { x: number, y: number }) => {
                  const duration = 20; // Set your desired duration in milliseconds

                  // Ensure scrollingRef.current is not null before calling scrollToWithAnimation
                  if (scrollingRef.current) {
                        scrollToWithAnimation(scrollingRef.current, y, duration);
                  }
            },
            [scrollingRef]
      );
      const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const scrollToWithAnimation = (element, to, duration) => {
            const start = element.scrollTop;
            const change = to - start;
            const startDate = new Date().getTime();

            const animateScroll = () => {
                  const currentDate = new Date().getTime();
                  const elapsed = currentDate - startDate;

                  element.scrollTop = easeInOutQuad(elapsed, start, change, duration);

                  if (elapsed < duration) {
                        requestAnimationFrame(animateScroll);
                  }
            };

            animateScroll();
      };
      const { encodedData } = useParams();


      function randChoice<T>(): T {
            return (serie.info.backdrop_path > 0) ?serie.info.backdrop_path[
                  Math.floor(Math.random() * serie.info.backdrop_path.length)
            ] : serie.info.cover;
      }
      const changeScroll = () => {
            focusSelf();

      }

      const cellRenderer = ({ index, key, style }) => (
            <div key={key} style={style} data-key={key} className="p-2">
                  <ItemEpi key={index} index={index} season={serieEPi[index]} cover={randChoice()}  focusKey={'Epi'+index}/>

            </div>
      );
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  focusSelf();

                  scrollToIndex(payload)

            };

            addEventListener('focusEvent', handleCustomEvent);

            return () => {
                  removeEventListener('focusEvent', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);
      const scrollToIndex = (index) => {
            // Calculate the position to scroll to based on the index and cell height
            const columnCount = 4; // Assuming 3 columns

            const cellHeight = itemHeight;
            const margin = itemMargin; // Adjust this based on any margins

            const row = Math.floor(index / columnCount);
            const scrollToPosition = row * (cellHeight + margin);
            window.scrollTo(0, scrollToPosition-200);

      };
      const cellSizeAndPositionGetter = ({ index }) => {
            const columnCount = 4;
            const cellWidth = ((width * 0.90)) / 4 - itemMargin;
            const cellHeight = itemHeight;
            const margin = itemMargin;

            const column = index % columnCount;
            const row = Math.floor(index / columnCount);

            return {
                  height: cellHeight ,
                  width: cellWidth ,
                  x: column * cellWidth,
                  y: row * (cellHeight ),
            };
      };
      return <FocusContext.Provider value={focusKey}><div ref={scrollingRef} className=" w-full h-screen px-20 mx-auto   flex flex-col absolute bottom-0 left-0	" >
            <h1 className="text-white text-4xl font-bold my-0 mt-28 text-s mx-auto  text-left w-full capitalize ">Season {serieSea}</h1>
            <div className="list flex-grow  mt-28  w-full">
                  <WindowScroller>
                        {({ height, scrollTop, onChildScroll }) => (
                              <div onScroll={changeScroll}>

                                    <AutoSizer disableHeight>
                                          {({ width }) => (

                                                <Collection
                                                      autoHeight
                                                      tabIndex={-1}
                                                      ref={collectionRef}
                                                      height={height}
                                                      width={width}
                                                      scrollTop={scrollTop}
                                                      cellSizeAndPositionGetter={cellSizeAndPositionGetter}

                                                      onScroll={onChildScroll}

                                                      cellRenderer={cellRenderer}
                                                      cellCount={serieEPi.length}
                                                      overscanRowCount={50}
                                                />


                                          )}
                                    </AutoSizer>
                                    <div style={{ height: 200 }} /> {/* Spacer div to account for future content height */}
                              </div>
                        )}
                  </WindowScroller>


            </div>
        </div>
      </FocusContext.Provider>

}

export default Season;