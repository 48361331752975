import React from 'react';

function RWIcon({ classes }: { classes?: string }) {
      return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" version="1.1" id="svg6" className={classes}
 >
                  <defs id="defs10" />
                  <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M 21.999975,6.42632 V 17.5737 c 0,1.8474 -1.60065,3.0151 -2.90313,2.1179 l -6.09667,-4.46 V 8.76844 l 6.09668,-4.46006 c 1.30248,-0.89716 2.90312,0.27057 2.90312,2.11794 z"  id="path2" />
                  <path d="m 12.999975,7.12303 v 1.64541 6.46316 1.6454 c 0,1.6164 -1.4673,2.6382 -2.6612,1.8532 l -7.4174,-4.877 c -1.2285,-0.8077 -1.2285,-2.8987 0,-3.7064 l 7.4174,-4.87697 c 1.1939,-0.78501 2.6612,0.23675 2.6612,1.8532 z"  id="path4" />
            </svg>
      );
};

export default RWIcon;
