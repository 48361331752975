
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useRef, useState } from "react";
import ItemSettingSheet from "../sub/ItemSettingSheet";
import ButtonItem from "../sub/ButtonItem";
import Refrech from "../svg/Refrech";
import InputFocus from "../sub/InputFocus";
import Save from "../svg/Save";
import Cancel from "../svg/Cancel";
import { useCustomEvent } from "../../Event/CustomEventContext";
import toast, { Toaster } from 'react-hot-toast';

function Parental({ isOpen, onCloseFn, onOpenFn }) {
      const [inputPassword, setInputPassword] = useState('');
      const inputRef = useRef<HTMLInputElement>(null);
      const inputRef2 = useRef<HTMLInputElement>(null);

      const [inputPasswordConfirmation, setInputPasswordConfirmation] = useState('');
      const { addEventListener, removeEventListener } = useCustomEvent();
      let varible=''
      const { ref, focusSelf, focusKey, focused } = useFocusable({ focusKey: "Language" });
      const [isSeted, setSeted] = useState<boolean>(false);

      const handleInputPassword = (value) => {
            setInputPassword(value);
            varible = value;
            console.log(varible)
      };
      const handleInputPasswordConfirmation = (value) => {
            setInputPasswordConfirmation(value);
            console.log(value)

      };
      useEffect(() => {
            console.log("open", isOpen)
            if (isOpen) {
                  setFocus('Language');

                  let password = localStorage.getItem('password');
                  if (!password) {
                        setSeted(false);
                  }else{
                        setSeted(true);

                  }

            }
            else {
            }

      }, [isOpen]);
      const showToast = () => {
            toast.success("password saved !", {
                  position: "bottom-center",
                  style: { borderRadius: "200px", background: "#8181B5", color: " #fff" },
            });
      };
      const showToast2 = () => {
            toast.success("password verified !", {
                  position: "bottom-center",
                  style: { borderRadius: "200px", background: "#8181B5", color: " #fff" },
            });
      };
      const showToastError = () => {
            toast.error("password not match !", {
                  position: "bottom-center",
                  style: { borderRadius: "200px", background: "#8181B5", color: " #fff" },
            });
      };
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  onCloseFn()
            };

            addEventListener('close', handleCustomEvent);
            const handleCustomEventSave = (payload) => {
                  let password = localStorage.getItem('password');

                  if (!password){


                  if ((inputRef.current!!.value != inputRef2.current!!.value) || inputRef.current!!.value=="")
                  {
                        showToastError()


                  }else{
                        showToast()
                        setSeted(true);
                        localStorage.setItem('password', inputRef.current!!.value);

                  }
                  }else{
                        console.log(password)
                        if ((inputRef.current!!.value != password) || inputRef.current!!.value == "") {
                              showToastError()


                        } else {
                              showToast2()
                              onOpenFn()
                             // setSeted(true);
                              //localStorage.setItem('password', inputRef.current!!.value);

                        }

                  }
            };

            addEventListener('save', handleCustomEventSave);

            return () => {
                  removeEventListener('close', handleCustomEvent);
                  removeEventListener('save', handleCustomEventSave);
            };
      }, [addEventListener, removeEventListener]);

      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col 	" ref={ref}>

                  <h1 className="text-white text-4xl font-bold my-0 mt-10 text-s mx-auto  text-left w-full capitalize ">Controle Parental</h1>

            <div className="flex items-center  mt-16 ">
                        <InputFocus placeholder={"password"} onInputValueChange ={handleInputPassword}></InputFocus>
                        {!isSeted && <InputFocus placeholder={"confirm password"} onInputValueChange={handleInputPasswordConfirmation}></InputFocus>
                        }      <input
                              type="hidden"
                              value={inputPassword}
                              ref={inputRef}
                              style={{ display: 'none' }}
                        />

                        <input
                              type="hidden"
                              value={inputPasswordConfirmation}
                              ref={inputRef2}
                              style={{ display: 'none' }}
                        />

                        <ButtonItem event="save" classes="mr-10  py-6 px-12 ">
                              <ButtonItem.Slot name="leftActions">
                                    <Save classes="fill-textcolor2" />
                              </ButtonItem.Slot>
                              <ButtonItem.Slot name="leftActionsFocused">
                                    <Save classes="fill-white" />
                              </ButtonItem.Slot>

                              <ButtonItem.Slot name="rightActions">
{ !isSeted&&                                   <span className="ml-2">Save</span>
}
                                    {isSeted && <span className="ml-2">Enter</span>
                                    }
                                               </ButtonItem.Slot>
                        </ButtonItem>
                        <ButtonItem event="close" >
                              <ButtonItem.Slot name="leftActions">
                                    <Cancel classes="fill-textcolor2" />
                              </ButtonItem.Slot>
                              <ButtonItem.Slot name="leftActionsFocused">
                                    <Cancel classes="fill-white" />
                              </ButtonItem.Slot>

                              <ButtonItem.Slot name="rightActions">
                                    <span className="ml-2">Cancel</span>
                              </ButtonItem.Slot>
                        </ButtonItem>

            </div>
            </div>
            <Toaster />


      </FocusContext.Provider>

}

export default Parental;