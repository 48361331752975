
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import ItemSettingSheet from "../sub/ItemSettingSheet";
import ItemSettingSheetSize from "../sub/ItemSettingSheetSize";
import ItemTrack from "../sub/ItemTrack";
import ItemTrackAudio from "../sub/ItemTrackAudio";

function SettingPlayer({ isOpen, onCloseFnSpeed, onCloseFnCaption, trucks, onCloseFnAudio ,rate }) {
      const { ref, focusSelf, focusKey, focused } = useFocusable({focusKey:"settingplayer"});
      const [languageList, setLanguageList] = useState<any[]>([{ id: "0", title: "X0.5" }, { id: "1", title: "X1" }, { id: "2", title: "X1.5" }, { id: "3", title: "X2" }]);
      const [textruks, setTextruks] = useState<any[]>(trucks.filter((data) => { return data.type == "TEXT" }))
      const [audioTruks, setAudioTruks] = useState<any[]>(trucks.filter((data) => { return data.type == "AUDIO" }))

      useEffect(() => {

            if (isOpen)
            {  if(rate==0.5){
                  setFocus('S0')
            }
                  if (rate == 1) {
                        setFocus('S1')
                  }
                  if (rate == 1.5) {
                        setFocus('S2')
                  }
                  if (rate == 2) {
                        setFocus('S3')
                  }
            }
            else{
            }

      }, [isOpen]);
      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col 	" ref={ref}>

            <h1 className="text-white text-3xl font-bold my-0 mt-4 text-s mx-auto  text-left w-full capitalize ">Speed</h1>

            <div className="  flex  mt-4  w-full">
                        {languageList.map((obj, index) => (
                              <ItemSettingSheetSize key={index} index={index} obj={obj} onClose={onCloseFnSpeed} focusKey={"S" + obj.id}  />
                        ))}
                  </div>
                  <h1 className="text-white text-3xl font-bold my-0 mt-4 text-s mx-auto  text-left w-full capitalize ">Caption</h1>

                  <div className="  flex  mt-4  w-full text-white overflow-auto">
                        {textruks.length == 0 && <p>no Caption available</p>}
                        {textruks.length > 0 &&<>
                              <ItemTrack key={99} index={99} obj={null} onClose={onCloseFnAudio} focusKey={"C99"} />

                              {textruks.map((obj, index) => (
                                    <ItemTrack key={index} index={index} obj={obj} onClose={onCloseFnCaption} focusKey={"C" + obj.index} />
                              ))}

                        </>}
                  </div>
                {(audioTruks.length>1)  && <><h1 className="text-white text-3xl font-bold my-0 mt-4 text-s mx-auto  text-left w-full capitalize ">Audio</h1>

                  <div className="  flex  mt-4  w-full text-white">
                              {audioTruks.length == 0 && <p>no Caption available</p>}
                              {audioTruks.length > 0 && <>

                                    {audioTruks.map((obj, index) => (
                                    <ItemTrackAudio key={index} index={index} obj={obj} onClose={onCloseFnAudio} focusKey={"A" + obj.index} />
                              ))}

                        </>}
                  </div>
                  </>
                  }
                  </div>


              </FocusContext.Provider>
}

export default SettingPlayer;