import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useMemo, useState } from "react";
import { useCustomEvent } from "../../Event/CustomEventContext";
import Mask from "./Mask";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import a CSS effect (optional)
import LazyLoad from 'react-lazyload';
import { Navigate, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../model/GlobalContext";
import Like from "../svg/Like";

function ItemMovie({ index, movie, focusKey: focusKeyParam, listFav, isScrolling =false}) {
      const { emitEvent } = useCustomEvent();
      const navigate = useNavigate();
      const { changeMovie } = useGlobalContext();
      movie.isLiked = listFav.includes(String(movie.stream_id))
      const [isScrolled, setisScrolled] = useState<boolean>(false);
      useEffect(() => {
            console.log(isScrolling)
            if (isScrolling) {
               setTimeout(()=>{
                    setisScrolled(true)
               },3500)
            }
      }, []);

      const { ref, focused, focusSelf } = useFocusable({
            focusKey: focusKeyParam,

            onEnterPress: () => {
                  onEnterPress()
            },
      });
      const onEnterPress= () => {
            changeMovie(movie)
            navigate('/movie/' + movie.stream_id)
      }
      useEffect(() => {

            if (focused) {
                 emitEvent('focusEventMovie', index);
                  ref.current.scrollIntoView({
                        behavior: 'instant',
                        block: 'center', inline: 'nearest'
                  });
            }
      }, [focused]);
      const MemoizedImage = useMemo(() => {

            if (!isScrolled) {
                  return <LazyLoad height="100%" offset={0}>

                        <LazyLoadImage
                              alt={movie.name}
                              height="100%"
                              width="100%"
                              src={movie.stream_icon}
                        />
                  </LazyLoad>
            }
            return <></>;
      }, [movie.name, movie.stream_icon, isScrolled]);


      return <div ref={ref} onClick={onEnterPress}
            onMouseEnter={focusSelf}  className={"  w-full h-full" }
      ><div className="w-full h-full   outline-none"
                 ><div
                  className="text-start	   outline-none w-full h-full transition-all duration-300 cursor-pointer"
                 >
                        <div className={(focused ? "bg-white" : "") + " w-1/3 mx-auto h-10 "} />
                        <div
                              className={(focused ? " bg-gradient-custom " : "") + "  w-full h-full relative backdrop-blur-2xl bg-secondary  -mt-9  "}
                        >

                              <Mask mask="linear-gradient(199.42deg, #292945 -15.04%, rgba(41, 41, 69, 0) 78.01%)">
                                    {MemoizedImage}

</Mask>

                              <div
                                    className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-end p-10"
                              >
                                    <h2 className="text-white text-xl my-3">

                                          {movie.name}
                                          </h2>

                              </div>
                              {movie.isLiked && <div className="absolute right-1 top-1"><Like class="fill-rose-600 " /></div>
}

                        <div
                              className={(focused ? "block " : "hidden") + " absolute left-0 top-0 right-0 bottom-0  "}
                              style={{ "backgroundImage": "url(/images/bordergradient.svg)" }}
                        />
                        </div>

                  </div >
            </div></div>
}

export default ItemMovie;

