import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const BottomSheet = ({ isOpen, onClose, children ,size="50%"}) => {
      const springProps = useSpring({
            transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',

      });
      const springProps2 = useSpring({
            opacity: isOpen ? 1 : 0, // Add opacity animation for the overlay

      });
      return (
            <animated.div className="bg-transparentWhite30 w-screen h-screen" style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  zIndex: isOpen ? 99 : -1,
                  opacity: springProps2.opacity,
}} >

            <animated.div
                  className="bg-primary"
                  style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: size, // Adjust the height as needed
                        boxShadow: '0px -5px 15px rgba(0, 0, 0, 0.1)',
                        ...springProps,
                  }}
            >
                  {children}
                  <button onClick={onClose}>Close</button>
            </animated.div>
            </animated.div>

      );
};
export default BottomSheet;
