import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Return from '../svg/Return';
import Search from '../svg/Search';
import Setting from '../svg/Setting';
import Whether from './Whether';
import { City } from '../../model/model';
import CircleButton from './CircleButton';
import { useCustomEvent } from '../../Event/CustomEventContext';
import { setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import ProfileIcon from '../svg/ProfileIcon';
import { generateurl } from '../../model/constant';
import { useGlobalContext } from '../../model/GlobalContext';
import ButtonItem from './ButtonItem';
import Refrech from '../svg/Refrech';
import Cancel from '../svg/Cancel';
import Profile from './Profile';
import useEventListener from '@use-it/event-listener';
const ESCAPE_KEYS = ['27', '10009'];

function Nav(props) {
      const navigate = useNavigate();
      const { profile, profileShow, changeProfileShow, changeProfile, changeCatLive, changeListLive, changeCatSerie, changeListVod, changeCatVod, changeListSerie, changeListLiveFav, changeListLiveRecent, changeListVodFav, changeListVodRecent, changeListSerieFav, changeListSerieRecent, parentalEnable } = useGlobalContext();
      const [ProfileServer, changeProfileServer] = useState<any>({});


      const { addEventListener, removeEventListener } = useCustomEvent();

      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  navigate("/setting");
            }
            const handleProfile = (payload) => {
                  var requestOptions: RequestInit = {
                        method: "GET",
                        redirect: "follow",
                  };

                  fetch(generateurl("", profile), requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                              changeProfileServer(result)
                              changeProfileShow(true);
                              setTimeout(() => {
                                    setFocus('Profile')
},100)
                        })
                        .catch((error) => console.log("error", error));
                        }

            addEventListener('setting', handleCustomEvent);

            const handleCustomEventBack = (payload) => {
                  navigate(-1);
            }
            const handleSearch = (payload) => {
                  navigate("/search");
            }
            addEventListener('back', handleCustomEventBack);
            addEventListener('search', handleSearch);
            addEventListener('profile', handleProfile);

            return () => {
                  removeEventListener('setting', handleCustomEvent);
                  removeEventListener('back', handleCustomEventBack);
                  removeEventListener('search', handleSearch);
                  removeEventListener('profile', handleProfile);

            };





      }, [addEventListener, removeEventListener]);
      const [time, setTime] = useState<any>({})

      const [path, setPath] = useState(window.location.pathname);
      let timeFormat = localStorage.getItem("timeformat") ||"12hours"
      let opsiJam: Intl.DateTimeFormatOptions = {
            hour: "numeric",
            minute: "numeric",
            hour12: timeFormat =="12hours" ?true:false,
      };
      let opsiTanggal: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
      };

      let whether: any = null;
      let lastwether = 0;
      let city: City;
      let classes = "h-14 transition-all absolute"

       useEffect(()=>{
         //   city = get(cityStore) as City;

        //    getWhether();
             console.log(window.location.pathname)

             const handlePathnameChange = () => {
                   setPath(window.location.pathname)
             };

             // Add the event listener to window
             window.addEventListener('hashchange', handlePathnameChange);

             // Clean up the event listener when the component unmounts

             if (path !== "/main/") {
                   classes += " ml-20"
             }
          /*  const interval = setInterval(() => {
                  date = new Date();
                  if (date.getTime() - lastwether > 60 * 60 * 1000) getWhether();
            }, 1000);*/


            let interval2= setInterval(() => {

                  let date = new Date();
                  let tanggal = new Intl.DateTimeFormat("en-US", opsiTanggal).format(date);
                  let jam = new Intl.DateTimeFormat("en", opsiJam).format(date);

                  setTime({ "time": jam , "date": tanggal })
             }, 1000)


            return () => {
                  clearInterval(interval2);

                  window.removeEventListener('hashchange', handlePathnameChange);

            };
      },[])
      function handler({ keyCode }) {


            if (ESCAPE_KEYS.includes(String(keyCode))) {
                  setTimeout(() => {
                        if (profileShow)
                              changeProfileShow(false)

                  })

            }
      }

      useEventListener('keydown', handler);
      const getWhether = () => {
            if (city) {
                  var requestOptions: RequestInit = {
                        method: "GET",
                        redirect: "follow",
                  };
                  fetch(
                        "https://api.open-meteo.com/v1/forecast?latitude=" +
                        city.lat +
                        "&longitude=" +
                        city.lng +
                        "&current_weather=true&hourly_units=true&weathercode=true&weather=true",
                        requestOptions
                  )
                        .then((response) => response.json())
                        .then((result) => {
                              whether = result;
                              lastwether = new Date().getTime();
                        })
                        .catch((error) => console.log("error", error));
            }
      };
      const onRefrech=()=>{
            changeCatLive([]);
            changeCatSerie([]);
            changeCatVod([]);
            changeListLive([])
            changeListVod([])
            changeListSerie([])
            changeListLiveFav([])
            changeListVodFav([])
            changeListSerieFav([])
            changeListLiveRecent([])
            changeListVodRecent([])
            changeListSerieRecent([])

            changeProfile({...profile});
changeProfileShow(false)
      }
      return (<><div
            className="  mx-20 navbar absolute top-0 left-0 z-10 pt-8   flex
  justify-center items-center transition-all right-0 shrink-0 delay-300 overflow-hidden"
      >

            <div className="pl-1  grow  transition-all h-14 delay-300 flex"

            >
                  {path !="/main/" &&
                        <CircleButton event="back">


                              <CircleButton.Slot name="rightActions">
                                    <Return/>
                              </CircleButton.Slot>
                        </CircleButton>
}
                  < img src="/images/logoui.svg" className={(path != "/main/" ?" ml-20 ":"ml-0 ")+classes} />
            </div>

            <div
                  className="menuItems flex justify-center items-center rounded-full mr-4"
                  id="menu"
            >
                  {parentalEnable && <img className="w-14 h-14 mx-auto mr-4 animated-shild" src="/images/shield.svg" alt="" />}


                  <CircleButton event="search">


                        <CircleButton.Slot name="rightActions">
                              <Search />
                        </CircleButton.Slot>
                  </CircleButton>
                  <CircleButton event="setting" classes='mr-4'>


                        <CircleButton.Slot name="rightActions" >
                              <Setting />
                        </CircleButton.Slot>
                  </CircleButton>

                  {whether&&
                  <Whether
                        is_day={whether.current_weather.is_day}
                        deg={whether.current_weather.temperature}
                        weathercode={whether.current_weather.weathercode}
                  />
}
                        < div className="flex justify-center items-center mr-6">
                  <div className="text-white">
                        <div className="text-2xl">{time.time}</div>
                              <div className="text-lg">{time.date}</div>
                  </div>
            </div>
                  <CircleButton event="profile" classes='mr-4'>


                        <CircleButton.Slot name="rightActions" >
                              <ProfileIcon />
                        </CircleButton.Slot>
                  </CircleButton>
            </div>
      </div>

            {profileShow && <Profile ProfileServer={ProfileServer} profile={profile} onClose={() => changeProfileShow(false)} onRefrech={onRefrech}/>
            }
    </>
);
}

export default Nav;