import React from 'react';

const Play = (props) => {
      return (
            <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  className={props.class}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
            >
                  <path
                        d="M11.9697 2.5C6.44973 2.5 1.96973 6.98 1.96973 12.5C1.96973 18.02 6.44973 22.5 11.9697 22.5C17.4897 22.5 21.9697 18.02 21.9697 12.5C21.9697 6.98 17.4997 2.5 11.9697 2.5ZM14.9697 14.73L12.0697 16.4C11.7097 16.61 11.3097 16.71 10.9197 16.71C10.5197 16.71 10.1297 16.61 9.76973 16.4C9.04973 15.98 8.61973 15.24 8.61973 14.4V11.05C8.61973 10.22 9.04973 9.47 9.76973 9.05C10.4897 8.63 11.3497 8.63 12.0797 9.05L14.9797 10.72C15.6997 11.14 16.1297 11.88 16.1297 12.72C16.1297 13.56 15.6997 14.31 14.9697 14.73Z"
                  />
            </svg>
      );
};

export default Play;
