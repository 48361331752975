import React, { useEffect, useRef, useState } from 'react';

const LockComponent = ({closedElm}) => {

      const [closed, setClosed] = useState(false);
      useEffect(() => {
            setClosed(closedElm)
      }, [closedElm])
      const domLockRef = useRef<SVGSVGElement>(null);

      const handleLockClick = () => {
            setClosed((prevClosed) => !prevClosed);
            resetAnimation();
      };

      const getAnimation = () => {
            return closed
                  ? 'LinearShake ease-in-out 280ms, 360ms AngularShake ease-in-out 280ms'
                  : 'LinearShake ease-in-out 280ms';
      };

      const resetAnimation = () => {
            if (domLockRef.current) {
                  (domLockRef.current as SVGSVGElement).style.animation = 'none';
                  setTimeout(() => {
                        (domLockRef.current as SVGSVGElement).style.animation = getAnimation();
                  }, 0);
            }
      };

      return (
            <svg
                  className={`lock ${closed ? 'closed' : ''}`}
                  viewBox="0 0 184 220.19"
                  onClick={handleLockClick}
                  ref={domLockRef}
            >
                  <clipPath id="clip-path">
                        <rect className="fill-mask" x="7.5" y="97.69" width="169" height="115" rx="18.5" ry="18.5" />
                  </clipPath>
                  <g className="fill-mask-group">
                        <circle className="fill-circle" cx="142.5" cy="97.69" r="1.5" />
                  </g>
                  <path className="top-part" d="M41.5,93.69V56.93A49.24,49.24,0,0,1,90.73,7.69h2.54A49.24,49.24,0,0,1,142.5,56.93v2.26" />
                  <rect className="bottom-part" x="7.5" y="97.69" width="169" height="115" rx="18.5" ry="18.5" />
            </svg>
      );
};

export default LockComponent;
