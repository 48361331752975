import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';

type SlotProps = {
  name: 'rightActions' | 'rightActionsFocused';
  className?: string; // New prop for className

  children?: React.ReactNode;

};

const Slot: React.FC<SlotProps> = ({ children }) => <>{children}</>;

type NavbarContainerProps = {
  children: ReactElement<SlotProps> | ReactElement<SlotProps>[];
  event: string;
  classes?: string;
  eventFn? :any

};


const CircleButtonPlayer: React.FC<NavbarContainerProps> & { Slot: React.FC<SlotProps> } = ({ children, event, classes, eventFn }) => {
  const { emitEvent } = useCustomEvent();

  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress: () => {
      onEnterPress();

    },
  });
  const onEnterPress = () => {
    if (eventFn) {
      eventFn()
    } else {
      emitEvent(event, "click");

    }
  }
  if(classes==""){
    classes =" "
  }
  const childrenArray = Children.toArray(children) as ReactElement<SlotProps>[];
  const rightActionsSlot = childrenArray.find((child) => child?.props?.name === 'rightActions');
  const rightActionsSlotFocused = childrenArray.find((child) => child?.props?.name === 'rightActionsFocused');

  return (<div ref={ref} onClick={onEnterPress}
    onMouseEnter={focusSelf}  className={(focused ? "border-0 bg-gradientplay shadow-lg" : " border-transparentWhite50 ") +" border-0  w-20 h-20 rounded-full mr-4 focusable outline-none flex justify-center items-center"}
                                                tabIndex={-1}
                                          >



    {!focused && <div className="flex">{rightActionsSlot?.props?.children}</div>}
    {focused && <div className="flex">{rightActionsSlotFocused?.props?.children}</div>}
                                          </div>
  );
};

CircleButtonPlayer.Slot = Slot;

export default CircleButtonPlayer;

