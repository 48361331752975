import React, { createContext, useContext, useMemo } from 'react';

interface CustomEventContextProps {
      emitEvent: (eventName: string, payload: any) => void;
      addEventListener: (eventName: string, callback: (payload: any) => void) => void;
      removeEventListener: (eventName: string, callback: (payload: any) => void) => void;
}

const CustomEventContext = createContext<CustomEventContextProps | undefined>(undefined);

export const useCustomEvent = () => {
      const context = useContext(CustomEventContext);
      if (!context) {
            throw new Error('useCustomEvent must be used within a CustomEventProvider');
      }
      return context;
};

interface CustomEventProviderProps {
      children: React.ReactNode;
}

export const CustomEventProvider: React.FC<CustomEventProviderProps> = ({ children }) => {
      const listeners: { [eventName: string]: ((payload: any) => void)[] } = {};

      const emitEvent = (eventName: string, payload: any) => {
            if (listeners[eventName]) {
                  listeners[eventName].forEach((listener) => listener(payload));
            }
      };

      const addEventListener = (eventName: string, callback: (payload: any) => void) => {
            if (!listeners[eventName]) {
                  listeners[eventName] = [];
            }
            listeners[eventName].push(callback);
      };

      const removeEventListener = (eventName: string, callback: (payload: any) => void) => {
            if (listeners[eventName]) {
                  listeners[eventName] = listeners[eventName].filter((listener) => listener !== callback);
            }
      };

      const contextValue = useMemo(
            () => ({
                  emitEvent,
                  addEventListener,
                  removeEventListener,
            }),
            [emitEvent, addEventListener, removeEventListener]
      );

      return <CustomEventContext.Provider value={contextValue}>{children}</CustomEventContext.Provider>;
};
