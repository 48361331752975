import React from 'react';

const PassIcon = () => {
      return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M26.3865 5.62676C22.4399 1.69342 16.0399 1.69342 12.1199 5.62676C9.35987 8.36009 8.5332 12.2934 9.59987 15.7601L3.3332 22.0268C2.8932 22.4801 2.58654 23.3734 2.67987 24.0134L3.07987 26.9201C3.22654 27.8801 4.11987 28.7868 5.07987 28.9201L7.98654 29.3201C8.62654 29.4134 9.51987 29.1201 9.9732 28.6534L11.0665 27.5601C11.3332 27.3068 11.3332 26.8801 11.0665 26.6134L8.47987 24.0268C8.0932 23.6401 8.0932 23.0001 8.47987 22.6134C8.86654 22.2268 9.50654 22.2268 9.8932 22.6134L12.4932 25.2134C12.7465 25.4668 13.1732 25.4668 13.4265 25.2134L16.2532 22.4001C19.7065 23.4801 23.6399 22.6401 26.3865 19.9068C30.3199 15.9734 30.3199 9.56009 26.3865 5.62676ZM19.3332 16.0001C17.4932 16.0001 15.9999 14.5068 15.9999 12.6668C15.9999 10.8268 17.4932 9.33342 19.3332 9.33342C21.1732 9.33342 22.6665 10.8268 22.6665 12.6668C22.6665 14.5068 21.1732 16.0001 19.3332 16.0001Z" fill="#ABABCD" />
                  <path d="M19.3333 16.0002C21.1743 16.0002 22.6667 14.5078 22.6667 12.6668C22.6667 10.8259 21.1743 9.3335 19.3333 9.3335C17.4924 9.3335 16 10.8259 16 12.6668C16 14.5078 17.4924 16.0002 19.3333 16.0002Z" fill="#ABABCD" />
            </svg>
      );
};

export default PassIcon;
