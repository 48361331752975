import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement, useEffect, useRef, useState } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';



function InputFocus({ placeholder, onInputValueChange }){
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  // Event handler for input change
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    // Call the callback function to pass the inputValue to the parent
    onInputValueChange(newValue);
  };

  const { ref, focusKey, focusSelf, focused } = useFocusable();
  useEffect(() => {
    console.log('focused array changed:', focused);
    if (focused) {
      ref.current?.focus()
    }else{
      ref.current?.blur()

    }
    //    addToGlobalArray('eeeee')
    // Do something when the global array changes
  }, [focused]);
  return <FocusContext.Provider value={focusKey}>
    <input type="text" placeholder={placeholder} ref={ref} className={(focused ? "bg-red-500/30" : "bg-white/10") + " w-full h-20 mr-10 outline-none border-0 rounded-md text-white text-lg px-5 max-w-md	placeholder:italic placeholder:text-white/50"}
      value={inputValue}
      onChange={handleInputChange}
/>
</FocusContext.Provider>
}

export default InputFocus;

