import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import a CSS effect (optional)
import LazyLoad from 'react-lazyload';
import { useEffect } from "react";
import { useCustomEvent } from "../../Event/CustomEventContext";
import Like from "../svg/Like";

function ItemChannelSquare({ item, position, focusKey: focusKeyParam,  listFav,type }) {
      const navigate = useNavigate();

      const { emitEvent } = useCustomEvent();
      item.isLiked = listFav.includes(String(item.stream_id))
      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,
            onEnterPress: () => {
                  navigate("/live/" + type +"/" + position);
               //   onChannelChange(position);

            },
      });
      useEffect(() => {
            if (focused) {
                  ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                  });
                  emitEvent('focusEventLive', position);

            }
      }, [focused]);

      return <div className={(focused ? "bg-secondary2" :"bg-secondary")+" w-full h-full  rounded-lg overflow-hidden flex flex-col items-center align-center"} ref={ref}>

            <LazyLoad height="100%" className=" object-cover object-center m-5" offset={100}>

                  <LazyLoadImage
                        alt={item.name}
                        height="80%"
                        width="80%"
                        placeholderSrc="/images/logoui.svg"
                        src={item.stream_icon}
                        onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/images/logoui.svg";
                        }}
                        wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: { display: "flex", justifyContent :'center' },
                        }}

                        effect="blur" // You can change this to other available effects
                  />
            </LazyLoad>

            <p className="mb-2 text-center text-xl font-bold absolute bottom-0 left-0 right-0 text-white">{item.name}</p>

            {item.isLiked && <div className="absolute right-1 top-1 h-16 flex items-center	"><Like class="fill-rose-600 " /></div>}
 </div>

}

export default ItemChannelSquare;