import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';

const ItemTrack = ({ index, obj, onClose, focusKey: focusKeyParam }) => {

      const json = JSON.parse(obj.extraInfo)
      console.log(json)
      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,
            onEnterPress: () => {
                  if (typeof onClose === 'function') {
                        onClose(obj.index);
                  }else{
                        console.log(typeof onClose)
                  }
            },
      });


      return (
            <FocusContext.Provider value={focusKey}>
            <div ref={ref}
                        className="group relative focusable w-full transition-all duration-300 h-[6rem] outline-none border-0 max-w-[10rem] m-2 "
            >
                  <div
                        className={(focused ?"bg-white":"")+" w-1/3 mx-auto h-[0.4rem] bottom-[0.6rem] absolute left-1/2 -translate-x-1/2 "}
                  />

                  <div className="backdrop-blur-3xl h-[5rem] bg-categoryColor relative w-full">
                        <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                                    {json.language == "" &&   <h2 className="text-white text-normal my-2 text-xl">
                                          Caption {json.channels}
                              </h2>
}
                                    {json.language != "" && <h2 className="text-white text-normal my-2 text-xl">
                                          {json.language}
                                    </h2>
                                    }
                              <div
                                    className={(focused ? "block" : "hidden") + " absolute left-0 top-0 right-0 w-full h-full  "}
                                    style={{ backgroundImage: `url('../images/bordergradient.svg')` }}
                              />
                        </div>
                  </div>
            </div>
            </FocusContext.Provider>
      );
};

export default ItemTrack;
