function Refrech({ classes}:{ classes?: string}) {
      return <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            className={classes}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
      >
            <path
                  d="M12.9336 2C7.41359 2 2.93359 6.48 2.93359 12C2.93359 17.52 7.41359 22 12.9336 22C18.4536 22 22.9336 17.52 22.9336 12C22.9336 6.48 18.4536 2 12.9336 2ZM7.53359 11.23C7.70359 10.04 8.23359 8.97 9.06359 8.13C11.0636 6.14 14.2136 6.03 16.3536 7.77V6.82C16.3536 6.41 16.6936 6.07 17.1036 6.07C17.5136 6.07 17.8536 6.41 17.8536 6.82V9.49C17.8536 9.9 17.5136 10.24 17.1036 10.24H14.4336C14.0236 10.24 13.6836 9.9 13.6836 9.49C13.6836 9.08 14.0236 8.74 14.4336 8.74H15.1836C13.6336 7.66 11.4936 7.81 10.1136 9.19C9.51359 9.79 9.13359 10.57 9.00359 11.44C8.95359 11.81 8.63359 12.08 8.26359 12.08C8.22359 12.08 8.19359 12.08 8.15359 12.07C7.76359 12.02 7.47359 11.64 7.53359 11.23ZM16.8036 15.87C15.7336 16.94 14.3336 17.47 12.9336 17.47C11.7136 17.47 10.5036 17.04 9.50359 16.23V17.17C9.50359 17.58 9.16359 17.92 8.75359 17.92C8.34359 17.92 8.00359 17.58 8.00359 17.17V14.5C8.00359 14.09 8.34359 13.75 8.75359 13.75H11.4236C11.8336 13.75 12.1736 14.09 12.1736 14.5C12.1736 14.91 11.8336 15.25 11.4236 15.25H10.6736C12.2236 16.33 14.3636 16.18 15.7436 14.8C16.3436 14.2 16.7236 13.42 16.8536 12.55C16.9136 12.14 17.2836 11.85 17.7036 11.91C18.1136 11.97 18.3936 12.35 18.3436 12.76C18.1636 13.97 17.6336 15.04 16.8036 15.87Z"
            />
      </svg>

}

export default Refrech;


/*
function Refrech() {
}

export default Refrech;
*/