import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from './components/Home';
import Main from './components/Main';
import { useGlobalContext } from './model/GlobalContext';
import { CustomEventProvider } from './Event/CustomEventContext';
import { Toaster } from 'react-hot-toast';
import { Playlist } from './model/model';
import { generateurl, selectrecentAll, selectall } from './model/constant';
import Category from './components/Category';
import {  init} from "@noriginmedia/norigin-spatial-navigation";
import Movies from './components/Movies';
import Movie from './components/Movie';
import Series from './components/Series';
import Serie from './components/Serie';
import YoutubePlayer from './components/YoutubePlayer';
import Settings from './components/Settings';
import MoviePlayer from './components/MoviePlayer';
import Season from './components/Season';
import LivePlayer from './components/LivePlayer';
import SeriePlayer from './components/SeriePlayer';
import Favorite from './components/Favorite';
import Recent from './components/Recent';
import Search from './components/Search';
import LivePlayerList from './components/LivePlayerList';


const App: React.FC = () => {
  init({
    throttle: 100,
    throttleKeypresses: true,
 });
  const property = "num";
  const { profile, catLive, catVod, catSerie, changeCatLive, changeListLive, changeCatSerie, changeListVod, changeCatVod, changeListSerie,changeListLiveFav,changeListLiveRecent,changeListVodFav,changeListVodRecent,changeListSerieFav,changeListSerieRecent } = useGlobalContext();


  useEffect(() => {
    if (profile.id){
      loadcatLive(profile as Playlist);
      loadcatMovie(profile as Playlist);
      loadcatSerie(profile as Playlist);
      loadChannel(profile as Playlist);
      loadVod(profile as Playlist);

      loadSerie(profile as Playlist);
      loadRecent(profile as Playlist);
      loadFav(profile as Playlist);



   /* toast.error("This didn't work.", {
      position: "bottom-center",
      style: { borderRadius: "200px", background: "#8181B5", color: " #fff" },
    });*/
  }
  }, [profile]);
  const loadcatLive = (value: Playlist) => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(generateurl("get_live_categories", value), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var data: any[] = result;

        data.splice(0, 0, {
          category_id: "-2",
          category_name: "recent",
          parent_id: 0,
          size: 0,
        });
        data.splice(0, 0, {
          category_id: "-1",
          category_name: "favorite",
          parent_id: 0,
          size: 0,
        });
        data.splice(0, 0, {
          category_id: "0",
          category_name: "all",
          parent_id: 0,
          size: 0,
        });
        changeCatLive( data);
      })
      .catch((error) => console.log("error", error));
  };
  const loadcatMovie = (value: Playlist) => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(generateurl("get_vod_categories", value), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var data = result;

        data.splice(0, 0, {
          category_id: "-2",
          category_name: "recent",
          parent_id: 0,
          size: 0,
        });
        data.splice(0, 0, {
          category_id: "-1",
          category_name: "favorite",
          parent_id: 0,
          size: 0,
        });
        data.splice(0, 0, {
          category_id: "0",
          category_name: "all",
          parent_id: 0,
          size: 0,
        });
        changeCatVod(data);
      })
      .catch((error) => console.log("error", error));
  };
  const loadcatSerie = (value: Playlist) => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(generateurl("get_series_categories", value), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var data = result;

        data.splice(0, 0, {
          category_id: "-2",
          category_name: "recent",
          parent_id: 0,
          size: 0,
        });
        data.splice(0, 0, {
          category_id: "-1",
          category_name: "favorite",
          parent_id: 0,
          size: 0,
        });
        data.splice(0, 0, {
          category_id: "0",
          category_name: "all",
          parent_id: 0,
          size: 0,
        });
        changeCatSerie(data);
      })
      .catch((error) => console.log("error", error));
  };
  const loadChannel = (value: Playlist) => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(generateurl("get_live_streams", value), requestOptions)
      .then((response) => response.json())
      .then((result) => {

        changeListLive([...result].sort(dynamicSort(property)));

        let liveListCat: any[] =[...catLive];

        for (let i = 0; i < liveListCat.length; i++) {
          if (liveListCat[i].category_id == 0) {
            liveListCat[i].lengthCat = result.length;
          } else if (liveListCat[i].category_id > 0)
            liveListCat[i].lengthCat = result.filter(
              (channel) => channel.category_id == liveListCat[i].category_id
            ).length;
        }

      })
      .catch((error) => console.log("error", error));
  };
  const loadVod = (value: Playlist) => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(generateurl("get_vod_streams", value), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        changeListVod([...result].sort(dynamicSort(property)));

        let ListCat: any[] = catVod;
        for (let i = 0; i < ListCat.length; i++) {
          if (ListCat[i].category_id == 0) {
            ListCat[i].lengthCat = result.length;
          } else if (ListCat[i].category_id > 0)
            ListCat[i].lengthCat = result.filter(
              (channel) => channel.category_id == ListCat[i].category_id
            ).length;
        }
      //  changeCatVod(ListCat)
      })
      .catch((error) => console.log("error", error));
  };
  const loadSerie = (value: Playlist) => {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(generateurl("get_series", value), requestOptions)
      .then((response) => response.json())
      .then((result) => {

        changeListSerie([...result].sort(dynamicSort(property)));
        let ListCat: any[] = catSerie;
        for (let i = 0; i < ListCat.length; i++) {
          if (ListCat[i].category_id == 0) {
            ListCat[i].lengthCat = result.length;
          } else if (ListCat[i].category_id > 0)
            ListCat[i].lengthCat = result.filter(
              (channel) => channel.category_id == ListCat[i].category_id
            ).length;
        }
       // changeCatSerie([...ListCat])
      })
      .catch((error) => console.log("error", error));
  };

  const loadRecent = (value: Playlist)=>{
    selectrecentAll(value).then(function (recent:any) {

      if (recent) {
        let live = recent.live.map(function (item) {
          return item['streamid'];
        });
        changeListLiveRecent(live)
        let vod = recent.movie.map(function (item) {
          return item['streamid'];
        });
        changeListVodRecent(vod)
      let serie = recent.serie.map(function (item) {
          return item['streamid'];
        });
        changeListSerieRecent(serie)
      }
      });
}
  const loadFav = (value: Playlist) => {

    selectall("movie",value).then(function (favquery:any) {
      if (favquery) {
        let Movie = favquery.map(function (item) {
          return item['streamid'];
        });
        changeListVodFav(Movie)
      }
    });
    selectall("live", value).then(function (favquery: any) {

      if (favquery) {
        let Live = favquery.map(function (item) {
          return item['streamid'];
        });
        changeListLiveFav(Live)

      }
    });
    selectall("serie", value).then(function (favquery: any) {

      if (favquery) {
        let Serie = favquery.map(function (item) {
          return item['streamid'];
        });
        changeListSerieFav(Serie)

      }
    });
  }
  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (typeof a[property] === 'number') {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      } else {
        const result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
        return result * sortOrder;
      }
    };
  };

  return (
    <div className="App w-full h-screen">
      <BrowserRouter>
        <AnimatePresence>
            <CustomEventProvider>

        <Routes >
            <Route path="/" element={<Home />} />
                <Route path="/main" element={<Main />} />
              <Route path="/cat/:type" element={<Category />} />
              <Route path="/movies/:id" element={<Movies />} />
              <Route path="/movie/:encodedData" element={<Movie />} />
              <Route path="/series/:id" element={<Series />} />
              <Route path="/serie/:encodedData" element={<Serie />} />
              <Route path="/youtube/:id" element={<YoutubePlayer />} />
              <Route path="/setting" element={<Settings />} />
              <Route path="/movieplayer" element={<MoviePlayer />} />
              <Route path="/season/:index" element={<Season />} />
              <Route path="/live/:index" element={<LivePlayer />} />
              <Route path="/live/:index/:indexchannel" element={<LivePlayer />} />
              <Route path="/serieplayer" element={<SeriePlayer />} />
              <Route path="/favorite" element={<Favorite />} />
              <Route path="/recent" element={<Recent />} />
              <Route path="/search" element={<Search />} />
              <Route path="/liveList" element={<LivePlayerList />} />

        </Routes>
        </CustomEventProvider>
        </AnimatePresence>
      </BrowserRouter>
      <Toaster />

    </div>

  );
}

export default App;
