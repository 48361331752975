import React, { useRef, useState } from 'react';
import './Home.css';
import { createContext, useContext, FC, ReactNode, useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import MainSetting from './sub/MainSetting';
import MainItem from './sub/MainItem';
import { useGlobalContext } from '../model/GlobalContext';
import { motion } from 'framer-motion';
import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import Nav from './sub/Nav';
import RightSheet from './Settings/RightSheet';
import LanguageCom from './Settings/language';
import useEventListener from '@use-it/event-listener';
import ExitModal from './Settings/exitModal';
import { useNavigate } from 'react-router-dom';
import NoInternet from './Settings/NoInternet';



const ESCAPE_KEYS = ['27', '10009'];


const Main: FC = () => {
      const navigate = useNavigate();

      const [movieSize, setMovieSize] = useState<number>(0);
      const [liveSize, setLiveSize] = useState<number>(0);
      const [serieSize, setSerieSize] = useState<number>(0);
      const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);

      const { ref, focusSelf, focusKey, focused } = useFocusable({ saveLastFocusedChild :true,trackChildren:true});
      const { listVod, listSerie, listLive, profileShow, profile, changeParental } = useGlobalContext();
      useEffect(() => {
            changeParental(false)
            if (!profile.id ){
            navigate('/')
          }

      }, [profile]);

      useEffect(() => {
            focusSelf();
           let main= localStorage.getItem('main') ||'live'
           setFocus(main)

      }, [focusSelf]);
      useEffect(() => {
            setMovieSize(listVod.length)
      }, [listVod]);
      useEffect(() => {
            setSerieSize(listSerie.length)
      }, [listSerie]);
      useEffect(() => {
            setLiveSize(listLive.length)
      }, [listLive]);
      const closeFn = (id) => {
            if (id=='0'){
                  focusSelf()
                  setBottomSheetOpen(false)

                  if (window.Tizen)
                        window.Tizen.exit()
            }
            if (id == '1') {
                  setBottomSheetOpen(false)
                  navigate('/')
            }
            if (id == '2') {
                  focusSelf()
                  setBottomSheetOpen(false)

            }
      }
      function handler({ keyCode }) {


            if (ESCAPE_KEYS.includes(String(keyCode))) {
                  setTimeout(() => {
                        if (!profileShow)
                            {
                              if(isBottomSheetOpen){
                                    setBottomSheetOpen(botom => !botom)
                                    focusSelf();
                              }else{
                                    setBottomSheetOpen(botom => !botom)
                              }
                            }
                  })

            }
      }

      useEventListener('keydown', handler);
      return<> <FocusContext.Provider value={focusKey}>
                <Nav />
<div

 className="w-full h-screen " >

      <div
            className=" w-full h-screen overflow-hidden bg-cover bg-[50%] bg-no-repeat absolute bottom-0 left-0"
      >
            <img src="/images/main.png" className="w-full h-screen min-w-full" />

            <div
                  className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-radial from-0% from-primary via-55% via-primary to-90% to-primarytransparent"
            />
                        <div className="-translate-y-1/2 top-0 absolute  " style={{ width: "55vw", height: "55vh", opacity: 0.10, background: '#ABABCD', borderRadius: "100%", filter: 'blur(5rem)' }} />
                        <div className="-translate-x-1/2 translate-y-1/2 bottom-0 absolute  " style={{ width: "55vw", height: "55vh", opacity: 0.10, background: '#ABABCD', borderRadius: "100%", filter: 'blur(5rem)', }} />
      </div>
      <div
            className=" absolute bottom-0 left-0 w-full h-full justify-center justify-items-center"
      >
                        <div className=" flex  px-36 items-center h-screen	 w-full pt-12 "   >
                              <MainItem card="live" size={liveSize}  />
                              <MainItem card="movie" size={movieSize} />
                              <MainItem card="serie" size={serieSize} />
                  <div
                        className="  w-1/4 h-4/6 mx-3 flex flex-col justify-items-center justify-center"
                  >

                     <div className='p-2'> <MainSetting type="s1" /></div>
                     <div className='p-2'>    <MainSetting type="s2" /></div>
                  <div className='p-2'>   <MainSetting type="s3" /></div>
                  </div>
                        </div>

            </div>
      </div>

</FocusContext.Provider >
            <RightSheet isOpen={isBottomSheetOpen} onClose={() => {  setBottomSheetOpen(false) }}>
                  {isBottomSheetOpen && <ExitModal isOpen={isBottomSheetOpen} onCloseFn={closeFn} />
                  }
            </RightSheet>

</>
}



export default Main;
