import React from 'react';
import { createContext, useContext, FC, ReactNode, useEffect } from 'react';

interface GlobalContextProps {
      deviceInfo: any; // Change the type according to your needs
      parentalEnable: any; // Change the type according to your needs
      profile: any; // Change the type according to your needs
      profileShow: any; // Change the type according to your needs
      settings: any; // Change the type according to your needs
      catLive: any; // Change the type according to your needs
      listLive: any; // Change the type according to your needs
      catVod: any; // Change the type according to your needs
      listVod: any; // Change the type according to your needs
      catSerie: any; // Change the type according to your needs
      listSerie: any; // Change the type according to your needs
      movie: any; // Change the type according to your needs
      serie: any; // Change the type according to your needs

      listVodFav: any; // Change the type according to your needs
      listSerieFav: any; // Change the type according to your needs
      listLiveFav: any; // Change the type according to your needs

      listVodRecent: any; // Change the type according to your needs
      listLiveRecent: any; // Change the type according to your needs
      listSerieRecent: any; // Change the type according to your needs

      changeDeviceInfo: (item: any) => void;
      changeProfile: (item: any) => void;
      changeProfileShow: (item: any) => void;
      changeSettings: (item: any) => void;
      changeCatLive: (item: any) => void;
      changeListLive: (item: any) => void;

      changeCatVod: (item: any) => void;
      changeListVod: (item: any) => void;

      changeCatSerie: (item: any) => void;
      changeListSerie: (item: any) => void;
      changeSerie: (item: any) => void;
      changeMovie: (item: any) => void;

      changeListLiveFav: (item: any) => void;
      changeListVodFav: (item: any) => void;
      changeListSerieFav: (item: any) => void;
      changeListLiveRecent: (item: any) => void;
      changeListVodRecent: (item: any) => void;
      changeListSerieRecent: (item: any) => void;
      changeParental: (item: any) => void;



}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const useGlobalContext = () => {
      const context = useContext(GlobalContext);
      if (!context) {
            throw new Error('useGlobalContext must be used within a GlobalProvider');
      }
      return context;
};

interface GlobalProviderProps {
      children: ReactNode;
}

export const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
      const [deviceInfo, setDeviceInfo] = React.useState<any>({});
      const [parentalEnable, setParentalEnable] = React.useState<boolean>(false);

      const [profile, setProfile] = React.useState<any>({});
      const [profileShow, setProfileShow] = React.useState<boolean>(false);
      const [settings, setSettings] = React.useState<any>({position:0});
      const changeParental = (item: any) => {
            setParentalEnable(item)
      };

      const changeDeviceInfo = (item: any) => {
            setDeviceInfo(item)    };

      const changeProfile = (item: any) => {
            setProfile(item);
      };
      const changeProfileShow = (item: any) => {
            setProfileShow(item);
      };
      const changeSettings = (item: any) => {
            setSettings(item);
      };

      const [movie, setMovie] = React.useState<any>({});
      const [serie, setSerie] = React.useState<any>({});

      const changeMovie = (item: any) => {
            setMovie(item)
      };

      const changeSerie = (item: any) => {
            setSerie(item);
      };


      const [catLive, setcatLive] = React.useState<any>([]);
      const [listLive, setlistLive] = React.useState<any>([]);

      const changeCatLive = (item: any) => {
            setcatLive((prevArray) => [... item]);

      };

      const changeListLive = (item: any) => {
            setlistLive(item);
      };

      const [catVod, setcatVod] = React.useState<any>([]);
      const [listVod, setlistVod] = React.useState<any>([]);

      const changeCatVod = (item: any) => {
            setcatVod(item)
      };

      const changeListVod = (item: any) => {
            setlistVod(item);
      };


      const [catSerie, setcatSerie] = React.useState<any>([]);
      const [listSerie, setlistSerie] = React.useState<any>([]);

      const changeCatSerie = (item: any) => {
            setcatSerie(item)
      };

      const changeListSerie = (item: any) => {
            setlistSerie(item);
      };

      const [listSerieFav, setlistSerieFav] = React.useState<any>([]);
      const [listVodFav, setlistVodFav] = React.useState<any>([]);
      const [listLiveFav, setlistLiveFav] = React.useState<any>([]);
      const changeListSerieFav = (item: any) => {
            setlistSerieFav(item);
      };
      const changeListVodFav = (item: any) => {
            setlistVodFav(item);
      };
      const changeListLiveFav = (item: any) => {
            setlistLiveFav(item);
      };

      const [listSerieRecent, setlistSerieRecent] = React.useState<any>([]);
      const [listVodRecent, setlistVodRecent] = React.useState<any>([]);
      const [listLiveRecent, setlistLiveRecent] = React.useState<any>([]);
      const changeListSerieRecent = (item: any) => {
            setlistSerieRecent(item);
      };
      const changeListVodRecent = (item: any) => {
            setlistVodRecent(item);
      };
      const changeListLiveRecent = (item: any) => {
            setlistLiveRecent(item);
      };






      useEffect(() => {

      }, [listVodFav]);

      useEffect(() => {

      }, [catLive]);
      useEffect(() => {

      }, [catLive]);
      useEffect(() => {

      }, [catVod]);
      useEffect(() => {

      }, [catSerie]);
      useEffect(() => {

      }, [movie]);
      useEffect(() => {

      }, [serie]);

      return (
            <GlobalContext.Provider value={{ deviceInfo, profile, profileShow, catLive, settings, listLive, catVod, listVod, catSerie, listSerie, movie, serie, listLiveFav, listLiveRecent, listSerieRecent, listSerieFav, listVodFav, listVodRecent, changeDeviceInfo, changeProfile, changeProfileShow, changeSettings, changeCatLive, changeListLive, changeCatSerie, changeListVod, changeCatVod, changeListSerie, changeMovie, changeSerie, changeListLiveFav, changeListLiveRecent, changeListSerieFav, changeListSerieRecent, changeListVodFav, changeListVodRecent, parentalEnable,changeParental }}>
                  {children}
            </GlobalContext.Provider>
      );
};
