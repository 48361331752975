
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import ItemSettingSheet from "../sub/ItemSettingSheet";
import { useGlobalContext } from "../../model/GlobalContext";
import { Any } from "react-spring";

function StreamType({isOpen, onCloseFn}) {
      const { ref, focusSelf, focusKey, focused } = useFocusable({focusKey:"StreamType", trackChildren:true});
      const [languageList, setLanguageList] = useState<any[]>([]);
      const { profile } = useGlobalContext();
      useEffect(() => {
            let arr:any[]=[];
            for (let i = 0; i < profile.allowed_output_formats.length;i++){
                  console.log(profile.allowed_output_formats[i])
                  arr.push({ id: profile.allowed_output_formats[i], title: profile.allowed_output_formats[i] })
            }
            setLanguageList(arr)
console.log(profile)
      }, [profile]);

      useEffect(() => {
            if (isOpen)
            {
                  setFocus('StreamType');
                  let id = localStorage.getItem('streamFormat') || "m3u8"
                  if (!profile.allowed_output_formats.includes(id)){
                        id = profile.allowed_output_formats[0]
                  }
                  console.log(profile.allowed_output_formats)
                  setTimeout(() => { setFocus('S'+id )},500);

            }
            else{
            }

      }, [isOpen]);
      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col 	" ref={ref}>

            <h1 className="text-white text-4xl font-bold my-0 mt-10 text-s mx-auto  text-left w-full capitalize ">Select your stream type</h1>

            <div className="  flex flex-row   mt-28  w-full">
                        {languageList.map((obj, index) => (
                              <div className="p-2 w-1/4" key={index}>  <ItemSettingSheet key={index} index={index} obj={obj} onClose={onCloseFn}  />
                            </div>
                        ))}
                  </div>
                  </div>

              </FocusContext.Provider>
}

export default StreamType;