import { FocusContext, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";
import ButtonItem from "./ButtonItem";
import Refrech from "../svg/Refrech";
import Cancel from "../svg/Cancel";
import { useCustomEvent } from "../../Event/CustomEventContext";

function Profile({ProfileServer, profile ,onClose,onRefrech}) {
      const { ref, focusSelf, focusKey } = useFocusable({ focusKey: "Profile" });
      const timeConverter = (UNIX_timestamp) => {
            var a = new Date(UNIX_timestamp * 1000);
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = date + ' ' + month + ' ' + year;
            return time;
      }
      useEffect(() => {
            setFocus('Profile');
      }, []);
      const { addEventListener, removeEventListener } = useCustomEvent();

      useEffect(() => {
            const handleClose =()=>{
                  onClose();
            }
            const handleRefresh = () => {
                  onRefrech();
            }
                 addEventListener('refresh', handleRefresh);
            addEventListener('close', handleClose);

            return () => {
                  removeEventListener('refresh', handleRefresh);
                  removeEventListener('close', handleClose);

            };
      }, [addEventListener, removeEventListener]);


      return <FocusContext.Provider value={focusKey}><section ref={ref} className=" bg-primary/80 flex font-medium items-center absolute left-0 top-0 z-40 justify-center h-screen w-full">

            <section className="w-1/3 mx-auto z-40 bg-primary rounded-2xl px-8 py-6 shadow-lg absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="flex items-center justify-between">
                        {(ProfileServer.user_info.is_trial == 1) && <span className="text-black text-sm bg-yellow-400 p-2 rounded-md">Trial</span>
                        }
                  </div>
                  <div className="mt-6 w-fit mx-auto">
                        <div className="rounded-full w-28 h-28 bg-secondary flex items-center justify-center text-white text-5xl font-bold" >{profile.profile_name[0]}</div>
                  </div>

                  <div className="mt-8 ">
                        <h2 className="text-white font-bold text-2xl tracking-wide">{profile.profile_name}</h2>
                        <h2 className="text-white font-bold text-2xl tracking-wide opacity-50">{ProfileServer.user_info.username}</h2>
                  </div>
                  <p className="text-white font-semibold mt-2.5" >
                        {ProfileServer.server_info.url}
                  </p>
                  {(ProfileServer.user_info.status.toLowerCase() == "active") && <p className="text-emerald-400 font-semibold mt-2.5" >
                        Active
                  </p>}
                  {(ProfileServer.user_info.status.toLowerCase() != "active") && <p className="text-red-600 font-semibold mt-2.5" >
                        {ProfileServer.user_info.status}
                  </p>}
                  <div className='flex flex-row'>
                        <div className="text-white font-semibold mt-2.5 w-1/2 flex justify-center " >
                              <img src="/images/timeadd.svg" className='w-6 h-6 mr-4 opacity-50'></img>
                              <span className='mr-4 text-white opacity-50 font-semibold text-lg'> Created At</span>                   {timeConverter(ProfileServer.user_info.created_at)}
                        </div>
                        <div className="text-white font-semibold mt-2.5 w-1/2 text-center flex justify-center" >
                              <img src="/images/expire.svg" className='w-6 h-6 mr-4 opacity-50'></img>
                              <span className='mr-4 text-white opacity-50 font-semibold text-lg'> Expired At</span>                     {timeConverter(ProfileServer.user_info.exp_date)}
                        </div>
                  </div>
                  <div className='flex flex-row  justify-center'>
                        <div className="text-white font-semibold mt-2.5 w-1/2 flex justify-center " >
                              <img src="/images/timeadd.svg" className='w-6 h-6 mr-4 opacity-50'></img>
                              <span className='mr-4 text-white opacity-50 font-semibold text-lg'> Max Connections</span>                   {ProfileServer.user_info.max_connections}
                        </div>

                  </div>
                  <div className='flex flex-row  justify-center mt-4'>

                        <ButtonItem event="refresh" classes=" rounded-full bg-transparentWhite30 mr-4 py-4 px-6 "
                        >
                              <ButtonItem.Slot name="leftActions">
                                    <Refrech classes="fill-textcolor2" />
                              </ButtonItem.Slot>
                              <ButtonItem.Slot name="leftActionsFocused">
                                    <Refrech classes="fill-white" />
                              </ButtonItem.Slot>

                              <ButtonItem.Slot name="rightActions">
                                    <span className="ml-2">Refresh</span>
                              </ButtonItem.Slot>
                        </ButtonItem>

                        <ButtonItem event="close" classes=" rounded-full bg-transparentWhite30  py-4 px-6 "
                        >
                              <ButtonItem.Slot name="leftActions">
                                    <Cancel classes="fill-textcolor2" />
                              </ButtonItem.Slot>
                              <ButtonItem.Slot name="leftActionsFocused">
                                    <Cancel classes="fill-white" />
                              </ButtonItem.Slot>

                              <ButtonItem.Slot name="rightActions">
                                    <span className="ml-2">Close</span>
                              </ButtonItem.Slot>
                        </ButtonItem>
                  </div>

            </section>
      </section></FocusContext.Provider>
}
export default Profile;

