import React, { useRef, useState } from 'react';
import './Home.css';
import { createContext, useContext, FC, ReactNode, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FocusContext, getCurrentFocusKey, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import Nav from './sub/Nav';
import FavMovie from './FavMovie';
import FavSerie from './FavSerie';
import Keyboard from './Settings/KeybordV2';
import SearchLive from './SearchLive';
import useEventListener from '@use-it/event-listener';
import SearchMovie from './SearchMovie';
import SearchSerie from './SearchSerie';

import SearchIcon from './svg/Search';
import { useNavigate } from 'react-router-dom';


const ESCAPE_KEYS = ['27', 'Escape', "XF86Back"];


const Search: FC = () => {
      const navigate = useNavigate();

      const { ref, focusSelf, focusKey, focused } = useFocusable({ isFocusBoundary: true, focusKey: "search" });
      const [width, setWidth] = useState<number>(0);
      const [height, setHeight] = useState<number>(0);
      const [currentSection, setSection] = useState<string>("s1");
      const [inputValue, setInputValue] = useState('');
      const inputRef = useRef<HTMLInputElement>(null);

      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      const myDivRef = useRef<HTMLDivElement>(null);
      useEffect(() => {
            // Accessing width and height inside the useEffect to make sure the component has been rendered
            if (myDivRef.current)
            {const divWidth = myDivRef.current.offsetWidth;
           const divHeight = myDivRef.current.offsetHeight;
                  setWidth(divWidth)
                  setHeight(divHeight)
            }
      }, []); // Empty dependency array to run the effect only once after the initial render


      const handleKeyPress = (text) => {
            if (text !== 'Space' && text !== 'Delete' && text !== 'CLEAR') {
                  setInputValue((value) => value + text);

            } else {
                  if (text == 'CLEAR') {
                        setInputValue("")
                  }
                  if (text == 'Space') {
                        setInputValue((value) => value + " ");
                  }
                  if (text == 'Delete') {
                        setInputValue((value) => value.substring(0, value.length - 1));
                  }

            }
            setTimeout(() => {
                  //handleInputChange(inputRef!!.current!!.value)

            })
            // setInput((prevInput) => prevInput + key);
      };
      function handler({ key }) {
            if (ESCAPE_KEYS.includes(String(key))) {

                        navigate(-1)

            }
      }

      useEventListener('keydown', handler);

      return <FocusContext.Provider value={focusKey}>
      <div ref={ref}>
           <Nav />
<motion.div initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}

 className="w-full h-screen " >

      <div
            className=" w-full h-screen overflow-hidden bg-cover bg-[50%] bg-no-repeat absolute bottom-0 left-0"
      >
            <img src="/images/main.png" className="w-full h-screen min-w-full" />
            <div
                  className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-radial from-0% from-primary via-55% via-primary to-90% to-primarytransparent"
            />
      </div>
      <div
            className=" absolute bottom-0 left-0 w-full h-full justify-center justify-items-center px-5"
      >
                        <div className=" flex  mx-auto items-center h-screen	 w-full pt-12 "   >
                              <div className=' w-2/3 overfow-auto h-full py-[4rem]' >
                                          <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={inputValue.length < 3 ? { opacity: 1, height: '100%' } : { opacity: 0, height: 0 }}
                                                transition={{ duration: 0.5 }}
                                                style={{ overflow: 'hidden' }}
                                          >
                                                <div className='w-full h-full flex items-center justify-center'>
                                                      <div className='opacity-50 animate-bounce'> <SearchIcon classes={'50%'} /> </div>
                                                </div>
                                          </motion.div>

                                    <div className='h-full  ' ref={myDivRef}>
                                          {width > 0 &&<>

                                                      <SearchLive parentWidth={width} parentHeiht={height}  keystr={inputValue} />
                                                      <SearchMovie parentWidth={width} parentHeiht={height} keystr={inputValue} />
                                                      <SearchSerie parentWidth={width} parentHeiht={height} keystr={inputValue} />


                                          </>
                                          }</div>
</div>
                  <div
                        className="   w-1/3 mx-2 flex flex-col justify-items-center justify-center"
                  >
                                    <input
                                          type="text"
                                          value={inputValue}
                                          ref={inputRef}
                                          readOnly
                                          className='p-4 text-2xl bg-white/10 text-white rounded-lg outline-0
                                                            m-8'
                                    />
                                    <Keyboard onKeyPress={handleKeyPress} width='w-full' />
                  </div>
                        </div>

            </div>
      </motion.div></div>
</FocusContext.Provider >

}



export default Search;
