import React from 'react';

const DateImg: React.FC = () => {
      return (
            <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
            >
                  <path
                        opacity="0.4"
                        d="M21.9897 6.51996C22.0097 6.75996 21.9897 7.00995 21.9297 7.25995L18.5597 20.7899C18.3197 21.7999 17.4198 22.4999 16.3798 22.4999H3.23974C1.72974 22.4999 0.659755 21.0199 1.09976 19.5699L5.30975 6.03992C5.59975 5.09992 6.46976 4.45996 7.44976 4.45996H19.7498C20.7097 4.45996 21.4898 5.02996 21.8198 5.82996C21.9198 6.03996 21.9697 6.27996 21.9897 6.51996Z"
                        fill="white"
                  />
                  <path
                        d="M22.9899 20.12C23.0899 21.41 22.0699 22.5 20.7799 22.5H16.3799C17.4199 22.5 18.3199 21.8 18.5599 20.79L21.9299 7.26001C21.9899 7.01001 22.0099 6.76002 21.9899 6.52002L21.9999 6.5L22.9899 20.12Z"
                        fill="white"
                  />
                  <path
                        d="M9.67977 7.63001C9.61977 7.63001 9.55977 7.61999 9.49977 7.60999C9.09977 7.50999 8.84979 7.11002 8.94979 6.70002L9.98976 2.38001C10.0898 1.98001 10.4898 1.74002 10.8998 1.83002C11.2998 1.93002 11.5498 2.33 11.4498 2.74L10.4098 7.06C10.3298 7.4 10.0198 7.63001 9.67977 7.63001Z"
                        fill="white"
                  />
                  <path
                        d="M16.3804 7.64005C16.3304 7.64005 16.2704 7.64003 16.2204 7.62003C15.8204 7.53003 15.5604 7.13001 15.6404 6.73001L16.5804 2.39005C16.6704 1.98005 17.0704 1.73003 17.4704 1.81003C17.8704 1.90003 18.1304 2.30004 18.0504 2.70004L17.1104 7.04001C17.0404 7.40001 16.7304 7.64005 16.3804 7.64005Z"
                        fill="white"
                  />
                  <path
                        d="M15.7002 13.25H7.7002C7.2902 13.25 6.9502 12.91 6.9502 12.5C6.9502 12.09 7.2902 11.75 7.7002 11.75H15.7002C16.1102 11.75 16.4502 12.09 16.4502 12.5C16.4502 12.91 16.1102 13.25 15.7002 13.25Z"
                        fill="white"
                  />
                  <path
                        d="M14.7002 17.25H6.7002C6.2902 17.25 5.9502 16.91 5.9502 16.5C5.9502 16.09 6.2902 15.75 6.7002 15.75H14.7002C15.1102 15.75 15.4502 16.09 15.4502 16.5C15.4502 16.91 15.1102 17.25 14.7002 17.25Z"
                        fill="white"
                  />
            </svg>
      );
};

export default DateImg;
