function Recent({ classes}:{ classes?: string}) {
    return ( <svg
          width="2rem"
          height="2rem"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
      >
            <path
                  d="M16.0003 2.6665C8.65366 2.6665 2.66699 8.65317 2.66699 15.9998C2.66699 23.3465 8.65366 29.3332 16.0003 29.3332C23.347 29.3332 29.3337 23.3465 29.3337 15.9998C29.3337 8.65317 23.347 2.6665 16.0003 2.6665ZM21.8003 20.7598C21.6137 21.0798 21.2803 21.2532 20.9337 21.2532C20.7603 21.2532 20.587 21.2132 20.427 21.1065L16.2937 18.6398C15.267 18.0265 14.507 16.6798 14.507 15.4932V10.0265C14.507 9.47984 14.9603 9.0265 15.507 9.0265C16.0537 9.0265 16.507 9.47984 16.507 10.0265V15.4932C16.507 15.9732 16.907 16.6798 17.3203 16.9198L21.4537 19.3865C21.9337 19.6665 22.0937 20.2798 21.8003 20.7598Z"
                  fill="url(#paint0_linear_747_1337)"
            />
            <defs>
                  <linearGradient
                        id="paint0_linear_747_1337"
                        x1="14.8003"
                        y1="11.0665"
                        x2="43.067"
                        y2="38.5332"
                        gradientUnits="userSpaceOnUse"
                  >
                      <stop stopColor="white" />
                      <stop offset="0.200834" stopColor="white" stopOpacity="0.7" />
                      <stop offset="0.392183" stopColor="white" stopOpacity="0.5" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                  </linearGradient>
            </defs>
      </svg>);

}

export default Recent;


/*
function Refrech() {
}

export default Refrech;
*/