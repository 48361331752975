import { createRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import { List, AutoSizer, Collection } from 'react-virtualized';
import ItemChannel from './sub/ItemChannel';

import { useGlobalContext } from '../model/GlobalContext';
import { FocusContext, getCurrentFocusKey, pause, resume, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCustomEvent } from '../Event/CustomEventContext';
import { useNavigate, useParams } from 'react-router-dom';
import useEventListener from '@use-it/event-listener';
import { deleterow, generateurlplayer, insertData, insertDatarecent } from '../model/constant';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import useRemtoPx from '../hooks/remToPx';
import ItemCtagoryLive from './sub/ItemCtagoryLive';
import Keyboard from './Settings/KeybordV2';
import CircleButton from './sub/CircleButton';
import LikeEmpty from './svg/LikeEmpty';
import ResizeIcon from './svg/ResizeIcon';
import ReactPlayer from 'react-player';
import Progress from './sub/Progress';
import Resolution from './sub/Resolution';
import BottomSheet from './Settings/BottomSheet';
import Size from './Settings/size';
import CircleButtonLike from './sub/CircleButtonLike';
import Like from './svg/Like';
import ListIcon from './svg/ListIcon';
import NoInternet from './Settings/NoInternet';
const RED_KEY = ['100', '403']
const ESCAPE_KEYS = ['27', '10009'];
const LEFT_KEYS = ['37'];
const RIGHT_KEYS = ['39'];
const GREEN_KEY = ['103', '404']
const YELLOW_KEY = ['104', '405']
const ENTER_KEY = ['13']
const CH_UP = ['427']
const CH_DOWN = ['428']

const BLUE_KEY = ['105', '406']


interface VideoPlayerProps {
      videoSource: string;
      setResolution:any;
      currentVideoSize:any;
      brightness: number;
      playerRef:any;
      buffering:any;
      playing:any;
      handleBuffer:any;
      handleBufferEnd:any;
      handleError:any;
      handleReady:any;
      removeError: any;
      videoEnded: any;
}

// VideoPlayer component
const VideoPlayer: React.FC<VideoPlayerProps> = memo(({ videoSource, setResolution, currentVideoSize, brightness, playerRef, buffering, playing, handleBuffer, handleBufferEnd, handleError, handleReady, removeError, videoEnded }) => {

      return (
            <div style={{ position: 'relative', width: '100%', paddingTop: `${(1 / (currentVideoSize.size == "4:3" ? 4 / 3 : 16 / 9)) * 100}%`, overflow: 'hidden' }}>
            <ReactPlayer
                  url={videoSource} // Replace with your actual video source
                        width="100%"
                        height="100%"
                        ref={playerRef}
                          playing={playing}
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  onProgress={removeError}
                        onEnded={videoEnded} // Update playing state when the video ends
                        onBuffer={handleBuffer} // Buffering begins
                        onBufferEnd={handleBufferEnd} // Buffering ends
                        onError={() => { console.log(playing);handleError()}} // Handle video player errors
                  onPlay={handleReady}

                  className={currentVideoSize.class}


            />
                  </div>

      );
});
const LivePlayer: React.FC = () => {
      let timeFormat = localStorage.getItem("timeformat") || "12hours"

      let opsiJam: Intl.DateTimeFormatOptions = {
            hour: "numeric",
            minute: "numeric",
            hour12: timeFormat == "12hours" ? true : false,
      };
      let opsiTanggal: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
      };
      const [timeoutId, setTimeoutId] = useState < number | null>(null);
      const timeoutIdRef = useRef<any | null>(null);
      const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
      const [listFav, setListFav] = useState<any[]>([]);
      const [isAvplayerInit, setAvplayerInit] = useState<boolean>(false);
const rempx=useRemtoPx(5)
      const [time, setTime] = useState<any>({})

      let streamFormat = localStorage.getItem('streamFormat') ||'m3u8';

      let playerType = localStorage.getItem('player') ||(window.Tizen?'Avplayer':'videojs');
      if (playerType == 'Avplayer' && !window.Tizen )
      { playerType ="videojs"}
const playerRef = useRef<ReactPlayer | null>(null);

      const [listcat, setListcat] = useState<any[]>([]);
      const [channel, setChannel] = useState<any>({});

      const [srcChannel, setSrcChannel] = useState<string>("");
      const [resolution, setResolution] = useState<string>("480");
      const [showUI,setShowUI]=useState("0")
      const [listPos, setListPos] = useState<number>(0);
      const itemHeight = useRemtoPx(8)
      const itemMargin = useRemtoPx(0.5)
      const itemMarginDiv = useRemtoPx(1.25)
      const collectionRef = createRef<Collection>();
      const [inputValue, setInputValue] = useState('');
      const inputRef = useRef<HTMLInputElement>(null);

      const { index, indexchannel } = useParams();
      const navigate = useNavigate();
      const [channelLiked, setChannelLiked] = useState<boolean>(false);

      const { listLive, catLive, profile, listLiveFav, listLiveRecent, changeListLiveFav, changeListLiveRecent, parentalEnable } = useGlobalContext();

      const { focusSelf, focusKey } = useFocusable();
      const scrollingRef = useRef<HTMLDivElement>(null);
      const [items, setItems] = useState<string[]>([]);

      const [listChannel, setListChannel] = useState<any[]>([]);
      const [listChannelfilterd, setListChannelfilterd] = useState<any[]>([]);
      const [VISIBLE_LIST, setVisibleList] = useState<string>("1");
      const [VISIBLE_KEYBOARD, setVisibleKEYBOARD] = useState<boolean>(false);
      const [currentVideoSize, setCurrentVideoSize] = useState({ id: "0", class: "contain", size: "16:9", title: "Default" });

      const [title, SetTitle] = useState<string>("");
      const [cat, SetCat] = useState<any>();

      // Your video player logic here
      const [buffering, setBuffering] = useState(true);
      const [error, setError] = useState(null);
      const [errorInternet, setErrorInternet] = useState<boolean>(false);
      const [playing, setPlaying] = useState(true);

      const handleBuffer = () => {
            setBuffering(true);
            setError(null)
      };

      const handleBufferEnd = () => {
            setBuffering(false);
            setError(null)

            if (playerRef.current) {
                  const internalPlayer = playerRef.current.getInternalPlayer();
                  setTimeout(() => {
                        if (internalPlayer) {
                              const videoWidth = internalPlayer.videoWidth;
                              const videoHeight = internalPlayer.videoHeight;

                              console.log(`Video resolution: ${videoWidth}x${videoHeight}`);

                              // Check for different resolutions
                              if (videoWidth >= 7680 && videoHeight >= 4320) {
                                    setResolution('8k')
                              } else if (videoWidth >= 3840 && videoHeight >= 2160) {
                                    setResolution('4k')
                              } else if (videoWidth >= 1920 && videoHeight >= 1080) {
                                    setResolution('1080')
                              } else if (videoWidth >= 1280 && videoHeight >= 720) {
                                    setResolution('720')
                              } else {
                                    setResolution('480')
                              }
                        }
                  }, 2000)

            }
      };

      const handleError = (err) => {
            setError(err);
            setBuffering(false)

            console.error('Video player error:', err);
      };
      const handleReady = () => {
            setError(null)

      };

      const memoizedUi = useMemo(() => {
            // Calculate the memoized value based on some logic
            console.log('Recalculating memoized value');
            return showUI;
      }, [showUI]);

      const [scrollToIndex, setScrollToIndex] = useState<number>(0);
      useEffect(() => {

            setListFav([...listLiveFav])
      }, [listLiveFav]);

      function handler({ keyCode }) {
          setTimeout(()=>{
  if(showUI=='1'){
                  handleShowDiv()

            }
          },100)
            if (ESCAPE_KEYS.includes(String(keyCode))) {
                  if (VISIBLE_KEYBOARD){
                        setVisibleKEYBOARD(false)
                        handleInputChange("")
                  }else{
                        if (VISIBLE_LIST!=="1"){
                              setVisibleList("1")
                        }else
                        {navigate(-1)}

                  }

            } else {
                  if (RED_KEY.includes(String(keyCode))) {
                        let focused = getCurrentFocusKey();
                        if (focused.includes('Live')) {

                              let id = parseInt(focused.replace("Live", ""))
                              const newArray = [...listChannelfilterd];
                              newArray[id].isLiked = !newArray[id].isLiked;

                              // Set the state with the new array
                              var streamId = String(newArray[id].stream_id);
                              const arrayFav=[...listLiveFav]
                              if (listLiveFav.includes(streamId))
                              {
                                   const arr2= arrayFav.filter(el => el !== streamId)
                                    deleterow(streamId, "live", profile);

                                    changeListLiveFav(arr2)
                        }else{ arrayFav.push(streamId)
                                    insertData(streamId, "live", profile)
                                    changeListLiveFav(arrayFav)

                        }
                              setListChannelfilterd(newArray)
                        }
                  }
                  if (BLUE_KEY.includes(String(keyCode))) {
                      setVisibleList("2")
                  }
                  if (GREEN_KEY.includes(String(keyCode))) {
                        setVisibleKEYBOARD(true)
                  }
                  if(LEFT_KEYS.includes(String(keyCode))){
                        let focused = getCurrentFocusKey();
                        if (focused&&focused.includes('Live') && !VISIBLE_KEYBOARD) {
                              var index = parseInt(focused.replace('Live', ''))
                            index=  Math.max(index - 10, 0)
                              setShowUI('1')
                              setScrollToIndex(index)

                              setTimeout(() => {
                                    setFocus('Live' + index)
                                    handleShowDiv()
                              }, 50);

                        }
}
                  if (RIGHT_KEYS.includes(String(keyCode))) {
                        let focused = getCurrentFocusKey();
                        if (focused&&focused.includes('Live')&&!VISIBLE_KEYBOARD)
                        {
                              var index = parseInt(focused.replace('Live',''))
                              setShowUI('1')
                              index = Math.min(index + 10, listChannelfilterd.length-1)
                              setScrollToIndex(index)

                              setTimeout(() => {
                                    setFocus('Live' + index)
                                    handleShowDiv()
                              }, 50);

}                  }
                  if (CH_DOWN.includes(String(keyCode))) {
                        let index = listChannelfilterd.findIndex((item) => item.stream_id === channel.stream_id);


                        handleShowDiv()
                        if (index + 1 < listChannelfilterd.length){
                              setChannel(listChannelfilterd[index+1])
                        }

                  }
                  if (CH_UP.includes(String(keyCode))) {
                        let index = listChannelfilterd.findIndex((item) => item.stream_id === channel.stream_id);


                        handleShowDiv()
                        if (index - 1 >=0) {
                              setChannel(listChannelfilterd[index - 1])
                        }

                  }
                  if (ENTER_KEY.includes(String(keyCode))) {
                        let focused = getCurrentFocusKey();
                        if (isBottomSheetOpen){


                              return;
                        }else{

                              if (focused == "action-like") {


                                    return;
                              } else {
                        switch (showUI) {
                              case '0':
                                    console.log('dddd')
                                    let index = listChannelfilterd.findIndex((item) => item.stream_id === channel.stream_id);


                                    setShowUI('1')

                                    setTimeout(()=>{setScrollToIndex(index)
                                    console.log("index", index)
                                    setFocus('Live' + index)
                                    handleShowDiv()
                                    },50);

                                    break;
                              case '11':
                                    setShowUI('0')
                                    handleShowDiv()
                                    setFocus("action-list")

                                    break;
                                    case '22':
                                    setShowUI('0')
                                    handleShowDiv()
                                    setFocus("action-list")

                                          break;

                        }
                        }
                  }
                        }

            }
      }

      useEventListener('keydown', handler);

      useEffect(() => {
            const storedItems = JSON.parse(localStorage.getItem('parentallist') || '[]') || [];
            setItems(storedItems);

            SetCat(catLive.find(({ category_id }) => category_id === index))
            setListcat(parentalEnable ? catLive : catLive.filter(item => !storedItems.includes(String(item.category_id))))
            if (!profile.allowed_output_formats.includes(streamFormat)) {
                  streamFormat = profile.allowed_output_formats[0]
            }

      }, [catLive, listLive, parentalEnable]);
      const handleInputChange = (key) => {

            // Filter the data based on the input value
            if(key==""){
                  setListChannelfilterd([...listChannel]);

            }else{  const filteredData = listChannel.filter(item =>
                  item.name.toLowerCase().includes(key.toLowerCase())
            );

            setListChannelfilterd(filteredData);
      }

      };
      const handleLike = (payload) => {
            var streamId = String(channel.stream_id);
            const arrayFav = [...listLiveFav]
            if (listLiveFav.includes(streamId)) {
                  let arr = arrayFav.filter(el => el !== streamId)
                  deleterow(streamId, "live", profile);

                  changeListLiveFav(arr)
            } else {
                  arrayFav.push(streamId)
                  insertData(streamId, "live", profile)
                  changeListLiveFav(arrayFav)

            }
            setChannelLiked((serielike) => !serielike)
      };
      useEffect(() => {
            console.log(cat)
            if(cat){
            SetTitle(cat.category_name)
                  if (cat.category_id == "0") {
                        setListChannel(parentalEnable ? [...listLive] : [...listLive].filter(item => !items.includes(String(item.category_id))))
                        setListChannelfilterd(parentalEnable ? [...listLive] : [...listLive].filter(item => !items.includes(String(item.category_id))))
                        if (indexchannel)
                              {setChannel(listChannelfilterd[indexchannel])
                              setChannelLiked(listLiveFav.includes(String(listLive[indexchannel].stream_id)))

                              }
                        else{setChannel(listLive[0])
                              setChannelLiked(listLiveFav.includes(String(listLive[0].stream_id)))
}

            }
            else if (cat.category_id=="-1") {
                        let arr = [...listLive.filter(item => {
                              return listLiveFav.includes(String(item.stream_id))
                        })]
if(!parentalEnable){
      arr.filter(item => !items.includes(String(item.category_id)))
}

                        setChannel(arr[0])
                        setChannelLiked(listLiveFav.includes(String(arr[0].stream_id)))

                        setListChannel(arr)
                        setListChannelfilterd(arr)

            } else if (cat.category_id == "-2") {
                        let arr = [...listLive.filter(item => {
                              return listLiveRecent.includes(String(item.stream_id))
                        })]
                        if (!parentalEnable) {
                              arr.filter(item => !items.includes(String(item.category_id)))
                        }

                        setChannel(arr[0])
                        setChannelLiked(listLiveFav.includes(String(arr[0].stream_id)))

                        setListChannel(arr)
                        setListChannelfilterd(arr)
            } else {
                  let arr = [...listLive.filter(item => {
                        return item.category_id === cat.category_id
                  })
                  ]
                        if (parentalEnable) {
                              arr.filter(item => !items.includes(String(item.category_id)))
                        }
                        setChannel(arr[0])
                        setChannelLiked(listLiveFav.includes(String(arr[0].stream_id)))

                        setListChannel(arr)
                        setListChannelfilterd(arr)

            }
            }
      }, [cat]);
      useEffect(() => {
            if (channel.stream_id){
                  console.log(generateurlplayer("live", channel.stream_id, streamFormat, profile))
            setTimeout(()=>{
                  setSrcChannel(generateurlplayer("live", channel.stream_id, streamFormat, profile))
                  const arrayFav = [...listLiveRecent]
                  arrayFav.push(channel.stream_id)
                  changeListLiveRecent(arrayFav)
                  insertDatarecent(channel.stream_id + "", "live", profile)
if(playerType=="Avplayer"){
      if (!isAvplayerInit)
     { var playerConfig = {
            url: generateurlplayer("live", channel.stream_id, streamFormat, profile),
            islive:true
      }
      window.Tizen.Player.create(playerConfig);
      setAvplayerInit(true)
     }else{
            window.Tizen.Player.changeVideo(generateurlplayer("live", channel.stream_id, streamFormat, profile));
     }
}
            })
      }
      }, [channel]);

      const listRef = useRef<HTMLDivElement>(null);
      const [listSize, setListSize] = useState({width:500,height:500}); // Default height
      const onAssetFocus = (index )=>{
            setScrollToIndex(index)
      };

      const scrollToWithAnimation = (element, to, duration) => {
            const start = element.scrollTop;
            const change = to - start;
            const startDate = new Date().getTime();

            const animateScroll = () => {
                  const currentDate = new Date().getTime();
                  const elapsed = currentDate - startDate;

                  element.scrollTop = easeInOutQuad(elapsed, start, change, duration);

                  if (elapsed < duration) {
                        requestAnimationFrame(animateScroll);
                  }
            };

            animateScroll();
      };
      const [isOnline, setIsOnline] = useState(navigator.onLine);


      const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const { addEventListener, removeEventListener } = useCustomEvent();
      const [scrollPosition, setScrollPosition] = useState(0);

      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  setScrollToIndex(parseInt(payload))
                  setFocus('Live' + payload);

            };
            const handleCustomEventOpen = (payload) => {

                  setVisibleList("1")
            SetCat(catLive[payload])

            };
            const resize = () => {
                  setBottomSheetOpen(true)
            }


            addEventListener('customEvent', handleCustomEvent);
            addEventListener('focusEventOpen', handleCustomEventOpen);
            const handleList = (payload) => {
                  let index = listChannelfilterd.findIndex((item) => item.stream_id === channel.stream_id);


                  setShowUI('1')

                  setTimeout(() => {
                        setScrollToIndex(index)
                        console.log("index", index)
                        setFocus('Live' + index)
                        handleShowDiv()
                  }, 50);

            };
            addEventListener('list', handleList);




            const handleCustomEventGroup = (payload) => {
                  focusSelf();
                  console.log(payload)

                  setListPos(payload)

            };

            addEventListener('focusEvent', handleCustomEventGroup);
            addEventListener('like', handleLike);
            addEventListener('resize', resize);

            return () => {
                  removeEventListener('focusEventOpen', handleCustomEventOpen);
                  removeEventListener('focusEvent', handleCustomEventGroup);
                  removeEventListener('like', handleLike);
                  removeEventListener('list', handleList);
                  removeEventListener('resize', resize);

                  removeEventListener('customEvent', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);

      useEffect(() => {
             focusSelf();
           //setFocus('Live0');
      }, [focusSelf]);
      useEffect(() => {
            console.log(
                  navigator.onLine)
      }, [error]);

      useEffect(() => {

            handleShowDiv()
            document.body.classList.remove('bg-primary');
            const playerEvent = (event) => {

                 const playerObj = event.detail;
                  console.log(playerObj)
                  if (playerObj.type =="playchange"){
                        setTimeout(()=>{
                              let streamInfo = window.Tizen.Player.streamInfo();
                             let index=  streamInfo.findIndex((obj)=>{return obj.type=="VIDEO"})
                             if(index>-1)
                              {
                                   let viedeoSize = JSON.parse(streamInfo[index].extra_info)
                                   const videoWidth = viedeoSize.Width;
                                   const videoHeight = viedeoSize.Height;


                                   // Check for different resolutions
                                   if (videoWidth >= 7680 && videoHeight >= 4320) {
                                         setResolution('8k')
                                   } else if (videoWidth >= 3840 && videoHeight >= 2160) {
                                         setResolution('4k')
                                   } else if (videoWidth >= 1920 && videoHeight >= 1080) {
                                         setResolution('1080')
                                   } else if (videoWidth >= 1280 && videoHeight >= 720) {
                                         setResolution('720')
                                   } else {
                                         setResolution('480')
                                   }
                              }

                        },2000)

                  }
                  if (playerObj.type === "progress") {
                        if (playerObj.show) {
                              setBuffering(true)
                              setError(null)



                        } else {
                              setBuffering(false)
                              setError(null)

                        }
                  }
                  if (playerObj.type === "error") {

                        if (playerObj.show) {

                              setBuffering(false)
                              setError(event)



                        } else {
                              setBuffering(false)
                              setError(null)

                        }
                  }
                  if (playerObj.type === "nointernet") {
                        if (playerObj.show) {

                        setErrorInternet(true)
                        pause()
                        }
                        else{
                              setErrorInternet(false)
                              resume()
focusSelf()
                        }

                  }            }
            window.document.addEventListener('playerEvent', playerEvent);

            const handleOnline = () => {
                  console.log("handleOnline")

                  setIsOnline(true);
            };

            const handleOffline = () => {
                  console.log("handleOffline")
                  setIsOnline(false);
            };

            window.addEventListener('online', handleOnline);
            window.addEventListener('offline', handleOffline);
            let interval2 = setInterval(() => {

                  let date = new Date();
                  let tanggal = new Intl.DateTimeFormat("en-US", opsiTanggal).format(date);
                  let jam = new Intl.DateTimeFormat("en", opsiJam).format(date);

                  setTime({ "time": jam, "date": tanggal })
            }, 1000)

            return () => {
                  if(playerType =="Avplayer"){
                        console.log(window.Tizen.Player)
                  window.Tizen.Player.stop();
                  window.Tizen.Player.clearListener();
                  }
                  clearInterval(interval2);

            document.body.classList.add('bg-primary');
                  window.document.removeEventListener('playerEvent', playerEvent);
                        window.removeEventListener('online', handleOnline);
                        window.removeEventListener('offline', handleOffline);
            }
      }, []);

    const   handleScrollListener = ({ scrollTop }) => {
            // Your scroll handling logic goes here
          focusSelf();      };



      const rowRenderer = ({ index, key, style }) => {
            return (
                  <div style={style} key={key} >
                        <ItemChannel position={index} item={listChannelfilterd[index]} focusKey={'Live' + index} onChannelChange={onChannelChange} listFav={listFav} currentChannel={channel}></ItemChannel></div>
            );
      };
      const onChannelChange = (position) => {
            const chal = listChannelfilterd[position]
            if(channel.stream_id!==chal.stream_id)
           {
            setChannel(listChannelfilterd[position])
                  setChannelLiked(listLiveFav.includes(String(listChannelfilterd[position].stream_id)))

           } else{
            console.log('0000000000000')
                  setShowUI('22')
                  handleShowDiv()
           }

      }

      const handleKeyPress = (text) => {
            console.log(text)
            if (text !== 'Space' && text !== 'Delete' && text !== 'CLEAR'){
                  setInputValue((value)=> value+text);

            }else{
                  if (text == 'CLEAR'){
                        setInputValue("")
                  }
                  if (text == 'Space') {
                        setInputValue((value) => value + " ");
                  }
                  if (text == 'Delete') {
                        setInputValue((value) => value.substring(0, value.length - 1));
                  }

            }
            setTimeout(()=>{
                  handleInputChange(inputRef!!.current!!.value)

            })
           // setInput((prevInput) => prevInput + key);
      };

      const cellSizeAndPositionGetter = ({ index }) => {
            const columnCount = 4;
            const rowCount = Math.ceil(listcat.length / columnCount);
            const cellWidth = ((scrollingRef.current!!.clientWidth - (itemMarginDiv * 4))) / 4 - (itemMargin/4);
            const cellHeight = itemHeight;
            const margin = itemMargin;

            const column = index % columnCount;
            const row = Math.floor(index / columnCount);

            return {
                  height: cellHeight + (margin * 2),
                  width: cellWidth + (margin * 2),
                  x: column * (cellWidth + (margin*2)),
                  y: row * (cellHeight + (margin * 2)),
            };
      };
      const cellRenderer = ({ index, key, style }) => (
            <div key={key} style={style}>
                  <ItemCtagoryLive category={listcat[index]} key={'item' + index}
                        focusKey={'CAT' + index} index={index}
                  />
            </div>
      );
      const handleShowDiv = () => {
            if (timeoutIdRef.current !== null) {
                  clearTimeout(timeoutIdRef.current);
            }


            const newTimeoutId =  setTimeout(() => {
                  setShowUI("11");
            }, 8000);
            timeoutIdRef!!.current = newTimeoutId;

      };
      const closeFn = (obj) => {
            setCurrentVideoSize(obj)
            focusSelf()
           if(playerType=="Avplayer"){
            if(obj.id==0)
                window.Tizen.Player. changeDisplay(0)
                 if (obj.id == 1)
                       window.Tizen.Player.changeDisplay(1)
                 if (obj.id == 2)
                       window.Tizen.Player.changeDisplay(1)
                 if (obj.id == 3)
                       window.Tizen.Player.changeDisplay(2)
                 if (obj.id == 4)
                       window.Tizen.Player.changeDisplay(2)



           }
           else{ if (playerRef.current)
            {
                  playerRef.current.getInternalPlayer().style.objectFit = obj.class
                 }
            }
           setTimeout(() => {
                 setBottomSheetOpen(false)
           }, 50);
            focusSelf()

      }
      const changeResolution = (res) => {
            setResolution(res)
      }
      return <><FocusContext.Provider value={focusKey} >
            <div className="w-full h-screen relative overflow-hidden">
                  <div className=" w-full h-full">
                   {playerType !=="Avplayer"&&  <div
                        className=" w-full h-full  bg-black"
                  >

                        <VideoPlayer videoSource={srcChannel} setResolution={changeResolution} currentVideoSize={currentVideoSize} brightness={1} playerRef={playerRef} buffering={buffering} handleBuffer={handleBuffer} handleError={handleError} handleBufferEnd={handleBufferEnd} handleReady={handleReady} playing={playing} removeError={() => { setError(null) }} videoEnded={() => { setPlaying(false) }}/>
            </div>}
                        {buffering &&
                              <Progress />
                        }
                        {error!=null &&
                              <div className="flex items-center justify-center w-full absolute z-30 right-0 left-0 bottom-0">
                                    <div
                                          className="group animate-bounce	 relative   focusable w-[26rem] transition-all duration-300 h-[5rem] outline-none border-0 bottom-[12rem] z-10"
                                    >
                                          <div
                                                className={(true ? "bg-thirdCOlor" : "") + " w-1/3 mx-auto h-[0.4rem] top-[-0.3rem] absolute left-1/2 -translate-x-1/2 "}
                                          />

                                          <div className="backdrop-blur-3xl h-[5rem] bg-primarytransparent relative w-full">
                                                <div className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-col justify-center text-center">
                                                      <h2 className="text-white text-normal my-2 text-xl">Error play</h2>

                                                </div>
                                          </div>
                                    </div>
                              </div>

                        }


            </div>
                 {showUI=='1'&& <div className="absolute left-0 top-0 w-full h-screen p-32">
                        <div className="bg-primarytransparent2 w-full h-full p-6 relative">
                              <img
                                    src="/images/bordergradient2.svg"
                                    className="absolute w-full h-full left-0 top-0"
                              />
                              <div className="flex flex-col h-full justify-between overflow-hidden">
                                    <header className="h-10 mb-6 flex">
                                          <div className="flex-grow flex">
                                                <img src="/images/cat.svg" alt="cat" className="w-8 h-8" />
                                                <span className="text-2xl ml-2 text-white">{title}                                                </span>
                                                <span className="text-2xl mx-2 text-white opacity-50"
                                                >{listChannel.length}</span>
                                          </div>
                                          <div>
                                                <img src="/images/filter.svg" alt="filter" className="w-8 h-8" />
                                          </div>
                                    </header>
                                    <main
                                          className="mb-auto h-full relative bg-categoryColor overflow-y-hidden overflow-x-hidden"
                                    >
                                          <div
                                                className="flex flex-col overflow-y-hidden overflow-x-hidden h-full scrollbar-thin scrollbar-rounded-full scrollbar-thumb-transparentWhite30 scrollbar-track-transparent max-w-full"
                                                ref={scrollingRef}
                                          >
                                             {VISIBLE_LIST=="1"&&<div className='flex h-full' ><div className='flex-grow flex flex-col'>
                                                      {VISIBLE_KEYBOARD&& <input
                                                            type="text"
                                                            value={inputValue}
                                                            ref={inputRef}
                                                            readOnly
                                                            className='p-4 text-2xl bg-white/10 text-white rounded-lg outline-0
                                                            mx-5 my-5'
                                                      />}
                                                      <div className='flex-grow  '>
             <AutoSizer>
                                                      {({ height, width }) => (
                                                            <List
                                                                  ref={listRef}

                                                                  width={width}
                                                                  height={height}
                                                                  rowCount={listChannelfilterd.length}
                                                                  onScroll={handleScrollListener}

                                                                              rowHeight={rempx}
                                                                  rowRenderer={rowRenderer}
                                                                  scrollToIndex={scrollToIndex}
                                                                  overscanRowCount={30}

                                                            />
                                                      )}
                                                </AutoSizer>  </div>
                                                </div>
                                                      {VISIBLE_KEYBOARD &&<Keyboard onKeyPress={handleKeyPress}  width='w-1/3'/>}
</div>}
                                                {VISIBLE_LIST == "2" && <AutoSizer>
                                                      {({ width, height }) => (
                                                            <Collection
                                                                  cellCount={listcat.length}
                                                                  cellRenderer={cellRenderer}
                                                                  width={width}
                                                                  ref={collectionRef}
                                                                  scrollToCell={listPos}
                                                                  height={height}
                                                                  cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                                                                  overscanRowCount={30}

                                                            />
                                                      )}
                                                </AutoSizer>}
                                          </div>
                                    </main>
                                    <footer className="h-10 flex items-center justify-end mt-6">
                                          <div className="inline-flex space-x-2 items-center justify-center">
                                                <div className="w-3 h-3 bg-red-500 rounded-full" />
                                                <p className="text-lg text-gray-100 text-opacity-50">Favorite</p>
                                          </div>
                                          <div className="inline-flex space-x-2 items-center justify-center ml-12">
                                                <div className="w-3 h-3 bg-green-500 rounded-full" />
                                                <p className="text-lg text-gray-100 text-opacity-50">Search</p>
                                          </div>
                                          <div className="inline-flex space-x-2 items-center justify-center ml-12">
                                                <div className="w-3 h-3 bg-yellow-500 rounded-full" />
                                                <p className="text-lg text-gray-100 text-opacity-50">Epg</p>
                                          </div>
                                          <div className="inline-flex space-x-2 items-center justify-center ml-12">
                                                <div className="w-3 h-3 bg-blue-500 rounded-full" />
                                                <p className="text-lg text-gray-100 text-opacity-50">Group</p>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                  </div>}
                  {showUI == '0' && <div className="absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-b from-black/0 from-10% via-black/60 via-70% to-black/90 to-90% flex items-end pb-3">
                        <div
                              className="absolute  navbar  w-full top-0 left-0 z-10 pt-8   flex
                        justify-center items-center transition-all"
                        > <div className='relative container mx-auto flex flex-row justify-end '>
                        {parentalEnable && <img className="w-14 h-14 mx-auto mr-4 animated-shild " src="/images/shield.svg" alt="" />}
                                    < div className="flex  items-center mr-6 text-right">
                                          <div className="text-white">
                                                <div className="text-2xl">{time.time}</div>
                                                <div className="text-lg">{time.date}</div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                       {(channel&&cat)&& <div
                              className="relative container mx-auto navbar  w-full top-0 left-0 z-10 pt-8   flex
  justify-center items-center transition-all"
                        >
                              <div className='flex justify-center p-10'>
                                    <div   className='rounded-full w-16 h-16'
                                    >
                                    <LazyLoadImage
                                          alt={channel.stream_icon}
                                          height="100%"
                                          width="100%"
                                          src={channel.stream_icon}
                                    />
                                    </div>
                                    <div className="text-white text-left ml-4">
                                    <h3 className="text-2xl text-grey-darkest font-medium">
                                                {channel.name}                                    </h3>
                                    <p className="text-sm text-grey mt-1">{cat.category_name}</p>
                              </div>
                                    <div className="ml-4">
                                          <CircleButtonLike event="like" isliked={channelLiked}>

                                                <CircleButtonLike.Slot name="leftActions">
                                                      <Like class="fill-rose-600" />
                                                </CircleButtonLike.Slot>
                                                <CircleButtonLike.Slot name="leftActionsFocused">
                                                      <Like class="fill-rose-300" />
                                                </CircleButtonLike.Slot>
                                                <CircleButtonLike.Slot name="leftActionsliked">
                                                      <LikeEmpty class="fill-rose-600" />
                                                </CircleButtonLike.Slot>
                                                <CircleButtonLike.Slot name="leftActionsFocusedliked">
                                                      <LikeEmpty class="fill-rose-300" />
                                                </CircleButtonLike.Slot>

                                          </CircleButtonLike>

                                    </div>
                              </div>
                              <div className="flex-grow">
                                   </div>
                              <div
                                    className="menuItems flex justify-center items-center rounded-full mr-4"
                                    id="menu"
                              >              <CircleButtonLike event="list" isliked={true}>

                                          <CircleButtonLike.Slot name="leftActions">
                                                <ListIcon className="fill-white/70" />
                                          </CircleButtonLike.Slot>
                                          <CircleButtonLike.Slot name="leftActionsFocused">
                                                <ListIcon className="fill-rose-300"  />
                                          </CircleButtonLike.Slot>


                                    </CircleButtonLike>

                                    <CircleButton event="resize">


                                          <CircleButton.Slot name="rightActions">
                                                <ResizeIcon />
                                          </CircleButton.Slot>
                                    </CircleButton>

                                   {resolution!=""&&
                                          <Resolution type={resolution}/>}

                              </div>
                        </div>}
                  </div>
                        }
            </div>

          </FocusContext.Provider>
            <BottomSheet isOpen={isBottomSheetOpen} onClose={() => { focusSelf(); setBottomSheetOpen(false) }}>
                  {isBottomSheetOpen && <Size isOpen={isBottomSheetOpen} onCloseFn={closeFn} size={currentVideoSize} />
                  }
            </BottomSheet>
            {errorInternet &&<NoInternet />
            }

</>
}

export default LivePlayer;



