import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const RightSheet = ({ isOpen, onClose, children ,size="30%"}) => {
      const springProps = useSpring({
            transform: isOpen ? 'translateX(0%)' : 'translateX(100%)',

      });
      const springProps2 = useSpring({
            opacity: isOpen ? 1 : 0, // Add opacity animation for the overlay

      });
      return (
            <animated.div className="bg-transparentWhite30 w-screen h-screen" style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  zIndex: isOpen ? 99 : -1,
                  opacity: springProps2.opacity,
}} >

            <animated.div
                  className="bg-primary"
                  style={{
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        width: size,
                        height: '100%', // Adjust the height as needed
                        boxShadow: '0px -5px 15px rgba(0, 0, 0, 0.1)',
                        ...springProps,
                  }}
            >
                  {children}
                  <button onClick={onClose}>Close</button>
            </animated.div>
            </animated.div>

      );
};
export default RightSheet;
