import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomEvent } from "../../Event/CustomEventContext";
import LockComponent from "../svg/LockComponent";

function ItemCtagoryPortal({ category, focusKey: focusKeyParam, index, closed,changeClose }) {
      const navigate = useNavigate();
      const { emitEvent } = useCustomEvent();

      const { ref, focused, focusKey } = useFocusable({
            focusKey: focusKeyParam,


            onEnterPress: () => {
                  changeClose(index)

            },
      });
      useEffect(() => {
            if (focused) {
                  emitEvent('focusEvent', index);

            }
      }, [focused]);

      return <FocusContext.Provider value={focusKey}> <div ref={ref}  className="group outline-none text-left m-2	" > <div
            className=" relative focusable w-full transition-all duration-300 h-24 outline-none border-0 cursor-pointer"
      >

            <div className="  h-full -mt-2 bg-categoryColor relative w-full">
                  <div
                        className="w-full h-full bg-center bg-cover absolute bottom-0 left-0 flex flex-row justify-center p-8"
                  >
                        <h2 className="text-white text-2xl flex-grow">{category.category_name}</h2>
                        <LockComponent closedElm={closed} />
                  </div>
            </div>

            <div
                  className={(focused ? "block " : "hidden") + " absolute left-0 top-0.5 right-0 w-full h-full  "}
                  style={{"backgroundImage": "url(/images/bordergradient.svg)"}}
            />
      </div>
      </div>
      </FocusContext.Provider>
}

export default ItemCtagoryPortal;

function emitEvent(arg0: string, index: any) {
      throw new Error("Function not implemented.");
}
