import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { Children, ReactElement, useEffect } from 'react';
import { useCustomEvent } from '../../Event/CustomEventContext';

type SlotProps = {
  name:  'rightActions';
  className?: string; // New prop for className

  children?: React.ReactNode;

};

const Slot: React.FC<SlotProps> = ({ children }) => <>{children}</>;

type NavbarContainerProps = {
  children: ReactElement<SlotProps> | ReactElement<SlotProps>[];
  event: string;
  classes?: string;
};

const CircleButton: React.FC<NavbarContainerProps> & { Slot: React.FC<SlotProps> } = ({ children, event, classes }) => {
  const { emitEvent } = useCustomEvent();

  const { ref, focused, focusSelf } = useFocusable({
    focusKey:"action-"+event,
    onEnterPress: () => {
      onEnterPress()
    },
  });
  const onEnterPress = () => {
    emitEvent(event, "click");

  }
  if(classes==""){
    classes =" "
  }

  useEffect(() => {
    if (focused) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });

    }
  }, [focused]);

  const childrenArray = Children.toArray(children) as ReactElement<SlotProps>[];
  const rightActionsSlot = childrenArray.find((child) => child?.props?.name === 'rightActions');

  return (<div ref={ref} onClick={onEnterPress}
    onMouseEnter={focusSelf}
 className={(focused ? "border-2 border-thirdCOlor" : " border-transparentWhite50 ") +" border  w-14 h-14 rounded-full mr-4 focusable outline-none flex justify-center items-center"}
                                                tabIndex={-1}
                                          >



      <div className="flex">{rightActionsSlot?.props?.children}</div>
                                          </div>
  );
};

CircleButton.Slot = Slot;

export default CircleButton;

