import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import Refrech from './svg/Refrech';
import Info from './svg/Info';
// @ts-ignore

import toast, { Toaster } from 'react-hot-toast';
import { useGlobalContext } from '../model/GlobalContext';
import { LoginModel, Playlist } from '../model/model';
import ItemProfile from './sub/ItemProfile';
import { CustomEventProvider, useCustomEvent } from '../Event/CustomEventContext';
import {
      useFocusable,
      FocusContext,
      setFocus
} from "@noriginmedia/norigin-spatial-navigation";
import ButtonItem from './sub/ButtonItem';
import Progress from './sub/Progress';
import MacIcon from './svg/MacIcon';
import PassIcon from './svg/PassIcon';
import BadgeIcon from './svg/BadgeIcon';
import BadgeIcon2 from './svg/BadgeIcon2';
import BadgeTextIcon from './svg/BadgeTextIcon';
import { Trail } from 'react-spring';
import getBrowserFingerprint from 'get-browser-fingerprint';



const Home: React.FC = () => {
      const { ref, focusSelf, focusKey, focused } = useFocusable();
const [isLoadinfShow, setLoadinfShow] = useState(true);

      // Initialize spatial navigation
      const itemRefs = useRef([]);


      useEffect(() => {
            focusSelf();
      }, [focusSelf]);

      let requestModel: LoginModel | undefined;
      const [profileList, setProfileList] = useState<Playlist[]>([]);
      const [profileName, setProfileName] = useState<number>(0);
      const [deviceInfoShow, changeDeviceInfoShow] = useState<string>("-2");
      const [isTrial, changeisTrial] = useState<boolean>(false);
      const [expiredAt, changeExpiredAtl] = useState<string>("");
      const [deviceInfoShowForce, changeDeviceInfoShowFarce] = useState<boolean>(false);

      let mac = "";
      let version = "";
      const { deviceInfo, changeDeviceInfo } = useGlobalContext();

      useEffect(() => {
            const fingerprint = getBrowserFingerprint({ hardwareOnly: true, enableScreen: false, enableWebgl :false});

            if (window.Tizen&&window.Tizen.info){
                  window.setMacadress(window.Tizen.info.mac, window.Tizen.info.version);

            }
            else {
                  window.setMacadress("53:54:58:63:69:20", "1.5.0");
}


            // Update the document title using the browser API
      },[]);

      useEffect(() => {
            if (deviceInfo&&deviceInfo.mac&&deviceInfo.mac != "") {
                  console.log(deviceInfo)

                  mac = deviceInfo.mac;
                  version = deviceInfo.version;
                  callService(deviceInfo.mac, deviceInfo.version);
            }
      }, [deviceInfo]);

      const { addEventListener, removeEventListener } = useCustomEvent();

      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  setProfileName(payload);

            };
            const handleCustomEventRefresh = (payload) => {
                  setProfileList([])
                  changeDeviceInfoShow("-2")
            changeDeviceInfoShowFarce(false)
                  if (deviceInfo && deviceInfo.mac && deviceInfo.mac != "") {

                        mac = deviceInfo.mac;
                        version = deviceInfo.version;
                        callService(deviceInfo.mac, deviceInfo.version);
                  }
            };
            const handleCustomEventInfo = (payload) => {
                  changeDeviceInfoShowFarce(true)

            };
            addEventListener('customEvent', handleCustomEvent);
            addEventListener('customEventToast', showToast);
            addEventListener('refresh', handleCustomEventRefresh);
            addEventListener('deviceinfo', handleCustomEventInfo);

            return () => {
                  removeEventListener('customEvent', handleCustomEvent);
                  removeEventListener('customEventToast', showToast);
                  removeEventListener('refresh', handleCustomEventRefresh);
                  removeEventListener('deviceinfo', handleCustomEventInfo);

            };
      }, [addEventListener, removeEventListener]);


      const filterHandler = (val) => {
            console.log('val', val);

      };
      window.setMacadress = (mac: string, ver: string) => {
            changeDeviceInfo({ mac, version: ver });
      };
      const showToast = () => {
            toast.error("This didn't work.", {
                  position: "bottom-center",
                  style: { borderRadius: "200px", background: "#8181B5", color:" #fff"},
            });
      };
      const callService = (mac: string, version: string) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                  mac: mac,
                  password: getPassword(mac),
                  type: 3,
                  version: version,
            });

            var requestOptions: RequestInit = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
            };


            fetch("https://api.uiplayer.com/api/apps/devices/login", requestOptions)
                  .then((response) => response.json())
                  .then((result) => {
                        console.log(result)
                        requestModel = result as LoginModel;
                        changeisTrial(requestModel.is_terial)
                        if ( requestModel.status == -1) {
                              changeExpiredAtl("")
                              changeDeviceInfoShow("-1")
                              setTimeout(()=>{
                                    setFocus("action-refresh");

                              },50)
                              return;
                        }
                        if (requestModel.status == 0) {
                              changeDeviceInfoShow("0")
                              var future = Date.parse(requestModel.ExipiredAt);
                              var now = new Date();
                              var diff = future - now.getTime();
                              var days = Math.floor(diff / (1000 * 60 * 60 * 24));
                              changeExpiredAtl("Expired in " + days + " days")
                              setTimeout(() => {
                                    setFocus("action-refresh");

                              }, 50)
                              if (!requestModel.is_terial)

                              return;
                        }
                        changeDeviceInfoShow("1")
                        var future = Date.parse(requestModel.ExipiredAt);
                        var now = new Date();
                        var diff = future - now.getTime();
                        var days = Math.floor(diff / (1000 * 60 * 60 * 24));
                        changeExpiredAtl("Expired in " + days + " days")

                        if (requestModel!!.playlist.length==0){
                              changeDeviceInfoShowFarce(true)
                        }else{
                              changeDeviceInfoShowFarce(false)

                        }
                        setProfileList((array: Playlist[]) => {
                               array.length=0;
                              array.push(...requestModel!!.playlist)
                              return array
                        })

                        console.log(requestModel.playlist)
                        if (requestModel.playlist.length > 0){
                              setProfileName(0);
                              setFocus('ITEM0');

                        }
                        focusSelf();

                  })
                  .catch((error) => console.log("error", error));

      };

      const getPassword = (chaine: string) => {
            var chaineEncode = "";
            chaine = chaine.replace(/:/gi, "");
            for (let i = 0; i < chaine.length; i++) {
                  chaineEncode += "" + chaine.charCodeAt(i) + "";
            }
            return chaineEncode.substring(0, 6);
      };



      return <FocusContext.Provider value={focusKey}>
 <div className="w-full h-screen bg-primary" ref={ref} >

  <div className="flex justify-center h-full items-center flex-col  mx-36">
                        {deviceInfoShow=="-2" &&
                          <div className='relative h-24'>    <Progress /></div>
                        }
                        {(deviceInfoShow == "1" && !deviceInfoShowForce) &&  <div className='flex justify-center h-auto  items-center flex-col'>   <h1 className="md:text-4xl font-bold sm:text-lg text-white md:mb-12 sm:mb-4">
                  Who Connected
            </h1>

            <div
                              className=" flex overflow-auto w-full max-w-full scrollbar-hide scroll-smooth mb-12 sm:mb-4 justify-center h-40"
                  >


                        {profileList.map((item,index) =>
                              <ItemProfile position={index} key={'item' + index} playList={item} focusKey={'ITEM'+index} ></ItemProfile>
                        )}
                              </div>


                              {profileList.length>0&&    <h5 className="text-transparentWhite45 text-2xl mb-12 sm:mb-4 sm:text-lg">
                                    {profileList[profileName].profile_name}
                              </h5>}
                        </div>
                        }
                        {(deviceInfoShow == "-1" || deviceInfoShow == "0" || deviceInfoShowForce) &&

                        <div className="mx-auto flex  items-center justify-center">
                                    <div className={(deviceInfoShow == "1" ? "from-[#15B981] " :" from-[#ff2c2c] ")+"h-auto w-auto  bg-gradient-to-l  to-transparent p-[1px] mb-4 "}>
                                          <div className="flex flex-col p-12 items-center relative w-full justify-start bg-secondary">
    <div className='absolute -right-[5rem] -top-[5rem]'>
                                    <div className='relative w-[13rem] h-[13rem]'>

                                    <div className='absolute transform-gpu z-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
'> <BadgeIcon2 start={deviceInfoShow == "1" ? "#11C386" : "#FF1744"} end={deviceInfoShow == "1" ? "#165F46" : "#F50057"} width="11rem" /></div>
                                    <div className='absolute transform-gpu z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
'> <BadgeIcon start={deviceInfoShow == "1" ? "#4AFEC1" : "#D50000"} end={deviceInfoShow == "1" ? "#299F77" : "#C51162"} width="10rem" /></div>
                                    <div className='absolute z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
'> <BadgeTextIcon></BadgeTextIcon></div>
                                          <div className='text-4xl absolute z-0  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-white  transform-gpu ' ><div style={{
                                          transform: "rotate(30deg)"
                                                            }}>{deviceInfoShow == "1" && <>Active</>} {deviceInfoShow == "-1" && <>Expired</>} {deviceInfoShow == "0" && <>Trial</>}</div></div>
</div>
                              </div>
                              <p className="text-3xl font-bold text-center text-white p-12">
                                                      {(deviceInfoShow == "-1" && !isTrial) &&<>
                                                            Your TV MAC is expired<br/>
                                                            Use the following TV MAC address and password to upload your playlist at   <span className='text-blue-700 text-3xl'>uiplayer.com</span>
                                                      </>                         }

                                                      {deviceInfoShow == "0" && <>Trial 7 days<br/>
                                                            {expiredAt != "" && <h4 className='text-transparentWhite50 text-3xl font-bold'>{expiredAt}</h4>}
                                                            Use the following TV MAC address and password to upload your playlist at   <span className='text-blue-700 text-3xl'>uiplayer.com</span>
                                                      </>}
                                                      {deviceInfoShow == "-1"&& isTrial && <>Limited Time Trial Has Expired<br />
                                                            Use the following TV MAC address and password to upload your playlist at   <span className='text-blue-700 text-3xl'>uiplayer.com</span>
                                                      </>}
                                                      {(deviceInfoShow == "1" && profileList.length > 0 ) && <>Your TV MAC is activated<br />
                                                            {expiredAt != "" && <h4 className='text-transparentWhite50 text-3xl font-bold'>{expiredAt}</h4>}
                                                            Use the following TV MAC address and password to upload your playlist at   <span className='text-blue-700 text-3xl'>uiplayer.com</span>
                                                      </>}
                                                      {(deviceInfoShow == "1" && profileList.length == 0) && <>Your TV MAC is activated<br />
                                                            {expiredAt != "" && <h4 className='text-transparentWhite50 text-3xl font-bold'>{expiredAt}</h4>}
                                                            There is no playlist uploaded for your TV.<br />

                                                            Use the following TV MAC address and password to upload your playlist at   <span className='text-blue-700 text-3xl'>uiplayer.com</span>
                                                      </>}                         </p>
                              < div className="bg-white/20  w-full h-[0.06rem]" />
                              <div className="inline-flex pt-6 items-center justify-center">
                                    <div className="flex  items-center justify-start">
                                       <MacIcon/>
                                          <div className="flex items-center justify-end">
                                                <p className="text-xl text-gray-400  ml-2">MAC Address</p>
                                                <p className="text-2xl font-bold text-white  ml-2">{deviceInfo.mac}</p>
                                          </div>
                                    </div>
                                    <div className="flex  items-center justify-start ml-10">
                                          <div className="flex items-center justify-start">
                                          <PassIcon/>
                                                <p className="text-xl text-gray-400 ml-2">Password</p>
                                          </div>
                                          {deviceInfo.mac && <p className="text-2xl font-bold text-white  ml-2">{getPassword(deviceInfo.mac)}</p>}
                                    </div>
                              </div>    </div>
  </div>
</div>
}
            <div className="flex md:flex-col sm:flex-row mt-4">

                              <ButtonItem event="refresh">
                                    <ButtonItem.Slot name="leftActions">
                                          <Refrech classes="fill-textcolor2" />
                                    </ButtonItem.Slot>
                                    <ButtonItem.Slot name="leftActionsFocused">
                                          <Refrech classes="fill-white" />
                                    </ButtonItem.Slot>

                                    <ButtonItem.Slot name="rightActions">
                                          <span className="ml-2">Refresh</span>
                                    </ButtonItem.Slot>
                              </ButtonItem>


                              {(deviceInfoShow == "1" && !deviceInfoShowForce)  && <ButtonItem event="deviceinfo">
                                    <ButtonItem.Slot name="leftActions">
                                          <Info classes="fill-textcolor2 group-focus:fill-white" />
                                    </ButtonItem.Slot>
                                    <ButtonItem.Slot name="leftActionsFocused">
                                          <Info classes="fill-white" />
                                    </ButtonItem.Slot>

                                    <ButtonItem.Slot name="rightActions">
                                          <span className="ml-2">Device Info</span>
                                    </ButtonItem.Slot>
                              </ButtonItem>}




            </div>
                        {(deviceInfoShow == "-1" || deviceInfoShow == "0" || deviceInfoShowForce) &&
<div className='mt-4'><span className='text-xl font-bold text-white/50'>More Info At</span> <span className='text-xl text-white font-bold ml-2'>uiplayer.com</span></div>}

      </div>
      <Toaster/>
      </div>
      </FocusContext.Provider>

;
}



export default Home;
