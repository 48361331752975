import { FocusContext, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import RemoteIcon from "../svg/RemoteIcon";
import ButtonItem from "../sub/ButtonItem";
import ReturnIcon from "../svg/ReturnIcon";
import { useEffect } from "react";


function ParentalInfo({  onCloseFn }) {
      const { ref, focusKey, focusSelf } = useFocusable({ isFocusBoundary: true, focusBoundaryDirections: [] });

      useEffect(() => {
            setFocus('action-info2')      }, [focusSelf]);

      return <FocusContext.Provider value={focusKey}><div className="bg-red-500 py-12 h-full" ref={ref}>
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h2 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                        How show categories filtred?
                  </h2>
                  <div className="flex flex-row  w-full h-full mt-24">
                        <div className="text-center sm:flex sm:text-left lg:block lg:text-center mx-2">
                              <div className="sm:flex-shrink-0">
                                    <div className="flow-root">
                                          <img className="w-28 h-24 mx-auto" src="/images/live.svg" alt="" />
                                    </div>
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                                    <h3 className="text-xl font-medium text-white">Go to liste Category Live</h3>
                              </div>
                        </div>
                        <div className="text-center sm:flex sm:text-left lg:block lg:text-center mx-2">
                              <div className="sm:flex-shrink-0">
                                    <div className="w-28 h-24 mx-auto">
                                          <RemoteIcon />
                                    </div>
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                                    <h3 className="text-xl font-medium text-white">Press 123456 </h3>
                                    <p className="mt-2 text-xl text-white">to show  control parental dialog</p>
                              </div>
                        </div>
                        <div className="text-center sm:flex sm:text-left lg:block lg:text-center mx-2">
                              <div className="sm:flex-shrink-0">
                                    <div className="flow-root">
                                          <img className="w-28 h-24 mx-auto" src="/images/password.svg" alt="" />
                                    </div>
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                                    <h3 className="text-xl font-medium text-white">enter your password</h3>

                              </div>
                        </div>
                        <div className="text-center sm:flex sm:text-left lg:block lg:text-center mx-2">
                              <div className="sm:flex-shrink-0">
                                    <div className="flow-root">
                                          <img className="w-28 h-24 mx-auto" src="/images/shield.svg" alt="" />
                                    </div>
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                                    <h3 className="text-xl font-medium text-white">your hidden category showing</h3>

                              </div>
                        </div>
                        <div className="text-center sm:flex sm:text-left lg:block lg:text-center mx-2">
                              <div className="sm:flex-shrink-0">
                                    <div className="flow-root">
                                          <img className="w-28 h-24 mx-auto" src="/images/shield-cross.svg" alt="" />
                                    </div>
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                                    <h3 className="text-xl font-medium text-white">to hide category press again 123456</h3>

                              </div>
                        </div>
                  </div>
                  <ButtonItem event="info2" classes="mt-24 bg-white px-4 py-4" onevent={onCloseFn } light={true} >
                        <ButtonItem.Slot name="leftActions">
                              <ReturnIcon classes="fill-textcolor2" />
                        </ButtonItem.Slot>
                        <ButtonItem.Slot name="leftActionsFocused">
                              <ReturnIcon classes="fill-textcolor2" />
                        </ButtonItem.Slot>

                        <ButtonItem.Slot name="rightActions">
                              <span className="ml-2">Return</span>
                        </ButtonItem.Slot>
                  </ButtonItem>
            </div>
      </div>
      </FocusContext.Provider>}
export default ParentalInfo;