
import { FocusContext, pause, resume, setFocus, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect, useState } from "react";
import ItemSettingSheet from "../sub/ItemSettingSheet";

function LanguageCom({isOpen, onCloseFn}) {
      const { ref, focusSelf, focusKey, focused } = useFocusable({focusKey:"Language"});
      /*
      , { id: "ar", title: "Arabic" } */
      const [languageList, setLanguageList] = useState<any[]>([{ id: "en", title: "English" } ]);


      useEffect(() => {
            console.log("open", isOpen)
            if (isOpen)
            {
                  setFocus('Language');
                  let id= localStorage.getItem('language') ||'en'
                  setFocus('S'+id)

}
            else{
            }

      }, [isOpen]);
      return <FocusContext.Provider value={focusKey}>
            <div className=" w-full h-screen px-5 mx-auto overflow-auto  flex flex-col 	" ref={ref}>

            <h1 className="text-white text-4xl font-bold my-0 mt-10 text-s mx-auto  text-left w-full capitalize ">Select your language</h1>

            <div className="  flex flex-row   mt-28  w-full">
                        {languageList.map((obj, index) => (
                              <div className="w-1/4 p-2" key={index}> <ItemSettingSheet key={index} index={index} obj={obj} onClose={onCloseFn}  />
                             </div>
                        ))}
                  </div>
                  </div>

              </FocusContext.Provider>
}

export default LanguageCom;