import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../model/GlobalContext";
import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import 'react-virtualized/styles.css'; // import the styles
import './Home.css'; // import the styles
import LazyLoad from 'react-lazyload';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import React from "react";
import { useCustomEvent } from "../Event/CustomEventContext";
import { deleterow, generateurl, getSeriePositions, insertData } from "../model/constant";
import Bg from "./svg/Bg";
import Users from "./svg/Users";
import DateImg from "./svg/date";
import Youtube from "./svg/youtube";
import Like from "./svg/Like";
import ItemSeason from "./sub/ItemSeason";
import ButtonItem from "./sub/ButtonItem";
import Nav from "./sub/Nav";
import ButtonItemLike from "./sub/ButtonItemLike";
import LikeEmpty from "./svg/LikeEmpty";
import useEventListener from "@use-it/event-listener";
import Mask from "./sub/Mask";
import ButtonItemLContenu from "./sub/ButtonItemLContenu";
import WatchingIcon from "./svg/WatchingIcon";
const ESCAPE_KEYS = ['27', '10009'];

const Serie: React.FC = () => {

      const navigate = useNavigate();
      const [serieTrailer, setSerieTrailer] = useState<boolean>(false);
      const [serieLiked, setSerieLiked] = useState<boolean>(false);
      const [currentpos, setCurrentpos] = useState<any>({});

      const [isLoadinfShow, setLoadinfShow] = useState(true);
      const [serieObj, setSerieObj] = useState<any>({});
      const { ref, focusSelf, focusKey } = useFocusable({ trackChildren: true });
      const scrollingRef = useRef<HTMLDivElement>(null);
      const { serie, changeSerie, profile, changeListSerieFav, listSerieFav, profileShow } = useGlobalContext();

      let serieOB;
      useEffect(() => {
           setSerieLiked( listSerieFav.includes(String(serie.series_id)))
      }, [listSerieFav]);

      useEffect(() => {
            getSerieDetail(serie.series_id, profile);
            const videoPositions = getSeriePositions();
            const storedPosition = videoPositions[String(serie.series_id + "")];

            if (storedPosition !== undefined) {
                  // Set the video position to the stored position
                  try{setCurrentpos(JSON.parse(storedPosition));}catch(e){}
            }
      }, [profile]);


      function handler({ keyCode }) {
            console.log(keyCode)
            if (ESCAPE_KEYS.includes(String(keyCode))) {

                  setTimeout(() => {
                        if (!profileShow)
                              navigate(-1)

                  })

            }
      }

      useEventListener('keydown', handler);

      const getSerieDetail = async (id, playlist) => {
            const requestOptions: RequestInit = {
                  method: "GET",
                  redirect: "follow",
            };

            try {
                  const response = await fetch(generateurl("get_series_info", playlist) + "&series_id=" + id, requestOptions);
                  const result = await response.json();
                  console.log(result)
                  result.series_id = id;
                  serieOB =result;
                  if (result.info.youtube_trailer){
                        setSerieTrailer(true)
                  }
                  result.sea = [];
                  for (const x in result.episodes) {
                        result.sea.push(x);
                  }
                  changeSerie(result)
                  setSerieObj(result);
            } catch (error) {
                  console.error("error", error);
            }
      };

      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      const { encodedData } = useParams();

      const { addEventListener, removeEventListener } = useCustomEvent();

      useEffect(() => {
            const handleYoutube = (payload) => {

                  navigate("/youtube/" + serieOB.info.youtube_trailer)
            };
            const handleLike = (payload) => {
                  var streamId = String(serie.series_id);
                  const arrayFav = [...listSerieFav]
                  if (listSerieFav.includes(streamId)) {
                       let arr= arrayFav.filter(el => el !== streamId)
                        deleterow(streamId, "serie", profile);

                        changeListSerieFav(arr)
                  } else {
                        arrayFav.push(streamId)
                        insertData(streamId, "serie", profile)
                        changeListSerieFav(arrayFav)

                  }
                  setSerieLiked((serielike) => !serielike)
            };
            const handlePlayC = (payload) => {
                  setTimeout(() => {
                        const ser = { ...serie }
                        const videoPositions = getSeriePositions();

                        const storedPosition = videoPositions[String(serie.series_id + "")];

                        if (storedPosition !== undefined) {
                              // Set the video position to the stored position
                              const tt = (JSON.parse(storedPosition));

                              ser.playon = tt.c;

                              const index = serie.episodes[currentpos.s].findIndex((ob) => { return ob.episode_num == currentpos.e  })
                              console.log(index)
                              if(index>=0){
                                    ser.epi = serie.episodes[currentpos.s][index]
                                    changeSerie(ser)
                                    navigate("/serieplayer/")

                              }

                        }
                        changeSerie(ser)
                  })

            };

            addEventListener('youtube', handleYoutube);
            addEventListener('like', handleLike);
            addEventListener('continue', handlePlayC);

            return () => {
                  removeEventListener('youtube', handleYoutube);
                  removeEventListener('like', handleLike);
                  removeEventListener('continue', handlePlayC);

            };
      }, [addEventListener, removeEventListener]);

      if (!serieObj) {
            return null; // Return some loading UI or placeholder if movieData is not available
      }


      return <FocusContext.Provider value={focusKey}><div ref={scrollingRef} className="outline-none  flex flex-col" >
            {serieObj.info && (<><div
                  className=" w-full h-screen overflow-hidden bg-cover bg-[50%] bg-no-repeat absolute bottom-0 left-0 top-0 right-0"
            >
                  <Mask mask="linear-gradient(45deg, transparent 50%, rgba(0, 0,0, 1) 150%)" >
                        <LazyLoad height="100%" offset={100}>

                              <LazyLoadImage
                                    alt={serieObj.info.name}
                                    height="100%"
                                    width="100%"
                                    src={serieObj.info.backdrop_path > 0 ? serieObj.info.backdrop_path[0] : serieObj.info.cover}
                              />
                        </LazyLoad>

                  </Mask>
                  <div className="w-full h-full left-0 top-0 bg-primary absolute opacity-40"></div>
                  <div className="-translate-y-1/2 top-0 absolute  " style={{ width: "55vw", height: "55vh", opacity: 0.10, background: '#ABABCD', borderRadius: "100%", filter: 'blur(5rem)' }} />
                  <div className="-translate-x-1/2 translate-y-1/2 bottom-0 absolute  " style={{ width: "55vw", height: "55vh", opacity: 0.10, background: '#ABABCD', borderRadius: "100%", filter: 'blur(5rem)', }} />

            </div>
                  <div
                        className=" absolute bottom-0 overflow-auto left-0 w-full h-full justify-center justify-items-center"
                  ><div className="relative h-full w-full">
                              <Nav />

                        <div
                              className="flex container mx-auto  h-auto pt-36  pb-5"
                              role="list"
                        >
                              <div className=" w-[22rem] h-[33rem]
                              max-w-[22rem] max-h-[33rem] mx-3 transition-all duration-300">
                                    <LazyLoadImage
                                          alt={serieObj.info.cover}
                                          height="100%"
                                          width="100%"
                                          placeholderSrc="/images/logoui.svg"
                                          src={serieObj.info.cover}
                                          onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/images/logoui.svg";
                                          }}
                                          wrapperProps={{
                                                // If you need to, you can tweak the effect transition using the wrapper style.
                                                style: { display: "flex",maxHeight:'100%' },
                                          }}

                                    />
                              </div>
                              <div className="w-2/3 ml-8">
                                    <div className="flex items-center flex-wrap	">
                                          <h1 className="text-white text-5xl text-left">{serieObj.info.name}</h1>
                                          {serieObj.info.genre.split(",").map((genre, index) => (
                                                <span
                                                      key={index}
                                                      className="bg-gray-700 text-white text-xl mt-2 font-medium ml-4 px-2.5 py-0.5 rounded-full"
                                                >
                                                      {genre}
                                                </span>
                                          ))}

          </div>
                                    <p className="w-full text-white mt-4 opacity-80 text-left text-xl">{serieObj.info.plot}</p>
                                    <div className="flex flex-row mt-8">

                                                <button className=" text-white font-light inline-flex  text-xl min-w-[7rem]">
                                                <DateImg />
                                                      <span className="ml-4 text-xl text-left">{serieObj.info.releaseDate} </span>
                                          </button>
                                          <button
                                                className=" text-white font-light inline-flex  ml-4 text-xl"
                                          >
                                                <Users />
                                                      <span className="ml-4 text-xl text-left">{serieObj.info.cast} </span>
                                          </button>
                                    </div>
                                    <div className="flex flex-row mt-8">
                                                {(currentpos.s ) &&
                                                      <ButtonItemLContenu event="continue" classes=" ml-4 -normal py-6 px-10 mr-4 mt-4 bg-secondary ">
                                                            <ButtonItemLContenu.Slot name="leftActions">
                                                                  <WatchingIcon fillColor="#fff" />
                                                            </ButtonItemLContenu.Slot>
                                                            <ButtonItemLContenu.Slot name="leftActionsFocused">
                                                                  <WatchingIcon fillColor="#000" />
                                                            </ButtonItemLContenu.Slot>

                                                            <ButtonItemLContenu.Slot name="rightActions">
                                                                  <span className="ml-2 text-lg">Continue S{currentpos.s} EP{currentpos.e}</span>
                                                            </ButtonItemLContenu.Slot>
                                                      </ButtonItemLContenu>
                                                }
                                                {serieTrailer && <ButtonItem event="youtube" classes=" ml-0  py-6 px-10  mt-4 bg-secondary ">
                                                <ButtonItem.Slot name="leftActions">
                                                      <Youtube class="fill-textcolor2" />
                                                </ButtonItem.Slot>
                                                <ButtonItem.Slot name="leftActionsFocused">
                                                      <Youtube class="fill-white" />
                                                </ButtonItem.Slot>

                                                <ButtonItem.Slot name="rightActions">
                                                      <span className="ml-2 text-lg">Trailer</span>
                                                </ButtonItem.Slot>
                                          </ButtonItem>
}

                                                <ButtonItemLike event="like" isliked={serieLiked} classes=" ml-4  py-6 px-10  mt-4 bg-buttonLike ">
                                                <ButtonItemLike.Slot name="leftActions">
                                                      <Like class="fill-rose-600" />
                                                </ButtonItemLike.Slot>
                                                <ButtonItemLike.Slot name="leftActionsFocused">
                                                      <Like class="fill-rose-300" />
                                                </ButtonItemLike.Slot>
                                                <ButtonItemLike.Slot name="leftActionsliked">
                                                      <LikeEmpty class="fill-rose-600" />
                                                </ButtonItemLike.Slot>
                                                <ButtonItemLike.Slot name="leftActionsFocusedliked">
                                                      <LikeEmpty class="fill-rose-300" />
                                                </ButtonItemLike.Slot>


                                          </ButtonItemLike>

                                    </div>
                              </div>
                        </div>
                        <div className=" container mx-auto text-left">
                              <h2 className="text-white text-3xl my-8">Seasons</h2>
                              <div className="  flex flex-row flex-wrap scroll-smooth">
                                    {serieObj.sea.map((season, index) => (
                                          <ItemSeason key={index}  index={index} season={season} />
                                    ))}
        </div>
                        </div>
                        <div className="h-20"></div>
                 </div> </div></>)
}

        </div>
      </FocusContext.Provider>

}

export default Serie;