import React, { useEffect, useState } from 'react';
import { Collection, Grid, AutoSizer } from 'react-virtualized';
import { useGlobalContext } from '../model/GlobalContext';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCustomEvent } from '../Event/CustomEventContext';
import ItemChannelSquare from './sub/ItemChannelSquare';
import { motion } from 'framer-motion';

const SearchLive = ({ parentWidth, parentHeiht, keystr }) => {
      const { listLive, listLiveFav, changeListSerieFav, profile } = useGlobalContext();
      const [listFav, setListFav] = useState<any[]>([]);
      const [cellposition, setCellposition] = useState<number>(0);
      const { ref, hasFocusedChild, focusKey,focusSelf } = useFocusable({

            trackChildren: true,

            focusKey: 'searchlive'
      });
      const [listLives, setlistLives] = useState<any[]>([]);
      const [listUnfilterd, setListUnfilterd] = useState<any[]>([]);

      const { addEventListener, removeEventListener } = useCustomEvent();

      const rowCount = 100;
      const columnCount = 5;
      const margin = 10;
      useEffect(() => {

            setListFav([...listLiveFav])
      }, [listLiveFav]);

      useEffect(() => {



            setListUnfilterd([...listLive])
            if (!keystr || (keystr == "" || keystr.length < 3)) {
               //   setListChannelfilterd([...listChannel]);
                  setlistLives([])

            } else {
                  const filteredData = listLive.filter(item =>
                        item.name.toLowerCase().includes(keystr.toLowerCase())
                  );


                  setlistLives(filteredData)
                  }

      }, [listLive]);

            useEffect(() => {
                  console.log(!keystr || (keystr == "" || keystr.length < 3))

                  if (!keystr || (keystr == "" || keystr.length < 3)) {
                        //   setListChannelfilterd([...listChannel]);
                        setlistLives([])

                  } else {

                        const filteredData = listUnfilterd.filter(item =>
                              item.name.toLowerCase().includes(keystr.toLowerCase())
                        );
                        console.log(filteredData)


                        setlistLives(filteredData)
                  }
            }, [keystr]);

      // Calculate the width of each column dynamically based on the available width
      const columnWidth = (parentWidth - margin - (columnCount) * margin) / columnCount;

      const totalHeight = columnWidth;
      const cellSizeAndPositionGetter = ({ index }) => {
            const columnIndex = index % columnCount;
            const rowIndex = Math.floor(index / columnCount);

            const left = index * (columnWidth + margin);

            return {
                  width: columnWidth ,
                  height: columnWidth ,
                  x: left,
                  y: 0,
            };
      };

      const cellRenderer = ({ index, key, style }) => {
            return (
                  <div
                        key={key}
                        style={{
                              ...style,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                        }}
                  >
                        <ItemChannelSquare position={index} item={listLives[index]} focusKey={'Live' + index} listFav={listFav} type={listLives[index].category_id} />
                  </div>
            );
      };
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  setCellposition(payload)
                  focusSelf()            };

            addEventListener('focusEventLive', handleCustomEvent);

            return () => {
                  removeEventListener('focusEventLive', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);


      useEffect(() => {
            if (hasFocusedChild) {
                  ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                  });

            }
      }, [hasFocusedChild]);

      return (
            <FocusContext.Provider value={focusKey}>
                  <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={listLives.length>0 ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                  >

                  <div style={{ width: '100%', overflow: 'hidden' }} ref={ref}>
                  <h1 className='text-white text-left text-3xl py-4 font-bold'>Live Channel</h1>
                  <div>
                  <AutoSizer disableHeight>
                        {({ width }) => (
                  <Collection
                        width={parentWidth}
                        height={totalHeight+30}
                        cellCount={listLives.length}
                        scrollToCell={cellposition}
                        cellRenderer={cellRenderer}
                        cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                  />
                        )}
                        </AutoSizer>
                  </div>
            </div>
            </motion.div>
            </FocusContext.Provider>
      );
};

export default SearchLive;
