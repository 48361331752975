import React, { useEffect, useState } from 'react';
import { Collection, Grid } from 'react-virtualized';
import { useGlobalContext } from '../model/GlobalContext';
import ItemSerie from './sub/ItemSerie';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCustomEvent } from '../Event/CustomEventContext';
import ItemMovie from './sub/ItemMovie';

const RecentMovie = ({ parentWidth, parentHeiht }) => {
      const { listVod, listVodRecent, listVodFav } = useGlobalContext();
      const [listFav, setListFav] = useState<any[]>([]);
      const [cellposition, setCellposition] = useState<number>(0);

      const [listVods, setlistVods] = useState<any[]>([]);
      const { addEventListener, removeEventListener } = useCustomEvent();

      const rowCount = 100;
      const columnCount = 5;
      const margin = 10;
      useEffect(() => {

            setListFav([...listVodFav])
      }, [listVodFav]);

      useEffect(() => {
            let arr = [...listVod.filter(item => {
                  return listVodRecent.includes(String(item.stream_id))
            })]


            setlistVods(arr)


      }, [listVod]);

      // Calculate the width of each column dynamically based on the available width
      const columnWidth = (parentWidth - margin - (columnCount) * margin) / columnCount;

      const totalWidth = parentWidth;
      const totalHeight = parentHeiht;
      const cellSizeAndPositionGetter = ({ index }) => {
            const columnIndex = index % columnCount;
            const rowIndex = Math.floor(index / columnCount);

            const left = columnIndex * (columnWidth + margin);
            const top = rowIndex * (columnWidth * 1.5 + margin);

            return {
                  width: columnWidth,
                  height: columnWidth * 1.5,
                  x: left,
                  y: top,
            };
      };

      const cellRenderer = ({ index, key, style }) => {
            return (
                  <div
                        key={key}
                        style={{
                              ...style,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                        }}
                  >
                        <ItemMovie index={index} movie={listVods[index]} focusKey={'Movie' + index} listFav={listFav} />
                  </div>
            );
      };
      useEffect(() => {
            const handleCustomEvent = (payload) => {
                  setCellposition(payload)
            };

            addEventListener('focusEventMovie', handleCustomEvent);

            return () => {
                  removeEventListener('focusEventMovie', handleCustomEvent);
            };
      }, [addEventListener, removeEventListener]);


      return (
            <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                  <Collection
                        width={totalWidth}
                        height={totalHeight}
                        cellCount={listVods.length}
                        scrollToCell={cellposition}
                        cellRenderer={cellRenderer}
                        cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                  />


            </div>
      );
};

export default RecentMovie;
