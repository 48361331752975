import React, { useRef, useState } from 'react';
import './Home.css';
import { createContext, useContext, FC, ReactNode, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import Nav from './sub/Nav';
import FavMenu from './sub/FavMenu';
import FavMovie from './FavMovie';
import FavLive from './FavLive';
import FavSerie from './FavSerie';
import useEventListener from '@use-it/event-listener';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../model/GlobalContext';



const ESCAPE_KEYS = ['27', '10009'];


const Favorite: FC = () => {
      const { profileShow } = useGlobalContext();

      const { ref, focusSelf, focusKey, focused } = useFocusable();
      const [width, setWidth] = useState<number>(0);
      const [height, setHeight] = useState<number>(0);
      const [currentSection, setSection] = useState<string>("s1");
      const navigate = useNavigate();

      useEffect(() => {
            focusSelf();
      }, [focusSelf]);
      const myDivRef = useRef<HTMLDivElement>(null);
      useEffect(() => {
            // Accessing width and height inside the useEffect to make sure the component has been rendered
            if (myDivRef.current)
            {const divWidth = myDivRef.current.offsetWidth;
           const divHeight = myDivRef.current.offsetHeight;
                  setWidth(divWidth)
                  setHeight(divHeight)
            }
      }, []); // Empty dependency array to run the effect only once after the initial render

      function handler({ keyCode }) {


            if (ESCAPE_KEYS.includes(String(keyCode))) {

                  setTimeout(() => {
                        if (!profileShow)
                              navigate(-1)

                  })

            }
      }

      useEventListener('keydown', handler);

      return <FocusContext.Provider value={focusKey}>       <Nav />
<motion.div initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}

 className="w-full h-screen " >

      <div
            className=" w-full h-screen overflow-hidden bg-cover bg-[50%] bg-no-repeat absolute bottom-0 left-0"
      >
            <img src="/images/main.png" className="w-full h-screen min-w-full" />
            <div
                  className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-radial from-0% from-primary via-55% via-primary to-90% to-primarytransparent"
            />
      </div>
      <div
            className=" absolute bottom-0 left-0 w-full h-full justify-center justify-items-center"
      >
                        <div className=" flex container mx-auto items-center h-screen	 w-full pt-12 "   >
                              <div className='flex-grow h-full py-[4rem]' >
                                    <div className='h-full ' ref={myDivRef}>
                                          {width > 0 &&<>

                                                {currentSection == 's1' && <FavLive parentWidth={width} parentHeiht={height} />}
                                                {currentSection == 's2' && <FavMovie parentWidth={width} parentHeiht={height} />}
                                                {currentSection == 's3' && <FavSerie parentWidth={width} parentHeiht={height} />}


                                          </>
                                          }</div>
</div>
                  <div
                        className="   h-4/6 mx-3 flex flex-col justify-items-center justify-center"
                  >

                                    <FavMenu type="s1" section={currentSection} setSection={(section)=>{setSection(section)}}/>
                                    <FavMenu type="s2" section={currentSection} setSection={(section)=>{setSection(section)}}/>
                                    <FavMenu type="s3" section={currentSection} setSection={(section)=>{setSection(section)}}/>
                  </div>
                        </div>

            </div>
      </motion.div>
</FocusContext.Provider >

}



export default Favorite;
