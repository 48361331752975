import React from 'react';

const Youtube = (props) => {
      return (
            <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  className={props.class}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
            >
                  <path
                        d="M17 4.5H7C4 4.5 2 6.5 2 9.5V15.5C2 18.5 4 20.5 7 20.5H17C20 20.5 22 18.5 22 15.5V9.5C22 6.5 20 4.5 17 4.5ZM13.89 13.53L11.42 15.01C10.42 15.61 9.59998 15.15 9.59998 13.98V11.01C9.59998 9.84001 10.42 9.38001 11.42 9.98001L13.89 11.46C14.84 12.04 14.84 12.96 13.89 13.53Z"
                  />
            </svg>
      );
};

export default Youtube;
