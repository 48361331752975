import { Focusable } from "react-js-spatial-navigation"
import Favorite from "../svg/Favorite"
import Recent from "../svg/Recent"
import Setting from "../svg/Setting"
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useNavigate } from "react-router-dom";

function MainSetting({ type }) {
      const navigate = useNavigate();

      const { ref, focused, focusSelf } = useFocusable({
            focusKey: type,

            onEnterPress: () => {
                  onEnterPress();
            },
      });
      const onEnterPress = () => {
            localStorage.setItem('main', type)
            if (type == 's1') {
                  navigate('/favorite/');

            }
            if (type == 's2') {
                  navigate('/recent/');

            }
            if (type == 's3') {
                  navigate('/setting/');

            }
      };
      return <div
            ref={ref}
            onClick={onEnterPress}
            onMouseEnter={focusSelf}
            className="  outline-none"
      > <div className=" flex items-center outline-none" >
            <div
                        className={(focused ? "bg-secondaryFocused" :"bg-secondary")+" w-20 h-20  relative rounded-full my-4 ml-8 flex justify-center items-center min-w-5"}
            >
                        <div
                              className={(focused ? "block " : "hidden") + " absolute left-0 top-0 right-0 bottom-0  "}
                              style={{ "backgroundImage": "url(/images/bordergradientcircle.svg)" }}
                        />
                        <div className={(focused ? "opacity-100" : "opacity-50")}>
                        {(() => {
                              if (type === "s1") {
                                    return (
                                          <Favorite />)
                              } else if (type === "s2") {
                                    return (
                                          <Recent />)
                              } else {
                                    return (
                                          <Setting />)
                              }
                        })()}

                  </div>
            </div>
            <div className="ml-8">
                  {(() => {
                        if (type === "s1") {
                              return (
                                    <h2
                                          className={(focused ? "opacity-100" : "opacity-50") +" text-textcolorselected text-2xl "}
                                    > Favorite</h2>)
                        } else if (type === "s2") {
                              return (
                                    <h2
                                          className={(focused ? "opacity-100" : "opacity-50") +" text-textcolorselected text-2xl text-left"}
                                    > Recently Played</h2>)
                        } else {
                              return (
                                    <h2
                                          className={(focused ? "opacity-100" : "opacity-50") +" text-textcolorselected text-2xl "}
                                    > Setting</h2>)
                        }
                  })()}

            </div>

      </div>

      </div>
}

export default MainSetting;